import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  setModalActive,
  setSelectedFolio,
  setFolioSearchResults,
  setFolioSearchBy,
  setFolioSearchDates,
  clearFolioStore,
  setFolioSearchLoading,
} from 'actions/redux/folio_search_actions';

export const initialState = () => {
  return {
    modalActive: false,
    results: [],
    selectedFolio: null,
    searchBy: 'last_name',
    dates: [
      moment().format('DD/MM/YYYY'),
      moment().add(1, 'days').format('DD/MM/YYYY'),
    ],
  };
};

const FolioSearchReducer = createReducer(initialState(), {
  [setModalActive]: (state, action) => {
    state.modalActive = action.payload;
  },
  [setFolioSearchResults]: (state, action) => {
    state.results = action.payload;
  },
  [setSelectedFolio]: (state, action) => {
    state.selectedFolio = action.payload;
  },
  [setFolioSearchBy]: (state, action) => {
    state.searchBy = action.payload;
  },
  [setFolioSearchDates]: (state, action) => {
    state.dates = action.payload;
  },
  [clearFolioStore]: (state) => {
    return (state = initialState());
  },
  [setFolioSearchLoading]: (state, action) => {
    state.loading = action.payload;
  },
});

export default FolioSearchReducer;
