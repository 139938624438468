import classNames from 'classnames';
import NumberPadKey from './number_pad_key';
import PropTypes from 'prop-types';
import { useKeyDown } from '@react-hooks-library/core';
import PaymentStore from 'stores/payment_store';

const NumberPad = ({ disabled, processKeyEvents, onReset, onAppendDigit }) => {
  const keys = ['9', '8', '7', '6', '5', '4', '3', '2', '1', '.', '0', 'c'];
  const cancelKeys = ['Escape', 'Backspace', 'Delete'];
  const ignoreKeys = ['Enter'];

  const shouldHandleKey = (element) => {
    return !(
      element.tagName == 'INPUT' ||
      element.tagName == 'SELECT' ||
      element.tagName == 'TEXTAREA' ||
      element.isContentEditable
    );
  };

  const keyPressed = (key) => {
    if (disabled) {
      return;
    }
    PaymentStore.trigger('change');
    if (key === 'c') {
      onReset();
    } else {
      onAppendDigit(key);
    }
  };

  useKeyDown(ignoreKeys, (event) => {
    if (processKeyEvents) {
      event.preventDefault();
    }
  });

  useKeyDown(cancelKeys, (event) => {
    if (disabled || !processKeyEvents) {
      return;
    }

    if (!shouldHandleKey(event.target)) {
      return;
    }

    event.preventDefault();

    onReset();
  });

  useKeyDown(keys, (e) => {
    if (disabled || !processKeyEvents) {
      return;
    }

    if (!shouldHandleKey(event.target)) {
      return;
    }
    e.preventDefault();
    keyPressed(e.key);
  });

  const classnames = classNames({
    keypad: 'keypad',
    row: 'row',
    'row-reverse': 'row-reverse',
    'text-center-row': 'text-center-row',
    col: 'col',
    disabled: disabled,
  });

  return (
    <div disabled={disabled} className={classnames}>
      {keys.map((key) => {
        return (
          <NumberPadKey keyButton={key} key={key} onKeyPress={keyPressed} />
        );
      })}
    </div>
  );
};

NumberPad.propTypes = {
  disabled: PropTypes.bool,
  processKeyEvents: PropTypes.bool,
  onReset: PropTypes.func,
  onAppendDigit: PropTypes.func,
};

NumberPad.defaultProps = {
  disabled: false,
  processKeyEvents: false,
  onReset: () => {},
  onAppendDigit: () => {},
};

export default NumberPad;
