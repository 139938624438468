import Loading from 'views/page/loading';
import ReservationItem from 'views/reservations/reservation_item';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const Summary = createReactClass({
  displayName: 'Summary',

  propTypes: {
    booking: PropTypes.object,
  },

  render() {
    return (
      <div className="col row booking-summary row-column js-booking-summary">
        {this.reservations()}
      </div>
    );
  },

  renderItems() {
    return this.props.booking.reservations().map((reservation, i) => {
      return (
        <CSSTransition
          key={`animReservation${i}`}
          classNames="reservation"
          timeout={{ enter: 500, exit: 300 }}
        >
          <ReservationItem
            reservation={reservation}
            key={`reservation${i}`}
            booking={this.props.booking}
          />
        </CSSTransition>
      );
    });
  },

  reservations() {
    if (this.props.booking == null) {
      return <Loading text="Reservations" />;
    }
    return <TransitionGroup>{this.renderItems()}</TransitionGroup>;
  },
});

export default Summary;
