import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import Modal from 'libs/modal';

import * as BookingServerActions from 'actions/redux/booking_server_actions';
import { selectBooking } from 'selectors/booking_selectors';
import {
  selectCompanyComments,
  selectInternalComments,
  selectExternalIdentifier,
} from 'selectors/comment_selectors';

import { setCommentsModalVisibility } from 'actions/redux/ui_actions';

export const CommentModal = () => {
  const dispatch = useDispatch();
  const companyCommentText =
    useSelector((state) => selectCompanyComments(state))?.text || '';

  const internalCommentText =
    useSelector((state) => selectInternalComments(state))?.text || '';

  const externalIdentifierText =
    useSelector((state) => selectExternalIdentifier(state)) || '';

  const initialValues = {
    companyCommentText: companyCommentText,
    internalCommentText: internalCommentText,
    externalIdentifierText: externalIdentifierText,
  };

  const [companyComment, setCompanyComment] = useState(companyCommentText);

  const [internalComment, setInternalComment] = useState(internalCommentText);

  const [externalIdentifier, setExternalIdentifier] = useState(
    externalIdentifierText
  );

  useEffect(() => {
    setCompanyComment(companyCommentText);
    setInternalComment(internalCommentText);
    setExternalIdentifier(externalIdentifierText);
  }, [companyCommentText, internalCommentText, externalIdentifierText]);

  const shouldEnableConfirmBtn = () => {
    return (
      initialValues.companyCommentText != companyComment ||
      initialValues.internalCommentText != internalComment ||
      initialValues.externalIdentifierText != externalIdentifier
    );
  };

  const handleCompanyCommentChange = (e) => {
    setCompanyComment(e.target.value);
  };

  const handleInternalCommentChange = (e) => {
    setInternalComment(e.target.value);
  };

  const handleExIdChange = (e) => {
    setExternalIdentifier(e.target.value);
  };

  const handleSave = (booking) => {
    dispatch(setCommentsModalVisibility(false));
    dispatch(
      BookingServerActions.update(booking, {
        public_comments: companyComment,
        internal_comments: internalComment,
        external_identifier: externalIdentifier,
      })
    );
  };

  const handleCancel = () => {
    dispatch(setCommentsModalVisibility(false));
  };

  const visible = useSelector((state) => state.ui.commentsModalVisible);
  const booking = useSelector((state) => selectBooking(state));

  return (
    <Modal
      modalTitle={'Booking Comments'}
      closable={false}
      cancelBtn={true}
      onCancelBtn={handleCancel}
      confirmBtn={true}
      confirmBtnEnabled={shouldEnableConfirmBtn()}
      onSaveBtn={() => handleSave(booking)}
      visible={visible}
    >
      <label className="grid-12" htmlFor="companyComment">
        Company Comment
      </label>
      <textarea
        id="companyComment"
        name="companyComment"
        value={companyComment}
        onChange={handleCompanyCommentChange}
      ></textarea>
      <label className="grid-12" htmlFor="internalComment">
        Internal Comment
      </label>
      <textarea
        id="internalComment"
        name="internalComment"
        value={internalComment}
        onChange={handleInternalCommentChange}
      ></textarea>
      <label className="grid-12" htmlFor="externalId">
        External Identifier
      </label>
      <input
        id="externalId"
        name="externalId"
        value={externalIdentifier}
        onChange={handleExIdChange}
      />
    </Modal>
  );
};

CommentModal.defaultProps = {
  companyComment: { text: '', type: 'company' },
  internalComment: { text: '', type: 'internal' },
  externalIdentifier: '',
};

export default CommentModal;
