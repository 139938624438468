import { createSelector } from '@reduxjs/toolkit';
import Money from '@sealink/money_beans';
import { isNil } from 'lodash';

import * as entities from './booking_entity_selectors';

const selectBooking = createSelector(
  [entities.selectBookingId, entities.selectBookings],
  (id, bookings) => {
    if (bookings == null) {
      return null;
    }
    return bookings[id];
  }
);

const selectClient = createSelector(
  [selectBooking, entities.selectClients],
  (booking, clients) => {
    if (isNil(booking) || isNil(clients) || isNil(booking.client)) {
      return null;
    }
    return clients[booking.client];
  }
);

const selectBookingBalance = createSelector([selectBooking], (booking) => {
  if (!booking) {
    return null;
  }
  return new Money(booking?.balance_in_cents || 0);
});

const selectBookingIsCompleted = createSelector(
  [selectBooking, selectBookingBalance],
  (booking, balance) => {
    if (!booking) {
      return false;
    }

    return booking.state === 'active' && !balance.isPositive();
  }
);

// BACKBONE-JS handler
const selectBookingFinalised = (booking) => {
  if (booking == null) {
    return false;
  } else {
    return booking.completed() || booking.cancelled();
  }
};

const selectPaymentTypes = createSelector(
  [selectBooking, entities.selectPaymentTypeEntities],
  (booking, paymentTypes) => {
    if (booking == null || booking.payment_types_attributes === undefined) {
      return [];
    }

    return booking.payment_types_attributes.map((id) => {
      return paymentTypes[id];
    });
  }
);

const selectIssuedTickets = createSelector(
  [selectBooking, entities.selectIssuedTicketEntities],
  (booking, issuedTickets) => {
    if (booking == null) {
      return [];
    }

    return booking.issued_tickets_attributes.map((id) => {
      return issuedTickets[id];
    });
  }
);

const selectReservations = createSelector(
  [selectBooking, entities.selectReservationEntities],
  (booking, reservations) => {
    if (booking == null) {
      return [];
    }

    return booking.reservations_attributes.map((id) => {
      return reservations[id];
    });
  }
);

const selectReservationIssuedTickets = createSelector(
  [selectIssuedTickets],
  (tickets) => {
    return tickets.filter(
      (ticket) => ticket.ticket_issueable_type == 'Reservation'
    );
  }
);

const selectConsumerIssuedTickets = createSelector(
  [selectIssuedTickets],
  (tickets) => {
    return tickets.filter(
      (ticket) => ticket.ticket_issueable_type == 'ConsumerSplit'
    );
  }
);

const selectReprintableTickets = createSelector(
  [selectIssuedTickets],
  (tickets) => {
    return tickets.filter(
      (ticket) => ticket.ticket_issueable_type != 'Booking'
    );
  }
);

const selectPassengerSplits = createSelector(
  [selectReservations, entities.selectPassengerSplitEntities],
  (reservations, passengerSplits) => {
    return reservations.map((reservation) => {
      return reservation.passenger_splits.map((id) => passengerSplits[id]);
    });
  }
);

const selectVehicleSplits = createSelector(
  [selectReservations, entities.selectVehicleSplitEntities],
  (reservations, vehicleSplits) => {
    return reservations.map((reservation) => {
      return reservation.vehicle_splits.map((id) => vehicleSplits[id]);
    });
  }
);

const selectConsumers = createSelector(
  [selectPassengerSplits, selectVehicleSplits],
  (passengerSplits, vehicleSplits) => {
    return passengerSplits.concat(vehicleSplits);
  }
);

const selectIsFullyIssued = createSelector(
  [
    selectReservationIssuedTickets,
    selectReservations,
    selectConsumerIssuedTickets,
    selectConsumers,
  ],
  (
    reservationIssuedTickets,
    reservations,
    consumerIssuedTickets,
    consumers
  ) => {
    if (reservations.length == 0) {
      return false;
    }

    const reservationTicketIds = reservationIssuedTickets.map(
      (ticket) => ticket.ticket_issueable_id
    );

    const consumerIds = consumers.map((consumer) => consumer.id);
    const consumerTicketIds = consumerIssuedTickets.map(
      (ticket) => ticket.ticket_issueable_id
    );

    const results = reservations.filter((reservation) => {
      if (reservationTicketIds.includes(reservation.id)) {
        return false;
      } else {
        const difference = consumerIds.filter(
          (id) => !consumerTicketIds.includes(id)
        );
        return difference.length > 0;
      }
    });

    return results.length == 0;
  }
);

const selectClientDetails = createSelector(
  [selectBooking, selectClient],
  (booking, client) => {
    if (booking == null) {
      return {};
    }

    return {
      bookingId: booking.id,
      description: client?.name || booking.customer_contact_name,
      isAgent: client != null,
    };
  }
);

export {
  selectBooking,
  selectBookingFinalised,
  selectPaymentTypes,
  selectIsFullyIssued,
  selectReprintableTickets,
  selectReservations,
  selectBookingIsCompleted,
  selectClient,
  selectClientDetails,
};
