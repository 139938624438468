import { get } from 'lodash';

const selectOpenSection = (state) => {
  return state.ui?.openSection || '';
};

const selectQuantityModalOpen = (state) => {
  return get(state, 'ui.quantityModalVisible') || false;
};

const selectHasSelectedReservations = (state) => {
  const selection = get(state, 'ui.selectedReservationIds') || [];
  return selection.length > 0;
};

export {
  selectOpenSection,
  selectQuantityModalOpen,
  selectHasSelectedReservations,
};
