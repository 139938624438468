import { useState, useCallback } from 'react';
import { Button } from 'components/button';
import { useSelector } from 'react-redux';
import Modal from 'libs/modal';
import PropTypes from 'prop-types';
import FolioResultCard from './folio_result_card';
import debounce from 'debounce-promise';
import { map } from 'lodash';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { selectReservationCodes } from 'selectors/booking_entity_selectors';
import { isEmpty } from 'lodash';

export const FolioSearchModal = ({
  loadOptions,
  virtualRoomSearch,
  folioSearchVisible,
  folioSearchCancelClicked,
  handleFolioSubmit,
  folioSearchResultClicked,
  selectedFolio,
  folioIdSelected,
  handleFolioSearchClear,
  setSearchByRoomNumber,
  setSearchBySurname,
  setSearchByReservationNumber,
  setSearchByVirtualRoom,
  folioSearchBy,
  folioSearchBySurnameClass,
  folioSearchByRoomNumberClass,
  folioSearchByReservationNumberClass,
  folioSearchByVirtualRoomClass,
  folioSearchStartDate,
  folioSearchEndDate,
  setFolioSearchDates,
  folioSearchResultsLoading,
}) => {
  const reservationCodes = useSelector((state) =>
    selectReservationCodes(state)
  );
  const code = isEmpty(reservationCodes)
    ? ''
    : `(${reservationCodes.toString()})`;
  const [inputValue, setInputValue] = useState('');
  const [commentValue, setCommentValue] = useState(code);
  const [datePickerFocused, setDatePickerFocused] = useState();

  const handleRoomChargeClick = useCallback(() => {
    handleFolioSubmit(commentValue);
  });

  const folioSearchResults = useSelector((state) => state.folioSearch.results);

  const emptyResultsRow = () => {
    return (
      <tr>
        <td colSpan="3">No results found</td>
      </tr>
    );
  };

  const renderResults = () => {
    if (folioSearchResultsLoading) {
      return (
        <tr>
          <td colSpan="3">
            Loading results...
            <i className="icon-sync icon-spin" />
          </td>
        </tr>
      );
    }
    if (isEmpty(folioSearchResults) && inputValue !== '') {
      return emptyResultsRow();
    }
    return map(folioSearchResults, (folioResult, index) => {
      return (
        <FolioResultCard
          {...folioResult}
          folioSearchResultClicked={folioSearchResultClicked}
          selectedFolioId={selectedFolio?.account_id}
          key={index}
          folioSearchBy={folioSearchBy}
        />
      );
    });
  };

  const renderSearchType = () => {
    return (
      <div className="search-field">
        <label htmlFor="folio-search-by">Search By</label>
        <div id="folio-search-by">
          <div
            className="btn-group container"
            role="group"
            aria-label="Search By"
          >
            <Button
              className={folioSearchBySurnameClass}
              onClick={setSearchBySurname}
            >
              Surname
            </Button>
            <Button
              className={folioSearchByRoomNumberClass}
              onClick={setSearchByRoomNumber}
            >
              Room Number
            </Button>
            <Button
              className={folioSearchByReservationNumberClass}
              onClick={setSearchByReservationNumber}
            >
              Reservation Number
            </Button>
            <Button
              className={folioSearchByVirtualRoomClass}
              onClick={setSearchByVirtualRoom}
            >
              Virtual Room
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDateRange = () => {
    if (folioSearchBy === 'virtual_room') {
      return null;
    }
    return (
      <div className="search-field">
        <label htmlFor="folio-search-dates">Stay within date range</label>
        <div id="folio-search-dates">
          <DateRangePicker
            focusedInput={datePickerFocused}
            onFocusChange={(focusedInput) => {
              setDatePickerFocused(focusedInput);
            }}
            onDatesChange={setFolioSearchDates}
            startDateId="room-charge-start-date"
            startDate={folioSearchStartDate}
            endDateId="room-charge-end-date"
            endDate={folioSearchEndDate}
            enableOutsideDays={true}
            displayFormat="DD-MM-YYYY"
            isOutsideRange={() => false}
            numberOfMonths={1}
            showClearDates={true}
          />
        </div>
      </div>
    );
  };

  const renderSearchTerm = () => {
    return (
      <div className="row search-field">
        <input
          type="text"
          placeholder="Search rooms"
          className="grid-6 grid-grow"
          onChange={(event) => {
            const searchTerm = event.target.value;
            setInputValue(event.target.value);
            if (folioSearchBy === 'virtual_room') {
              debounce(virtualRoomSearch(searchTerm), 500, {});
            } else {
              debounce(
                loadOptions(
                  searchTerm,
                  folioSearchStartDate,
                  folioSearchEndDate
                ),
                500,
                {}
              );
            }
          }}
          value={inputValue}
        />
        <button
          className="folio-search-clear"
          onClick={() => {
            setInputValue('');
            handleFolioSearchClear();
          }}
        >
          Clear Selection
        </button>
      </div>
    );
  };

  const renderResultsTable = () => {
    return (
      <table
        className="table folio-search-results table-horizontal"
        width="100%"
      >
        <thead>
          <tr>
            <th>Room Number</th>
            <th>Guest Name</th>
            <th>Account Type</th>
          </tr>
        </thead>
        <tbody>{renderResults()}</tbody>
      </table>
    );
  };

  return (
    <Modal
      key="modal"
      visible={folioSearchVisible}
      closable={false}
      confirmText={'Charge Room'}
      confirmBtn={true}
      confirmBtnEnabled={folioIdSelected}
      onSaveBtn={handleRoomChargeClick}
      cancelBtn={true}
      cancelText={'Cancel'}
      onCancelBtn={folioSearchCancelClicked}
      className="folio-search-modal"
      modalTitle="Charge Booking To Room"
    >
      <div>
        <div className="grid-12 col section">
          {renderSearchType()}
          {renderDateRange()}
          {renderSearchTerm()}
          {renderResultsTable()}
          <label htmlFor="folioSearchComment">Comment</label>
          <textarea
            id="comment"
            name="comment"
            className="form-control"
            onChange={(e) => setCommentValue(e.target.value)}
            value={commentValue}
          />
        </div>
      </div>
    </Modal>
  );
};

FolioSearchModal.propTypes = {
  loadOptions: PropTypes.func,
  virtualRoomSearch: PropTypes.func,
  folioSearchVisible: PropTypes.bool,
  handleFolioSubmit: PropTypes.func,
  folioSearchCancelClicked: PropTypes.func,
  folioSearchResultClicked: PropTypes.func,
  handleFolioSearchClear: PropTypes.func,
  selectedFolio: PropTypes.object,
  folioIdSelected: PropTypes.bool,
  folioSearchStartDate: PropTypes.instanceOf(moment),
  folioSearchEndDate: PropTypes.instanceOf(moment),
  setFolioSearchDates: PropTypes.func,
  setSearchByRoomNumber: PropTypes.func,
  setSearchBySurname: PropTypes.func,
  setSearchByReservationNumber: PropTypes.func,
  setSearchByVirtualRoom: PropTypes.func,
  folioSearchBy: PropTypes.string,
  folioSearchBySurnameClass: PropTypes.string,
  folioSearchByRoomNumberClass: PropTypes.string,
  folioSearchByReservationNumberClass: PropTypes.string,
  folioSearchByVirtualRoomClass: PropTypes.string,
  folioSearchResultsLoading: PropTypes.bool,
};

FolioSearchModal.defaultProps = {
  loadOptions: null,
  virtualRoomSearch: null,
  folioSearchVisible: false,
  handleFolioSubmit: null,
  folioSearchCancelClicked: null,
  folioSearchResultClicked: null,
  handleFolioSearchClear: null,
  selectedFolio: {},
  folioIdSelected: false,
  folioSearchStartDate: null,
  folioSearchEndDate: null,
  setFolioSearchDates: null,
  setSearchByRoomNumber: null,
  setSearchBySurname: null,
  setSearchByReservationNumber: null,
  setSearchByVirtualRoom: null,
  folioSearchBy: null,
  folioSearchBySurnameClass: 'btn col-md-4 btn-outline-dark',
  folioSearchByRoomNumberClass: 'btn col-md-4 btn-outline-dark',
  folioSearchByReservationNumberClass: 'btn col-md-4 btn-outline-dark',
  folioSearchByVirtualRoomClass: 'btn col-md-4 btn-outline-dark',
  folioSearchResultsLoading: false,
};

export default FolioSearchModal;
