import classNames from 'classnames';
import PropTypes from 'prop-types';

const NewBookingButton = ({ disabled, onClick }) => {
  const className = classNames({
    button: 'button',
    success: 'success',
    large: 'large',
    'grid-grow': 'grid-grow',
  });

  return (
    <button disabled={disabled} onClick={onClick} className={className}>
      New Booking
    </button>
  );
};

NewBookingButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

NewBookingButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default NewBookingButton;
