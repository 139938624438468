import { createAction } from '@reduxjs/toolkit';

const setModalActive = createAction('folioSearch/setModalActive');
const setSelectedFolio = createAction('folioSearch/setSelectedFolio');
const setFolioSearchResults = createAction('folioSearch/setFolioSearchResults');
const setFolioSearchBy = createAction('folioSearch/setFolioSearchBy');
const setFolioSearchDates = createAction('folioSearch/setFolioSearchDates');
const clearFolioStore = createAction('folioSearch/clearFolioStore');
const setFolioSearchLoading = createAction('folioSearch/setFolioSearchLoading');

export {
  setModalActive,
  setSelectedFolio,
  setFolioSearchResults,
  setFolioSearchBy,
  setFolioSearchDates,
  clearFolioStore,
  setFolioSearchLoading,
};
