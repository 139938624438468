import { createReducer } from '@reduxjs/toolkit';
import {
  setPromoCodes,
  setAutoPromoCode,
} from 'actions/redux/promo_code_actions';
import { AUTO_CODE_OFF } from 'models/promo_codes';

const INITIAL_STATE = {
  list: {},
  autoPromoCode: AUTO_CODE_OFF,
};

const PromoCodeReducer = createReducer(INITIAL_STATE, {
  [setPromoCodes]: (state, action) => {
    state.list = action.payload;
  },
  [setAutoPromoCode]: (state, action) => {
    state.autoPromoCode = action.payload;
  },
});

export default PromoCodeReducer;
