import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'underscore';

const selectVehicleTemplates = (state) => state.ticket.vehicleTemplates;
const selectNumberPlate = (state) => state.vehicle.numberPlate;
const selectValidation = (state) => state.ticket.validation;

const selectCanValidate = createSelector(
  [selectVehicleTemplates, selectValidation, selectNumberPlate],
  (templates, validation, numberPlate) => {
    if (!isEmpty(validation.response)) {
      // Already validated
      return validation.allowOverride === true;
    }

    if (!isEmpty(templates)) {
      // We have templates,

      if (isEmpty(numberPlate)) {
        // and the a vehicle hasn't been scanned
        return false;
      }

      const matchingVehicleTemplates = templates.find(
        (template) => template.registration === numberPlate
      );

      if (isEmpty(matchingVehicleTemplates)) {
        // The scanned vehicle does not match what is on the ticket.
        return false;
      }
    }

    return true;
  }
);

const selectIsValidated = createSelector(
  [selectValidation],
  (validation) => !isEmpty(validation.response)
);

const selectIsBoarded = createSelector(
  [selectValidation],
  (validation) => validation.boarded ?? false
);

const selectIsNumberPlateInvalid = createSelector(
  [selectVehicleTemplates, selectNumberPlate],
  (templates, numberPlate) => {
    if (isEmpty(templates)) {
      return true; // Don't care if we haven't got data.
    }

    if (isEmpty(numberPlate)) {
      return true; // Don't care if we haven't got data.
    }

    const matchingVehicleTemplates = templates.find(
      (template) => template.registration === numberPlate
    );

    return isEmpty(matchingVehicleTemplates);
  }
);

export {
  selectVehicleTemplates,
  selectValidation,
  selectNumberPlate,
  selectIsValidated,
  selectIsBoarded,
  selectCanValidate,
  selectIsNumberPlateInvalid,
};
