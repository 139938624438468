import ErrorHandler from 'utils/error_handler';

const handleJsonResponse = (error, dismissAction) => {
  return (dispatch) => {
    if (error.data != null && error.responseJSON == null) {
      error.responseJSON = error.data;
    }
    ErrorHandler.handleJsonResponse(dispatch, error, dismissAction);
    return Promise.resolve();
  };
};

const show = (error, dismissAction) => {
  return (dispatch) => {
    ErrorHandler.show(dispatch, error, dismissAction);
    return Promise.resolve();
  };
};

export { handleJsonResponse, show };
