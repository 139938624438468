/* eslint-disable
    no-console,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import ConfigKeys from 'config/config_keys';
import Config from 'config/config';

(function () {
  const isObjectOrArray = (value, key) => _.isObject(value) || _.isArray(value);

  const isRequiredKey = (value, key) => value.required;

  const checkConfig = function (configHash, configSchema, context) {
    let allKeysDefined;
    if (context == null) {
      context = '';
    }
    const requireConfig = _.pick(configSchema, isRequiredKey);
    const requireConfigKeys = _.keys(requireConfig);

    return (allKeysDefined = _.every(requireConfigKeys, function (key) {
      if (configHash[key] === undefined) {
        console.warn(
          `The environment variable: \`${context}${key}\` is not defined`
        );
      }
      return configHash[key] !== undefined;
    }));
  };

  checkConfig(Config, ConfigKeys);
  return (() => {
    const result = [];
    for (let tenantKey in Config.tenants) {
      const values = Config.tenants[tenantKey];
      result.push(
        checkConfig(values, ConfigKeys.tenants.children, `${tenantKey}:`)
      );
    }
    return result;
  })();
})();
