import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaError from './okta_error';
import PropTypes from 'prop-types';
import { loginSucceeded } from 'actions/redux/thunks/login_thunks';
import { useDispatch } from 'react-redux';

// eslint-disable react/prop-types
const LoginCallback = ({
  errorComponent,
  loadingElement = null,
  onAuthResume,
}) => {
  const dispatch = useDispatch();

  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = useState(null);

  const ErrorReporter = errorComponent || OktaError;
  useEffect(() => {
    // @ts-ignore OKTA-464505: backward compatibility support for auth-js@5
    const isInteractionRequired =
      oktaAuth.idx.isInteractionRequired ||
      oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }
    oktaAuth.handleLoginRedirect().catch((e) => {
      setCallbackError(e);
    });
  }, [oktaAuth]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const login = {
        access_token: authState.accessToken.accessToken,
        token_type: 'Bearer',
        username: authState.idToken.claims.name,
        expires_in: authState.idToken.claims.exp,
      };
      dispatch(loginSucceeded(login));
    }
  }, [authState]);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};

LoginCallback.propTypes = {
  errorComponent: PropTypes.element,
  loadingElement: PropTypes.element,
  onAuthResume: PropTypes.func,
};

export default LoginCallback;
