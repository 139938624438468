import { useSelector, useDispatch } from 'react-redux';
import {
  updateCustomerDetails,
  updateBookingAttributes,
} from 'actions/redux/thunks/booking_thunks';
import { selectBooking } from 'selectors/booking_selectors';
import CustomerDetails from 'components/client/customer_details';

const ConnectedCustomerDetails = () => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => selectBooking(state));

  if (!booking) {
    return null;
  }

  const details = {
    customerContactName: booking.customer_contact_name || '',
    customerContactEmail: booking.customer_contact_email || '',
    customerContactPhone: booking.customer_contact_phone || '',
    customerContactMobile: booking.customer_contact_mobile || '',
  };

  return (
    <CustomerDetails
      details={details}
      onChangeField={(fieldName, value) => {
        dispatch(
          updateCustomerDetails(booking.id, {
            [fieldName]: value,
          })
        );
      }}
      onSubmit={(attributes) => {
        dispatch(updateBookingAttributes(booking.id, attributes));
      }}
    />
  );
};

export default ConnectedCustomerDetails;
