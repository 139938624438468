import { useSelector } from 'react-redux';
import { useGetAdjustmentDefintionsQuery } from 'features/api/api_slice';
import { selectHasSelectedReservations } from 'selectors/ui_selectors';
import AdjustmentItem from './adjustment_item';
import Loading from 'views/page/loading';
import Config from 'config/config';

const AdjustmentList = () => {
  const hasAdjustableReservations = useSelector(selectHasSelectedReservations);

  const { data: adjustmentDefinitions, isLoading } =
    useGetAdjustmentDefintionsQuery(Config.adjustmentCategoryId);

  if (isLoading) {
    return <Loading text="Adjustments" />;
  }
  return (
    <div className="adjustment-definitions grid-12">
      <div className="section adjustment-list">
        {adjustmentDefinitions.map((adjustment) => {
          return (
            <AdjustmentItem
              key={`adjustment-${adjustment.id}`}
              id={adjustment.id}
              active={hasAdjustableReservations}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AdjustmentList;
