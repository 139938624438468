import OktaLoginButton from './okta_login_button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'actions/redux/thunks/login_thunks';

const LoginForm = () => {
  const dispatch = useDispatch();

  const [capslockOn, setCapslockOn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  const handleOnKeyDown = (event) => {
    const isCapsOn =
      event.getModifierState && event.getModifierState('CapsLock');
    setCapslockOn(isCapsOn);
  };

  const inputProps = () => {
    return {
      className: 'login-input',
      required: true,
      autoComplete: 'off',
      autoCapitalize: 'none',
      onKeyDown: handleOnKeyDown,
    };
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const renderCapslockWarning = () => {
    if (!capslockOn) {
      return null;
    }
    return (
      <span className="login-capslock-warning">You have caps lock on.</span>
    );
  };

  return (
    <div className="grid-6">
      <form className="login-form text-center" onSubmit={handleLoginSubmit}>
        <div className="login-label">
          <input
            aria-label="Username"
            type="text"
            name="username"
            placeholder="Login"
            id="login-form-username"
            value={username}
            onChange={handleUsernameChange}
            {...inputProps()}
          />
        </div>
        <div className="login-label">
          <input
            aria-label="Password"
            type="password"
            name="password"
            placeholder="Password"
            id="login-form-password"
            value={password}
            onChange={handlePasswordChange}
            {...inputProps()}
          />
        </div>
        {renderCapslockWarning()}
        <p>
          <button type="submit" className="button default full login-button">
            Login <i className="icon-login" />
          </button>
        </p>
        <OktaLoginButton />
      </form>
    </div>
  );
};

export default LoginForm;
