import { useEffect } from 'react';
import { useFocus } from 'utils/use_focus';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const PaymentReference = ({
  visible,
  reference,
  externalIdentifier = '',
  onPaymentReferenceChange = () => {},
}) => {
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    setInputFocus();
  }, [visible]);

  const paymentReferenceChanged = (e) => {
    onPaymentReferenceChange(e.target.value);
  };

  const copyExternalId = () => {
    onPaymentReferenceChange(externalIdentifier);
  };

  const externalClass = isEmpty(externalIdentifier) ? 'hide' : '';
  const referenceProps = {
    hidden: !visible,
    className: 'payment-reference',
  };

  return (
    <div {...referenceProps}>
      <div
        className={`external-id grid-12 ${externalClass}`}
        onClick={copyExternalId}
      >
        Copy External ID: {externalIdentifier}
      </div>
      <input
        type="text"
        ref={inputRef}
        placeholder="Reference"
        className="full"
        value={reference}
        onChange={paymentReferenceChanged}
      />
    </div>
  );
};

PaymentReference.propTypes = {
  visible: PropTypes.bool,
  reference: PropTypes.string,
  externalIdentifier: PropTypes.string,
  onPaymentReferenceChange: PropTypes.func,
};
export default PaymentReference;
