/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';
import Backbone from 'backbone';
import AppDispatcher from 'app_dispatcher';

const RequestQueueStore = _.extend({}, Backbone.Events, {
  busy: false,

  addChangeListener(listener) {
    return this.on('change', listener);
  },

  removeChangeListener(listener) {
    return this.off('change', listener);
  },
});

AppDispatcher.on('RequestQueue:busy', function () {
  RequestQueueStore.busy = true;
  RequestQueueStore.trigger('change');
})
  .on('RequestQueue:ready', function () {
    RequestQueueStore.busy = false;
    RequestQueueStore.trigger('change');
  })
  .on('RequestQueue:fail', function () {
    RequestQueueStore.busy = false;
    RequestQueueStore.trigger('change');
  });

export default RequestQueueStore;
