import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const selectRawDisplayText = (state) =>
  get(state, 'eftpos.response.Response.DisplayText');
const selectOKKeyFlag = (state) =>
  get(state, 'eftpos.response.Response.OKKeyFlag');
const selectCancelKeyFlag = (state) =>
  get(state, 'eftpos.response.Response.CancelKeyFlag');
const selectAcceptYesKeyFlag = (state) =>
  get(state, 'eftpos.response.Response.AcceptYesKeyFlag');
const selectDeclineNoKeyFlag = (state) =>
  get(state, 'eftpos.response.Response.DeclineNoKeyFlag');
const selectAuthoriseKeyFlag = (state) =>
  get(state, 'eftpos.response.Response.AuthoriseKeyFlag');

const selectDisplayText = createSelector([selectRawDisplayText], (text) => {
  return text || ['PROCESSING'];
});

const selectOkButtonVisible = createSelector([selectOKKeyFlag], (flag) => {
  return flag || false;
});
const selectCancelButtonVisible = createSelector(
  [selectCancelKeyFlag],
  (flag) => {
    return flag || false;
  }
);

const selectAcceptButtonVisible = createSelector(
  [selectAcceptYesKeyFlag],
  (flag) => {
    return flag || false;
  }
);

const selectDeclineButtonVisible = createSelector(
  [selectDeclineNoKeyFlag],
  (flag) => {
    return flag || false;
  }
);

const selectAuthoriseButtonVisible = createSelector(
  [selectAuthoriseKeyFlag],
  (flag) => {
    return flag || false;
  }
);

export const UnsetButton = { text: 'Unset', value: -1 };
export const OKButton = { text: 'OK', value: '0' };
export const AcceptButton = { text: 'Accept', value: '1' };
export const AuthoriseButton = { text: 'Auth', value: '3' };
export const CancelButton = { text: 'Cancel', value: '0' };
export const DeclineButton = { text: 'Decline', value: '2' };

const selectGreenButton = createSelector(
  [
    selectOkButtonVisible,
    selectAcceptButtonVisible,
    selectAuthoriseButtonVisible,
  ],
  (ok, accept, authorise) => {
    if (ok) {
      return OKButton;
    } else if (accept) {
      return AcceptButton;
    } else if (authorise) {
      return AuthoriseButton;
    }
    return UnsetButton;
  }
);

const selectRedButton = createSelector(
  [selectCancelButtonVisible, selectDeclineButtonVisible],
  (cancel, decline) => {
    if (cancel) {
      return CancelButton;
    } else if (decline) {
      return DeclineButton;
    }

    return UnsetButton;
  }
);

export { selectDisplayText, selectGreenButton, selectRedButton };
