import Config from 'config/config';
import { checkStatus } from 'utils/fetch_utils';

const tillsIndex = (access_token) => {
  const requestStartTime = new Date().getTime();

  return fetch(Config.routes.tills(), {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json;charset=utf-8',
      'X-ORIGIN-KEY': Config.apiKey,
    },
  })
    .then((response) => checkStatus(response, requestStartTime))
    .then((response) => response.json());
};

export { tillsIndex };
