import { useDispatch, useSelector } from 'react-redux';
import * as NoticeActions from 'actions/redux/notice_actions';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import Notice from 'components/notice';
import { selectCurrentNotice } from 'selectors/notice_selectors';

const NoticeView = () => {
  const dispatch = useDispatch();
  const currentNotice = useSelector((state) => selectCurrentNotice(state));

  const hasMessages = currentNotice.messages.length > 0;
  const { messages, type, dismissAction } = currentNotice;

  const reloadBooking = () => {
    dispatch(NoticeActions.clearNotice());
    dispatch(BookingActions.reloadBooking());
  };

  const newBooking = () => {
    dispatch(NoticeActions.clearNotice());
    dispatch(BookingActions.newBooking());
  };

  const dismiss = () => {
    dispatch(NoticeActions.clearNotice());
  };

  const returnToPayment = () => {
    dismiss();
    dispatch(BookingActions.payCurrentBooking());
  };

  return (
    <Notice
      hasMessages={hasMessages}
      noticeMessages={messages}
      noticeType={type}
      dismissAction={dismissAction}
      returnToPayment={returnToPayment}
      dismiss={dismiss}
      newBooking={newBooking}
      reloadBooking={reloadBooking}
    />
  );
};

export default NoticeView;
