import Config from 'config/config';

const login = function (username, password) {
  const url = new URL(`${Config.authServiceURL}/oauth/token`);

  const postData = {
    grant_type: 'password',
    username: username,
    password: password,
  };
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(postData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return { error };
    });
};

export default { login };
