import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectSelectedTenant } from 'selectors/tenant_selectors';

// eslint-disable-next-line react/prop-types
const AuthenticationGateway = ({ children }) => {
  const loggedIn = useSelector(selectAuthenticatedUser);
  const selectedTenant = useSelector(selectSelectedTenant);

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  } else if (selectedTenant === undefined) {
    return <Navigate to="/tenants" replace />;
  } else {
    return <Fragment>{children ? children : <Outlet />}</Fragment>;
  }
};

export default AuthenticationGateway;
