import {
  setIsScanning,
  unsetIsScanning,
  appendValue,
} from 'actions/redux/barcode_scanner_actions';

import { processBarcode } from 'actions/redux/thunks/barcode_processor_thunks';

import { suffix } from 'components/barcode_scanner';

const barcodeScannerTimeoutMillis = 50;

const startScan = () => {
  return (dispatch) => {
    dispatch(setIsScanning());
    const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

    return delay(barcodeScannerTimeoutMillis).then(() => {
      dispatch(unsetIsScanning());
    });
  };
};

const processKeyStroke = (key) => {
  return (dispatch, getState) => {
    if (key == suffix) {
      dispatch(unsetIsScanning());
      const barcode = getState().barcodeScanner.value;
      dispatch(processBarcode(barcode));
    } else {
      dispatch(appendValue(key));
    }
  };
};

export { startScan, processKeyStroke };
