import PropTypes from 'prop-types';
import { sample } from 'lodash';

const Loading = ({ style, text }) => {
  const loaderText = [
    'Loading',
    'Downloading',
    'Transmuting',
    'Dissembling',
    'Disecting',
    'Importing',
    'Initialising',
    'Digitising',
    'Delegating',
    'Designating',
    'Authorising',
    'Entrusting',
    'Enrolling',
    'Referencing',
    'Computerising',
    'Booting',
  ];

  return (
    <div
      aria-label="Loading"
      className="loading-loader text-center grid-12"
      style={style}
    >
      <i className="icon-spin icon-spinner" />
      {sample(loaderText)} {text}
    </div>
  );
};

Loading.displayName = 'Loading';

Loading.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
};

Loading.defaultProps = { text: '', style: null };

export default Loading;
