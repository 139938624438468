/* eslint-disable
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let attr;
import Backbone from 'backbone';
import moment from 'moment';
import Money from '@sealink/money_beans';

const Reservation = Backbone.Model.extend({
  initialize() {
    return this.adjustments != null
      ? this.adjustments
      : (this.adjustments = []);
  },

  addAdjustment(attrs) {
    return this.adjustments.push(attrs);
  },

  parse(data) {
    const parsed = data;
    this.set('name', data.selection_name);
    this.adjustments = data.adjustments_attributes;
    return parsed;
  },

  travelsToday() {
    const today = moment();
    return this.first_travel_date().isSame(today, 'day');
  },

  durational() {
    return this.first_travel_date() !== this.last_travel_date();
  },

  updatePrice(attrs) {
    this.set(_.pick(attrs, ...Array.from(this.priceAttrs)));
    return (this.adjustments = attrs.adjustments);
  },

  grossIncludingChildren() {
    return this.gross().add(this.sub_items_gross());
  },

  activePassengerTypes() {
    return _.pick(
      this.get('passenger_type_numbers'),
      (passenger_type_count, passenger_type) => passenger_type_count !== 0
    );
  },

  priceAttrs: [
    'gross_in_cents',
    'commission_in_cents',
    'pre_adjusted_gross_in_cents',
    'pre_adjusted_commission_in_cents',
    'unit_price_in_cents',
    'unit_cost_in_cents',
    'sub_items_gross_in_cents',
  ],
});

//Dynamically create money attribute readers
Reservation.prototype.moneyAttrs = ['gross', 'sub_items_gross'];
Reservation.prototype.dateAttrs = ['first_travel_date', 'last_travel_date'];
for (attr of Array.from(Reservation.prototype.moneyAttrs)) {
  ((attr) =>
    (Reservation.prototype[attr] = function () {
      if (this.get(attr + '_in_cents') == null) {
        return new Money(0);
      }
      return new Money(this.get(attr + '_in_cents'));
    }))(attr);
}
for (attr of Array.from(Reservation.prototype.dateAttrs)) {
  ((attr) =>
    (Reservation.prototype[attr] = function () {
      const rawDate = this.get(attr);
      if (rawDate instanceof Date) {
        // Date
        return rawDate;
      } else if (rawDate instanceof Object) {
        // QT date object
        return moment(rawDate._value, 'YYYY-MM-DD');
      } else {
        // String
        return moment(rawDate, 'DD/MM/YYYY');
      }
    }))(attr);
}

export default Reservation;
