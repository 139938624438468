import { useRef, useEffect } from 'react';
import { useCountUp } from 'react-countup';
import PropTypes from 'prop-types';

const AnimatedMoneyLabel = ({ amount }) => {
  const ref = useRef(null);

  const { update } = useCountUp({
    ref: ref,
    useEasing: true,
    useGrouping: true,
    separator: ',',
    decimal: '.',
    prefix: '$',
    decimals: 2,
    duration: 0.5,
  });

  useEffect(() => {
    update(amount);
  }, [amount]);

  return <span className="booking-price" ref={ref} />;
};

AnimatedMoneyLabel.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default AnimatedMoneyLabel;
