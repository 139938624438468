import _ from 'underscore';
import Backbone from 'backbone';
import AppDispatcher from 'app_dispatcher';
import Payment from 'models/payment';
import store from 'store';
import { selectPaymentType } from 'actions/redux/thunks/payment_type_thunks';
import { selectSelectedPaymentType } from 'selectors/payment_type_selectors';

let _currentPayment = null;

const PaymentStore = _.extend({}, Backbone.Events, {
  addChangeListener(listener) {
    return this.on('change', listener);
  },

  removeChangeListener(listener) {
    return this.off('change', listener);
  },

  currentPayment() {
    return _currentPayment;
  },

  paymentTypeMissing() {
    return selectSelectedPaymentType(store.getState()) !== null;
  },

  referenceMissing() {
    return _currentPayment.referenceMissing();
  },

  setPaymentTypeId(id) {
    _currentPayment.selectPaymentType(id);
  },
});

AppDispatcher.on('booking:loaded', function (booking) {
  _currentPayment = new Payment({
    booking,
    selectedPaymentType: selectSelectedPaymentType(store.getState()),
  });
  const pt = _currentPayment.paymentType();
  store.dispatch(selectPaymentType({ id: pt?.id }));
  PaymentStore.trigger('change');
})
  .on('payment:resetAmountEntered', function () {
    _currentPayment.resetAmountEntered();
    PaymentStore.trigger('change');
  })
  .on('payment:appendDigitToAmountEntered', function (digit) {
    _currentPayment.appendDigitToAmountEntered(digit);
    PaymentStore.trigger('change');
  })
  .on('payment:selectPaymentType', function (id) {
    _currentPayment.selectPaymentType(id);
    PaymentStore.trigger('change');
  })
  .on('checkout:success', function () {
    _currentPayment.complete();
    PaymentStore.trigger('change');
  })
  .on('checkout:fail', function () {
    PaymentStore.trigger('change');
  });

export default PaymentStore;
