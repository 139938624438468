import CategoryColour from 'utils/category_colour';
import PropTypes from 'prop-types';
import { selectResource } from 'actions/redux/reservation_to_add_actions';
import { useDispatch } from 'react-redux';

const ProductButton = ({ resource, disabled }) => {
  const dispatch = useDispatch();

  const formattedPrice = () => {
    if (!resource.get('todays_price_in_cents')) {
      return 'N/A';
    }
    return resource.price().format();
  };

  const hasPrice = () => {
    return resource.get('todays_price_in_cents') != null;
  };

  const add = (e) => {
    e.preventDefault();
    if (isDisabled()) {
      return;
    }
    dispatch(selectResource(resource));
  };

  const isDisabled = () => {
    return disabled || !hasPrice();
  };

  return (
    <li className="product-li grid-12">
      <div className="product-buttons row">
        <button
          disabled={isDisabled()}
          className="button product-button grid-grow product-button-increase"
          onClick={add}
          ref={(el) =>
            CategoryColour.set(el, false, resource.get('resource_category_id'))
          }
        >
          <i className="icon-plus" />
          {resource.get('name')}
          <div className="right">{formattedPrice()}</div>
        </button>
      </div>
    </li>
  );
};

ProductButton.propTypes = {
  resource: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ProductButton;
