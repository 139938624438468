import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

const TodoItems = ({ ticket }) => {
  if (isEmpty(ticket)) {
    return null;
  }

  const { todo_items } = ticket;
  if (isEmpty(todo_items)) {
    return null;
  }

  return (
    <div className="ticket-section">
      Todo Items
      <ul>
        {todo_items.map((todo_item, index) => {
          return <li key={`todo-${index}`}>{todo_item}</li>;
        })}
      </ul>
    </div>
  );
};

TodoItems.propTypes = {
  ticket: PropTypes.shape({
    todo_items: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default TodoItems;
