import { createReducer } from '@reduxjs/toolkit';

import {
  setTicket,
  showTicket,
  hideTicket,
  setTicketBooking,
  setVehicleTemplates,
  setValidation,
  setTicketPreviousBookingId,
} from 'actions/redux/ticket_actions';

const INITIAL_VALIDATION_STATE = { valid: false, response: {} };

const initialState = {
  visible: false,
  ticketDetails: {},
  vehicleTemplates: [],
  validation: INITIAL_VALIDATION_STATE,
  previousBookingId: null,
};

const TicketReducer = createReducer(initialState, {
  [showTicket]: (state) => {
    state.visible = true;
  },
  [hideTicket]: (state) => {
    state.visible = false;
    state.ticketDetails = {};
    state.vehicleTemplates = [];
    state.validation = INITIAL_VALIDATION_STATE;
  },
  [setTicket]: (state, action) => {
    state.validation = INITIAL_VALIDATION_STATE;
    state.vehicleTemplates = [];
    state.ticketDetails = action.payload;
  },
  [setTicketBooking]: (state, action) => {
    state.ticketDetails.booking = action.payload;
  },
  [setVehicleTemplates]: (state, action) => {
    state.vehicleTemplates = action.payload;
  },
  [setValidation]: (state, action) => {
    state.validation = action.payload;
  },
  [setTicketPreviousBookingId]: (state, action) => {
    state.previousBookingId = action.payload;
  },
});

export default TicketReducer;
