import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from 'config/config';

const login = createAction('pc_eftpos/login');
const logout = createAction('pc_eftpos/logout');
const receive = createAction('pc_eftpos/receive');
const close = createAction('pc_eftpos/close');
const error = createAction('pc_eftpos/error');
const sendKeyStart = createAction('pc_eftpos/send_key/start');
const sendKeyComplete = createAction('pc_eftpos/send_key/complete');

const sendKey = (payload) => {
  return (dispatch) => {
    dispatch(sendKeyStart());
    return axios
      .post(Config.routes.checkouts() + '/send_key', {
        payment_id: payload.uid,
        till_id: payload.till_id,
        key: payload.key,
      })
      .catch((err) => {
        if (err.response) {
          dispatch(error(err.response.error || 'Send Key Failed'));
        } else {
          dispatch(error(err));
        }
      })
      .finally(() => dispatch(sendKeyComplete()));
  };
};

export {
  login,
  logout,
  receive,
  close,
  error,
  sendKey,
  sendKeyStart,
  sendKeyComplete,
};
