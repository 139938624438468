import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AUTO_CODE_OFF } from 'models/promo_codes';

const PromoCodeAutoSwitch = ({ promoCodes, selected, onChange }) => {
  const [showOptions, setShowOptions] = useState(false);

  if (promoCodes.length <= 1) {
    return null;
  }

  const isDeactivatedPromoCode = (promoCode) => {
    return promoCode.value === AUTO_CODE_OFF.value;
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const renderPromoCodeOption = (promoCode) => {
    return (
      <div
        className={classNames('promo-code-option', 'option', {
          active: !isDeactivatedPromoCode(promoCode),
        })}
        onClick={() => onChange(promoCode)}
        key={`autoPromoCode-${promoCode.value}`}
      >
        {promoCode.label.toUpperCase()}
      </div>
    );
  };

  const renderPromoCodeOptions = () => {
    if (showOptions) {
      return (
        <div className="dropdown-panel active">
          {promoCodes.map(renderPromoCodeOption)}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="promo-code-autoswitch" onClick={toggleShowOptions}>
      <button
        className={classNames('promo-code-display', {
          active: !isDeactivatedPromoCode(selected),
        })}
      >
        <span>Automatically apply code:</span>
        <div>{selected.label.toUpperCase()}</div>
      </button>
      {renderPromoCodeOptions()}
    </div>
  );
};

PromoCodeAutoSwitch.propTypes = {
  promoCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

PromoCodeAutoSwitch.defaultProps = {
  onChange: () => {},
  selected: AUTO_CODE_OFF,
};

export default PromoCodeAutoSwitch;
