import RequestQueue from 'libs/request_queue';
import * as ServerResponses from 'actions/redux/server_responses';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';
import ProductStore from 'stores/product_store';
import { pick } from 'lodash';

const _commonAttributes = [
  'resource_id',
  'first_travel_date',
  'extra_pick_ids',
];

const _productSpecificAttributes = function () {
  if (ProductStore.passengersRequired()) {
    return ['passenger_type_numbers'];
  } else {
    return ['quantity'];
  }
};

const _buildAttributesForCreate = function (reservation) {
  const attributeKeys = _commonAttributes.concat(_productSpecificAttributes());
  return pick(reservation.attributes, ...Array.from(attributeKeys));
};

const baseUrl = (booking) => {
  return booking.urlRoot() + '/' + booking.get('id') + '/reservations';
};

const reservationUrl = (booking, reservationId) => {
  return baseUrl(booking) + '/' + reservationId + '.json';
};

export const create = (reservation, booking) => (dispatch) => {
  return RequestQueue.post(
    baseUrl(booking) + '/create_with_booking_price',
    _buildAttributesForCreate(reservation)
  )
    .done(function (response) {
      dispatch(
        ServerResponses.reservationCreated(reservation, response.reservation)
      );
      dispatch(ServerResponses.pricesUpdated(response.booking_price_breakdown));
    })
    .fail((response) =>
      dispatch(ErrorHandlerActions.handleJsonResponse(response))
    );
};

export const destroy = (reservation, booking) => (dispatch) => {
  return RequestQueue.delete(reservationUrl(booking, reservation.id), {
    id: reservation.id,
  })
    .done((response) =>
      dispatch(ServerResponses.pricesUpdated(response.booking_price_breakdown))
    )
    .fail((response) =>
      dispatch(ErrorHandlerActions.handleJsonResponse(response))
    );
};

export const update = (reservation, booking, attrs) => (dispatch) => {
  return RequestQueue.put(reservationUrl(booking, reservation.id), attrs)
    .done(dispatch(ServerResponses.reservationUpdated()))
    .fail(dispatch(ServerResponses.reservationError()));
};
