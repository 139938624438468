import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PayPrintButton = ({ disabled, caption, variant, onClick }) => {
  const className = classNames({
    button: 'button',
    right: 'right',
    large: 'large',
    default: 'default',
    'grid-grow': 'grid-grow',
    success: !disabled && variant === 'Success',
    redo: !disabled && variant === 'Redo',
  });

  return (
    <button onClick={onClick} disabled={disabled} className={className}>
      {caption}
    </button>
  );
};

PayPrintButton.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['Success', 'Redo']),
  caption: PropTypes.string,
  onClick: PropTypes.func,
};

PayPrintButton.defaultProps = {
  disabled: false,
  checkoutActionName: null,
  printActionName: null,
  variant: 'Success',
  caption: '',
  onClick: () => {},
};

export default PayPrintButton;
