import { createAction } from '@reduxjs/toolkit';

const setTicket = createAction('ticket/setTicket');
const showTicket = createAction('ticket/showTicket');
const hideTicket = createAction('ticket/hideTicket');
const setTicketBooking = createAction('ticket/setTicketBooking');
const setVehicleTemplates = createAction('ticket/setVehicleTemplates');
const setValidation = createAction('ticket/setValidation');
const setTicketPreviousBookingId = createAction(
  'ticket/setTicketPreviousBookingId'
);

const setPreviousBookingId = (bookingId) => {
  return (dispatch) => {
    dispatch(setTicketPreviousBookingId(bookingId));
  };
};

export {
  setTicket,
  showTicket,
  hideTicket,
  setTicketBooking,
  setVehicleTemplates,
  setValidation,
  setPreviousBookingId,
  setTicketPreviousBookingId,
};
