import PropTypes from 'prop-types';

const OktaError = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};

OktaError.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default OktaError;
