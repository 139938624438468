import { unRef } from '@react-hooks-library/shared';
import { useEffect, useState } from 'react';
import { useDebounce } from '@react-hooks-library/core';

/**
 *
 * Detect if a dom element is focused
 *
 * @param target - The element to listen to
 * @returns
 */
export function useDetectFocus(target) {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const el = unRef(target);

    if (!el) return;

    const onFocusIn = () => setIsFocused(true);
    const onFocusOut = () => setIsFocused(false);

    el.addEventListener('focusin', onFocusIn, true);
    el.addEventListener('focusout', onFocusOut, true);

    return () => {
      el.removeEventListener('focusin', onFocusIn, true);
      el.removeEventListener('focusout', onFocusOut, true);
    };
  }, [target]);

  // Debounce the result so we do not get intermidiate component
  // such as body when tabbing between controls.
  return useDebounce(isFocused, 100);
}
