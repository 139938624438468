import Rollbar from 'rollbar';
import Config from 'config/config';
import { fetchCurrentVersion } from 'utils/use_version_check';

if (Config.rollbar.enabled) {
  let version;
  await fetchCurrentVersion().then((response) => (version = response));

  const _rollbarConfig = {
    ...Config.rollbar,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      code_version: version,
    },
  };

  window.Rollbar = new Rollbar(_rollbarConfig);

  window.onerror = function (message, source, lineno, colno, error) {
    window.Rollbar.error(error, message);
  };
}
