import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BookingsListScreen from 'views/booking_search/bookings_list_screen';
import SingleBookingScreen from 'views/single_booking_screen';
import AppSideBar from 'views/page/app_side_bar';
import NoticeView from 'views/page/notice_view';
import BookingStore from 'stores/booking_store';
import { useNavigate } from 'react-router-dom';
import Config from 'config/config';
import * as NoticeActions from 'actions/redux/notice_actions';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import BookingReloader from 'utils/booking_reloader';

const AppScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBookingDisplayed, setIsBookingDisplayed] = useState(
    BookingStore.isBookingDisplayed
  );
  const [, setBookingUpdated] = useState(new Date()); // Hack to trigger re-render when booking store changes
  const [booking, setBooking] = useState(BookingStore.currentBooking());
  const bookingReloader = new BookingReloader(document);

  const checkClientTimeZone = () => {
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (Config.timeZone && !Config.timeZone.includes(timeZoneString)) {
      dispatch(
        NoticeActions.showError(
          `Warning: Your computer's timezone (${timeZoneString}) does not match the Quick Tickets (${Config.timeZone})`,
          'dismiss'
        )
      );

      dispatch(
        NoticeActions.showError('Please contact IT helpdesk', 'dismiss')
      );
    }
  };

  useEffect(() => {
    const onChange = () => {
      setIsBookingDisplayed(BookingStore.isBookingDisplayed);
      setBooking(BookingStore.currentBooking());
      setBookingUpdated(new Date());
    };

    bookingReloader.enable();
    BookingStore.addChangeListener(onChange);
    checkClientTimeZone();

    return () => {
      bookingReloader.disable();
      BookingStore.removeChangeListener(onChange);
    };
  }, []);

  const newBooking = (e) => {
    e.preventDefault();
    navigate('../bookings/new', { replace: true });
    dispatch(BookingActions.newBooking());
  };

  const appSideBarContent = () => {
    const showEnv = Config.showEnv ? 'logo-ribbon' : '';
    const sidebarClassnames = `grid-12 grid-medium-1 text-center nav-section sidebar-${Config.environment}`;
    return (
      <div className={sidebarClassnames}>
        <a
          className={`logo ${showEnv}`}
          href="/"
          onClick={newBooking}
          title="SeaLink Ticket App"
          data-qt={Config.name}
        />
        <AppSideBar isBookingDisplayed={isBookingDisplayed} />
      </div>
    );
  };

  const mainContent = () => {
    if (isBookingDisplayed) {
      return <SingleBookingScreen booking={booking} />;
    } else {
      return <BookingsListScreen />;
    }
  };

  return (
    <div className="row app-screen">
      <NoticeView />
      {appSideBarContent()}
      {mainContent()}
    </div>
  );
};

export default AppScreen;
