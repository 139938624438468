import PropTypes from 'prop-types';

const FolioResultCard = ({
  folioSearchResultClicked,
  selectedFolioId,
  account_id,
  first_name,
  last_name,
  rooms,
  account_type,
  folioSearchBy,
}) => {
  const selectedResultClass =
    account_id == selectedFolioId ? 'folio-active' : '';
  return (
    <tr>
      <td colSpan="3">
        <button
          onClick={() =>
            folioSearchResultClicked({
              account_id,
              first_name,
              last_name,
              rooms,
              account_type,
            })
          }
          className={`folio-search-result-button ${selectedResultClass}`}
        >
          <span className="folio-result-col">Room {rooms[0]}</span>
          <span className="folio-result-col">
            {last_name}, {first_name}
          </span>
          <span className="folio-result-col">
            {folioSearchBy === 'virtual_room' ? 'Virtual Room' : account_type}
          </span>
        </button>
      </td>
    </tr>
  );
};

FolioResultCard.propTypes = {
  folioSearchResultClicked: PropTypes.func,
  selectedFolioId: PropTypes.number,
  account_id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  rooms: PropTypes.array,
  account_type: PropTypes.string,
  folioSearchBy: PropTypes.string,
};

FolioResultCard.defaultProps = {
  folioSearchResultClicked: null,
  selectedFolioId: null,
  account_id: null,
  first_name: '',
  last_name: '',
  rooms: [],
  account_type: '',
  folioSearchBy: null,
};

export default FolioResultCard;
