import PromoCodeAutoSwitch from 'views/booking/promo_code_auto_switch';
import PromoCodeInput from 'views/booking/promo_code_input';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPromoCodeOptions,
  selectAutoPromoCodeOptions,
  selectAutoPromoCode,
} from 'selectors/promo_code_selectors';
import { selectBooking } from 'selectors/booking_selectors';
import { updatePromoCode } from 'actions/redux/thunks/booking_thunks';
import { setAutoPromoCode } from 'actions/redux/promo_code_actions';
import { AUTO_CODE_OFF } from 'models/promo_codes';

const PromoCodeContainer = () => {
  const booking = useSelector((state) => selectBooking(state));
  const promoCodes = useSelector((state) => selectPromoCodeOptions(state));
  const autoPromoCodes = useSelector((state) =>
    selectAutoPromoCodeOptions(state)
  );
  const autoPromoCode = useSelector((state) => selectAutoPromoCode(state));
  const dispatch = useDispatch();

  if (!booking) {
    return null;
  }

  const handlePromoCodeChange = (option) => {
    dispatch(
      updatePromoCode(booking.id, { promo_code_id: option?.value || '' })
    );
  };

  const isDeactivatedPromoCode = (promoCode) => {
    return promoCode.value === AUTO_CODE_OFF.value;
  };

  const handleAutoPromoCodeChange = (option) => {
    dispatch(setAutoPromoCode(option));
    if (isDeactivatedPromoCode(option)) {
      dispatch(updatePromoCode(booking.id, { promo_code_id: null }));
    } else {
      dispatch(updatePromoCode(booking.id, { promo_code_id: option.value }));
    }
  };

  const currentPromoCode = () => {
    if (!booking.promo_code_id) {
      return null;
    }
    return { label: booking.promo_code, value: booking.promo_code_id };
  };

  return (
    <div className="control-panel">
      <PromoCodeInput
        promoCodes={promoCodes}
        selected={currentPromoCode()}
        onChange={handlePromoCodeChange}
      />
      <PromoCodeAutoSwitch
        promoCodes={autoPromoCodes}
        selected={autoPromoCode}
        onChange={handleAutoPromoCodeChange}
      />
    </div>
  );
};

export default PromoCodeContainer;
