import { createAction } from '@reduxjs/toolkit';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectPrintAttempts } from 'selectors/printer_selectors';

import LocalStore from 'utils/local_store';
import { ConfigApi } from '@sealink/printers_qt';

const fetchPrintersStarted = createAction('printers/fetch/started');
const fetchPrintersSucceeded = createAction('printers/fetch/succeeded');
const fetchPrintersFailed = createAction('printers/fetch/failure');
const setPrinterId = createAction('printers/select');
const incrementPrintAttempts = createAction('printers/incrementPrintAttempts');
const resetPrintAttempts = createAction('printers/resetPrintAttempts');

const MAX_PRINT_RETRY_ATTEMPTS = 3;
const PRINTER_RETRY_DELAY_MS = 1000;

const fetchPrinters = (tenant) => (dispatch, getState) => {
  dispatch(fetchPrintersStarted());

  const state = getState();
  const user = selectAuthenticatedUser(state);

  return new ConfigApi(tenant.printing.host, user.access_token)
    .listPrintGroups(tenant.printing.catalogueId)
    .then((printerList) => {
      dispatch(fetchPrintersSucceeded(printerList));
      dispatch(selectPrinter({ id: LocalStore.get('selectedPrinter') }));
      dispatch(resetPrintAttempts());
    })
    .catch((error) => {
      if (selectPrintAttempts(state) < MAX_PRINT_RETRY_ATTEMPTS) {
        setTimeout(() => {
          dispatch(incrementPrintAttempts());
          dispatch(fetchPrinters(tenant));
        }, PRINTER_RETRY_DELAY_MS);
      } else {
        dispatch(fetchPrintersFailed(error.message));
        dispatch(resetPrintAttempts());
      }
    });
};

const selectPrinter = (printer) => (dispatch) => {
  if (printer?.id == null) {
    LocalStore.unset('selectedPrinter');
  } else {
    LocalStore.set('selectedPrinter', printer.id);
  }
  dispatch(setPrinterId(printer?.id));
};

export {
  fetchPrinters,
  fetchPrintersStarted,
  fetchPrintersSucceeded,
  fetchPrintersFailed,
  selectPrinter,
  setPrinterId,
  incrementPrintAttempts,
  resetPrintAttempts,
  MAX_PRINT_RETRY_ATTEMPTS,
  PRINTER_RETRY_DELAY_MS,
};
