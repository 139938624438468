import { useState, useEffect, useCallback } from 'react';

import AjaxSetup from 'utils/ajax_setup';

const AjaxActivity = ({ children }) => {
  const [ajaxPending, setAjaxPending] = useState(false);

  const handleOnAjaxStart = useCallback(() => {
    setAjaxPending(true);
  });

  const handleOnAjaxStop = useCallback(() => {
    setAjaxPending(false);
  });

  useEffect(() => {
    AjaxSetup.addStartListener(handleOnAjaxStart);
    AjaxSetup.addStopListener(handleOnAjaxStop);

    return () => {
      AjaxSetup.removeStartListener(handleOnAjaxStart);
      AjaxSetup.removeStopListener(handleOnAjaxStop);
    };
  }, []);

  const cloudColour = () => {
    if (ajaxPending) {
      return 'bright-cloud flash';
    } else {
      return 'dull-cloud';
    }
  };

  return (
    <div
      className={`dropdown-link animated infinite icon-upload-cloud ${cloudColour()}`}
    >
      {children}
    </div>
  );
};

export default AjaxActivity;
