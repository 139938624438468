import Backbone from 'backbone';
import Resources from 'collections/resources';
import Config from 'config/config';
import Category from 'models/category';

const NullCategory = Category.extend({
  resources() {
    return Resources.where({
      resource_category_id: null,
      product_type_id: this.get('product_type_id'),
    });
  },
});

const Categories = Backbone.Collection.extend({
  model: Category,
  url: Config.routes.resourceCategories,
  headers: { 'X-ORIGIN-KEY': Config.apiKey },
  null(product_type_id) {
    return new NullCategory({ name: 'Unknown', product_type_id });
  },
});

export default new Categories();
