import { useCallback } from 'react';
import classNames from 'classnames';

export const Button = (props) => {
  const className = classNames({
    button: true,
    default: !props.variant,
    primary: props.variant === 'primary',
    success: props.variant === 'success',
    redo: props.variant === 'redo',
    info: props.variant === 'info',
    danger: props.variant === 'danger',
    dark: props.variant === 'dark',
    large: props.size === 'lg',
    small: props.size === 'sm',
    mini: props.size === 'mini',
    full: props.block,
    disabled: props.disabled,
  });

  const handleClick = useCallback((event) => {
    if (!props.disabled) {
      props.onClick(event);
    }
  });

  return (
    <button
      className={classNames(props.className, className)}
      disabled={props.disabled}
      onClick={handleClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
