// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Used to toggle a class on touchstart events
// to give visual feedback to the user

const Trigger = {
  selectText(element) {
    if (element == null) {
      return;
    }
    return element.setSelectionRange(0, element.value.length);
  },

  moveCursorToEnd(element) {
    if (element == null) {
      return;
    }
    if (typeof element.selectionStart === 'number') {
      element.selectionStart = element.selectionEnd = element.value.length;
      element.focus();
    } else if (typeof element.createTextRange !== 'undefined') {
      element.focus();
      const range = element.createTextRange();
      range.collapse(false);
      range.select();
    }
  },

  focusParentElement(event) {
    return event.currentTarget.parentElement.classList.add('is-focused');
  },

  unfocusParentElement(event) {
    return event.currentTarget.parentElement.classList.remove('is-focused');
  },
};

export default Trigger;
