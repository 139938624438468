import { useSelector, useDispatch } from 'react-redux';
import {
  selectAvailablePaymentTypes,
  selectSelectedPaymentType,
} from 'selectors/payment_type_selectors';
import { selectPaymentType } from 'actions/redux/thunks/payment_type_thunks';
import PaymentTypeGrid from 'components/payment_type_grid';

const ConnectedPaymentTypeGrid = () => {
  const dispatch = useDispatch();

  const onSelectPaymentType = (paymentType) => {
    dispatch(selectPaymentType(paymentType));
  };

  const paymentTypes = useSelector(selectAvailablePaymentTypes);
  const selectedPaymentType = useSelector(selectSelectedPaymentType);

  return (
    <PaymentTypeGrid
      paymentTypes={paymentTypes}
      selected={selectedPaymentType}
      onSelect={onSelectPaymentType}
    />
  );
};

export default ConnectedPaymentTypeGrid;
