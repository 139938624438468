import Config from 'config/config';

const currentDeparture = ({ route_id, from_stop_id, access_token }) => {
  const url = new URL(Config.routes.services() + '/current_departure');
  const options = {
    method: 'GET',
  };

  url.searchParams.append('route_id', route_id);
  url.searchParams.append('from_stop_id', from_stop_id);

  return fetch(url.toString(), {
    options,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${access_token}`,
      'X-ORIGIN-KEY': Config.apiKey,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 404) {
      return Promise.resolve({ seasonality_ids: [] });
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  });
};

const nextDeparture = ({ route_id, from_stop_id, access_token }) => {
  const url = new URL(
    `${Config.routes.services()}/next_departure/${from_stop_id}`
  );
  const options = {
    method: 'GET',
  };

  url.searchParams.append('route_id', route_id);

  return fetch(url.toString(), {
    options,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${access_token}`,
      'X-ORIGIN-KEY': Config.apiKey,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 404) {
      return Promise.resolve({ seasonality_ids: [] });
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  });
};

export default { currentDeparture, nextDeparture };
