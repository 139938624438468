import Config from 'config/config';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

if (Config.datadog.applicationId !== null) {
  datadogLogs.init({
    clientToken: Config.datadog.clientToken,
    site: 'datadoghq.eu',
    service: Config.datadog.service,
    env: Config.datadog.environment,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.VERSION,
  });

  datadogRum.init({
    applicationId: Config.datadog.applicationId,
    clientToken: Config.datadog.clientToken,
    site: 'datadoghq.eu',
    service: Config.datadog.service,
    env: Config.datadog.environment,
    version: process.env.VERSION,
    sampleRate: 100,
    trackInteractions: true,
  });
}
