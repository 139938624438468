import { createSelector } from '@reduxjs/toolkit';
import { selectTenantConfig } from './tenant_selectors';
import { AUTO_CODE_OFF } from 'models/promo_codes';

const selectPromoCodes = (state) => state?.promoCodes?.list || [];

const selectAutoPromoCode = (state) =>
  state?.promoCodes?.autoPromoCode || AUTO_CODE_OFF;

const selectPromoCodeOptions = createSelector(
  [selectPromoCodes],
  (promoCodes) => {
    return promoCodes
      .filter((o) => o.active)
      .map((promoCode) => {
        return {
          label: promoCode.code,
          value: promoCode.id,
        };
      });
  }
);

const selectAutoPromoCodeOptions = createSelector(
  [selectTenantConfig],
  (config) => {
    const codes = config?.autoPromoCode?.promoCodes || [];
    return [
      AUTO_CODE_OFF,
      ...codes.map((promoCode) => {
        return {
          label: promoCode.code,
          value: promoCode.id,
        };
      }),
    ];
  }
);

export {
  selectPromoCodes,
  selectPromoCodeOptions,
  selectAutoPromoCodeOptions,
  selectAutoPromoCode,
};
