import { createReducer } from '@reduxjs/toolkit';

import {
  login,
  receive,
  close,
  error,
  sendKeyStart,
  sendKeyComplete,
} from 'actions/redux/pc_eftpos_actions';

const PcEftposReducer = createReducer(
  { visible: false, loading: false },
  {
    [receive]: (state, action) => {
      state.response = action.payload;
      state.visible = true;
      delete state.error;
    },
    [login]: (state) => {
      state.visible = true;
    },
    [error]: (state, action) => {
      state.error = action.payload;
      state.visible = true;
    },
    [close]: (state) => {
      state.visible = false;
      delete state.error;
    },
    [sendKeyStart]: (state) => {
      state.loading = true;
    },
    [sendKeyComplete]: (state) => {
      state.loading = false;
    },
    'checkout:success': (state) => {
      state.visible = false;
      delete state.error;
    },
    'checkout:fail': (state) => {
      state.visible = false;
      delete state.error;
    },
    'booking:reload': (state) => {
      state.visible = false;
      delete state.error;
    },
  }
);

export default PcEftposReducer;
