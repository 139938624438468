import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const selectSelected = (state) => state.tenant.selected;
const selectTenantEntities = (state) => state.tenant.entities;
const selectAvailableTenants = (state) => state.tenant.available;

const selectSelectedTenant = createSelector(
  [selectSelected, selectTenantEntities],
  (selected, entities) => {
    if (entities == null) {
      return undefined;
    }
    return entities.find((tenant) => tenant.id === selected);
  }
);

const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const selectAvailableTenantEntities = createSelector(
  [selectTenantEntities, selectAvailableTenants],
  (entities, available) => {
    if (isEmpty(entities) || isEmpty(available)) {
      return [];
    }
    const availableTenantIds = available.map((tenant) => tenant.id);

    const availableTenants = entities.filter((entity) => {
      const tenantId = entity.tenantId || entity.id;
      return availableTenantIds.includes(tenantId);
    });

    return availableTenants.sort(sortByName);
  }
);

const selectTenantConfig = createSelector(
  [selectSelected, selectTenantEntities],
  (tenant, entities) => {
    return entities.find((config) => config.id === tenant);
  }
);

export {
  selectSelectedTenant,
  selectTenantEntities,
  selectAvailableTenantEntities,
  selectTenantConfig,
};
