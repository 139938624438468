import Modal from 'libs/modal';
import Ticket from 'components/ticket';
import NumberPlateSelector from 'components/modals/ticket_modal/number_plate_selector';
import ValidationResult from 'components/modals/ticket_modal/validation_result';
import BoardedResult from 'components/modals/ticket_modal/boarded_result';
import PropTypes from 'prop-types';

const TicketModal = ({
  visible,
  processTicket,
  hideTicket,
  loadBooking,
  previousBookingId,
  invalidNumberPlate,
  ticket,
  booking,
  canValidate,
  validationResult,
  isValidated,
  isBoarded,
  numberPlate,
  vehicleTemplates,
}) => {
  const handleValidate = () => {
    const override = isValidated && !validationResult.valid;

    processTicket(ticket, override);
  };

  const handleHide = () => {
    hideTicket();
    loadBooking(previousBookingId);
  };

  const confirmText = () => {
    if (validationResult?.allowOverride) {
      return 'Override';
    }

    if (!isValidated) {
      return 'Validate';
    }

    return null;
  };

  const shouldRenderConfirmBtn = () => {
    if (isBoarded) {
      return false;
    }

    return canValidate;
  };

  const renderResult = () => {
    return (
      <>
        {isBoarded && <BoardedResult />}
        {isValidated && <ValidationResult {...validationResult} />}
      </>
    );
  };

  return (
    <Modal
      key="modal"
      visible={visible}
      closable={false}
      confirmText={confirmText()}
      confirmBtn={shouldRenderConfirmBtn()}
      cancelBtn={true}
      cancelText={isValidated || isBoarded ? 'Dismiss' : 'Cancel'}
      onCancelBtn={handleHide}
      onSaveBtn={handleValidate}
    >
      <div className="grid-12 ticket-modal">
        <Ticket ticket={ticket} booking={booking} />
        <NumberPlateSelector
          vehicleTemplates={vehicleTemplates}
          numberPlate={numberPlate}
          invalidNumberPlate={invalidNumberPlate}
        />
        {renderResult()}
      </div>
    </Modal>
  );
};

TicketModal.propTypes = {
  visible: PropTypes.bool,
  processTicket: PropTypes.func,
  loadBooking: PropTypes.func,
  previousBookingId: PropTypes.number,
  hideTicket: PropTypes.func,
  unassignClient: PropTypes.func,
  invalidNumberPlate: PropTypes.bool,
  ticket: PropTypes.object,
  booking: PropTypes.object,
  canValidate: PropTypes.bool,
  validationResult: PropTypes.object,
  isValidated: PropTypes.bool,
  isBoarded: PropTypes.bool,
  numberPlate: PropTypes.string,
  vehicleTemplates: PropTypes.array,
};

export default TicketModal;
