import { useState, useEffect } from 'react';
import { useInterval } from '@react-hooks-library/core';
import { checkStatus } from './fetch_utils';

const POLL_INTERNVAL = 60000; // 1 minute

export const fetchCurrentVersion = () => {
  const requestStartTime = new Date().getTime();
  return fetch('/version.txt')
    .then((response) => checkStatus(response, requestStartTime))
    .then((response) => response.text())
    .then((text) => {
      return text;
    });
};

const useVersionCheck = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [version, setVersion] = useState(null);

  // fetch immediately
  useEffect(() => {
    if (version === null) {
      fetchCurrentVersion().then((fetchedVersion) => {
        setVersion(fetchedVersion);
      });
    }
  }, [version]);

  // fetch on an interval
  useInterval(() => {
    fetchCurrentVersion().then((fetchedVersion) => {
      if (fetchedVersion !== version) {
        if (version !== null) {
          setUpdateAvailable(true);
        }
        setVersion(fetchedVersion);
      }
    });
  }, POLL_INTERNVAL);

  return [version, updateAvailable];
};

export default useVersionCheck;
