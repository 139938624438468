import { Component } from 'react';
import Modal from 'libs/modal';
import Button from 'components/button';
import { connect } from 'react-redux';
import { printTickets } from 'actions/redux/thunks/print_thunks';
import {
  selectDisplayText,
  selectGreenButton,
  selectRedButton,
  AuthoriseButton,
} from 'selectors/pc_eftpos_selectors';

import { BarLoader } from 'react-spinners';

export class EftposModal extends Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.state = { authData: '', showPrint: false };

    this.handleOkClicked = this.handleOkClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleAuthDataChange = this.handleAuthDataChange.bind(this);
    this.handlePrintTickets = this.handlePrintTickets.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ showPrint: true });
    }, 3000); // Make the user wait for a bit before ticket printing becomes available
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    return (
      <Modal
        key="modal"
        visible={true}
        closable={false}
        confirmText={this.props.greenButton.text}
        confirmBtn={this.props.greenButton.value >= 0}
        confirmBtnEnabled={!this.props.loading}
        onSaveBtn={this.handleOkClicked}
        cancelBtn={this.props.redButton.value >= 0}
        cancelText={this.props.redButton.text}
        cancelBtnEnabled={!this.props.loading}
        onCancelBtn={this.handleCancelClicked}
      >
        <div className="eftpos-modal">
          {this.props.error && (
            <div className="grid-12 eftpos-display-error">
              {this.props.error}
            </div>
          )}
          <div className="grid-12">
            {this.props.notifications.map((notification, index) => (
              <div key={index} className="eftpos-display-text">
                {notification}
              </div>
            ))}
          </div>
          {this.renderAuthDataField()}

          <div className="grid-12 row row-center">
            <BarLoader color={'#123abc'} loading={true} />
          </div>

          <div className="grid-12 row row-center">&nbsp;</div>

          <div className="grid-12 row row-center">
            <Button
              variant="primary"
              className="eftpos-print-tickets"
              onClick={this.handlePrintTickets}
              disabled={!this.state.showPrint}
            >
              Print Tickets
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  renderAuthDataField() {
    if (this.props.greenButton.value != AuthoriseButton.value) {
      return null;
    }

    return (
      <input
        className="grid-12"
        type="text"
        value={this.state.authData}
        placeholder="Enter auth data"
        onChange={this.handleAuthDataChange}
      />
    );
  }

  handleAuthDataChange(event) {
    this.setState({ authData: event.target.value });
    event.preventDefault();
  }

  handlePrintTickets() {
    this.props.printTickets();
  }

  handleOkClicked() {
    this.props.handleEftposClick(
      this.props.greenButton.value,
      this.state.authData
    );
  }

  handleCancelClicked() {
    this.props.handleEftposClick(
      this.props.redButton.value,
      this.state.authData
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.eftpos.error,
    loading: state.eftpos.loading,
    notifications: selectDisplayText(state),
    greenButton: selectGreenButton(state),
    redButton: selectRedButton(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    printTickets: () => {
      dispatch(printTickets());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EftposModal);
