/* eslint-disable
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import localStore from 'utils/local_store';
import EmbedAuthentication from 'libs/embed_authentication';
import AppDispatcher from 'app_dispatcher';

const AjaxSetup = {
  setupPrefilter() {
    return $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      $.extend(options, EmbedAuthentication.call(options));

      //This true is necessary in order to prevent the request from being cancelled
      return true;
    });
  },

  handleUnauthorized() {
    return $(document).ajaxError(function (event, xhr) {
      if (xhr.status === 401) {
        AppDispatcher.trigger('logout');
      }
    });
  }, // set logged out

  addStartListener(listener) {
    return $(document).ajaxStart(listener);
  },

  removeStartListener(listener) {
    return $(document).unbind('ajaxStart', listener);
  },

  addStopListener(listener) {
    return $(document).ajaxStop(listener);
  },

  removeStopListener(listener) {
    return $(document).unbind('ajaxStop', listener);
  },
};

export default AjaxSetup;
