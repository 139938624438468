import PaymentStore from 'stores/payment_store';
import create from 'api/checkout_api';
import store from 'store';
import { selectSelectedTillOption } from 'selectors/till_selectors';

const CheckoutHelper = {
  create(booking, paymentOptions, externalOptions) {
    const data = {
      booking_id: booking.id,
      payment: {
        amount_in_cents: booking.amountPaying().cents,
        payment_type_id: PaymentStore.currentPayment().payment_type_id,
        uid: PaymentStore.currentPayment().uid(),
        till_id: selectSelectedTillOption(store.getState())?.value || '',
        comment: paymentOptions.comment,
        external_id:
          PaymentStore.currentPayment().external_id ||
          externalOptions?.folio?.account_id,
      },
      ...externalOptions,
    };

    return create(data);
  },
};

export default CheckoutHelper;
