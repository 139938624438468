import store from 'store';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectSelectedTenant } from 'selectors/tenant_selectors';

const LoginStatus = {
  loggedIn() {
    const state = store.getState();
    return (
      selectAuthenticatedUser(state) && selectSelectedTenant(state) != null
    );
  },
};

export default LoginStatus;
