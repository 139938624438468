/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Fragment } from 'react';
import { isEmpty } from 'lodash';
import Trigger from 'utils/trigger';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import BookingStore from 'stores/booking_store';
import ProductStore from 'stores/product_store';
import ExtraPicks from 'views/reservations/extra_picks';
import ReservationAdjustments from 'views/reservations/adjustments';
import ReservationPassengerTypes from 'views/reservations/passenger_types';
import createReactClass from 'create-react-class';
import store from 'store';
const getState = function (reservation_id) {
  const selectedProductType = ProductStore.selectedProductType;

  const reservation = BookingStore.currentBooking()
    .reservations()
    .get(reservation_id);
  return {
    reservation,
    commentOpen: false,
    comment: reservation != null ? reservation.get('comment') : undefined,
    dropOffInformation:
      reservation != null ? reservation.get('drop_off_information') : undefined,
    pickUpInformation:
      reservation != null ? reservation.get('pick_up_information') : undefined,
    promptForPickUpAndDropOff: selectedProductType.get(
      'prompt_for_pick_up_drop_off'
    ),
  };
};

const ReservationItem = createReactClass({
  displayName: 'ReservationItem',

  getInitialState() {
    return getState(this.props.reservation.id);
  },

  componentDidMount() {
    return BookingStore.addChangeListener(this._onChange);
  },

  componentWillUnmount() {
    return BookingStore.removeChangeListener(this._onChange);
  },

  componentDidUpdate() {
    const { commentOpen, promptForPickUpAndDropOff } = this.state;

    if (commentOpen && !promptForPickUpAndDropOff) {
      return Trigger.moveCursorToEnd(this.commentField());
    }
  },

  commentField() {
    return this.refs.comment;
  },

  removeItem(e) {
    e.preventDefault();
    if (this.props.reservation.id == null) {
      return;
    }
    store.dispatch(BookingActions.destroyReservation(this.props.reservation));
  },

  _onChange() {
    if (!this.isMounted()) {
      return;
    }
    this.setState(getState(this.props.reservation.id));
    // if left open while editting comment will save and close itself
    if (this.state.commentOpen) {
      return this.saveComments();
    }
  },

  select(e) {
    e.preventDefault();
    if (this.props.reservation == null) {
      return;
    }

    store.dispatch(BookingActions.toggleReservation(this.props.reservation));
  },

  showReservationDate(reservation) {
    if (reservation.travelsToday() && !reservation.durational()) {
      return '';
    }
    return (
      <div className="item-reservation-date">
        {reservation.first_travel_date().format('MMM DD')}
        {this.showLastTravelDate(reservation)}
      </div>
    );
  },

  showLastTravelDate(reservation) {
    const firstDate = reservation.first_travel_date();
    const lastDate = reservation.last_travel_date();
    if (firstDate == null || lastDate == null || firstDate.isSame(lastDate)) {
      return;
    }

    return (
      <span>
        {' to '}
        {lastDate.format('MMM DD')}
      </span>
    );
  },

  toggleCommentEdit(e) {
    e.preventDefault();
    return this.setState({ commentOpen: !this.state.commentOpen }, function () {
      if (!this.state.commentOpen) {
        return Trigger.selectText(this.commentField());
      }
    });
  },

  updateComment(e) {
    return this.setState({ comment: e.target.value });
  },
  updatePickUpInformation(e) {
    return this.setState({ pickUpInformation: e.target.value });
  },
  updateDropOffInformation(e) {
    return this.setState({ dropOffInformation: e.target.value });
  },

  closeComment() {
    return this.setState({ commentOpen: false });
  },

  saveComments(e) {
    const {
      promptForPickUpAndDropOff,
      comment,
      dropOffInformation,
      pickUpInformation,
    } = this.state;

    if (e != null) {
      e.preventDefault();
    }

    let updateAttrs = { comment };

    if (promptForPickUpAndDropOff) {
      updateAttrs = {
        ...updateAttrs,
        ...{
          pick_up_information: pickUpInformation,
          drop_off_information: dropOffInformation,
        },
      };
    }

    store.dispatch(
      BookingActions.updateReservation(this.props.reservation.id, updateAttrs)
    );

    if (!promptForPickUpAndDropOff) {
      return this.closeComment();
    }
  },

  hasComments(reservation) {
    const comment = reservation.get('comment');
    const dropOffInformation = reservation.get('drop_off_information');
    const pickUpInformation = reservation.get('pick_up_information');

    if (this.state.commentOpen) {
      return false;
    }

    return (
      !isEmpty(comment) ||
      !isEmpty(dropOffInformation) ||
      !isEmpty(pickUpInformation)
    );
  },

  render() {
    const { reservation } = this.props;
    const selectedClass = reservation.get('selected') ? 'selected ' : '';

    return (
      <div
        className={selectedClass + 'item-reservation row'}
        data-id={reservation.id}
      >
        {this.showEdit(reservation)}

        <h4
          className="grid-7 grid-medium-6 grid-grow item-title"
          onClick={this.select}
        >
          <span>
            {reservation.get('quantity')} {reservation.get('name')}
          </span>
          {this.showReservationDate(reservation)}
          <ReservationAdjustments reservation={reservation} />
          <ReservationPassengerTypes
            reservation={reservation}
            booking={this.props.booking}
          />
        </h4>

        {this.showPrice(reservation)}
        <ExtraPicks reservation={reservation} />
        {this.showComments(reservation)}
        {this.editComments(reservation)}
      </div>
    );
  },

  showPrice(reservation) {
    return (
      <div
        className="grid-3 grid-grow text-right item-price"
        onClick={this.removeItem}
      >
        <span className="span-price">
          ${reservation.grossIncludingChildren().format()}
        </span>
        {this.showDelete()}
      </div>
    );
  },

  showDelete() {
    if (this.props.reservation.id != null) {
      return <span className="item-remove">delete</span>;
    } else {
      return '';
    }
  },

  showEdit(reservation) {
    if (reservation.id != null) {
      return (
        <div
          className="grid-base item-note-switch text-center"
          key={`resH-${reservation.id}`}
          onClick={this.toggleCommentEdit}
        >
          <i className="icon-edit" />
        </div>
      );
    } else {
      return (
        <div
          className="grid-base item-note-switch text-center"
          key={'resH-blank'}
        >
          <i className="icon-edit disabled" />
        </div>
      );
    }
  },

  editComments() {
    if (!this.state.commentOpen) {
      return '';
    }
    return (
      <Fragment>
        <div className="grid-12">
          <textarea
            ref="comment"
            className="col item-comment full"
            value={this.state.comment}
            onChange={this.updateComment}
            onBlur={this.saveComments}
            placeholder="Comment"
          />
        </div>
        {this.editPickUpAndDropOff()}
      </Fragment>
    );
  },

  editPickUpAndDropOff() {
    if (this.state.promptForPickUpAndDropOff) {
      return (
        <Fragment>
          <div className="grid-12">
            <textarea
              ref="pickUpInformationComment"
              className="col item-comment full"
              value={this.state.pickUpInformation}
              onChange={this.updatePickUpInformation}
              onBlur={this.saveComments}
              placeholder="Pick Up Comment"
            />
          </div>
          <div className="grid-12">
            <textarea
              ref="dropOffInformationComment"
              å
              className="col item-comment full"
              value={this.state.dropOffInformation}
              onChange={this.updateDropOffInformation}
              onBlur={this.saveComments}
              placeholder="Drop Off Comment"
            />
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  },

  showComments(reservation) {
    if (!this.hasComments(reservation)) {
      return null;
    }

    const comment = reservation.get('comment');
    const dropOffInformation = reservation.get('drop_off_information');
    const pickUpInformation = reservation.get('pick_up_information');

    return (
      <Fragment>
        {comment && (
          <div className="grid-12 col item-notes item-reservation-info">
            {comment}
          </div>
        )}
        {dropOffInformation && (
          <div className="grid-12 col item-notes item-reservation-info">
            {dropOffInformation}
          </div>
        )}
        {pickUpInformation && (
          <div className="grid-12 col item-notes item-reservation-info">
            {pickUpInformation}
          </div>
        )}
      </Fragment>
    );
  },
});

export default ReservationItem;
