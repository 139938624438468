import { createAction } from '@reduxjs/toolkit';

const addMessage = createAction('notice_store/messages/add');
const clearMessages = createAction('notice_store/reset');

const showError = (message, dismissAction) => (dispatch) => {
  if (dismissAction == null) {
    dismissAction = 'reload';
  }

  dispatch(
    addMessage({
      message: message,
      dismissAction: dismissAction,
      type: 'error',
    })
  );
  return Promise.resolve();
};

const clearNotice = () => (dispatch) => {
  dispatch(clearMessages());
  return Promise.resolve();
};

const loadingNotice = (message) => (dispatch) => {
  dispatch(
    addMessage({
      message: message,
      dismissAction: 'reload',
      type: 'loading',
    })
  );
  return Promise.resolve();
};

const prompt = (message) => (dispatch) => {
  dispatch(
    addMessage({
      message: message,
      dismissAction: 'reload',
      type: 'prompt',
    })
  );
  return Promise.resolve();
};

const flash = (message) => (dispatch) => {
  dispatch(
    addMessage({
      message: message,
      dismissAction: 'dismiss',
      type: 'flash',
    })
  );
  return Promise.resolve();
};

export {
  showError,
  clearNotice,
  loadingNotice,
  prompt,
  flash,
  addMessage,
  clearMessages,
};
