import { createAction } from '@reduxjs/toolkit';
import PaymentStore from 'stores/payment_store';
import AppDispatcher from 'app_dispatcher';

const setPaymentTypeId = createAction('payment_type/select');

const selectPaymentType = (paymentType) => (dispatch) => {
  dispatch(setPaymentTypeId(paymentType?.id));
  PaymentStore.setPaymentTypeId(paymentType?.id);
};

const load = () => () => {
  AppDispatcher.trigger('paymentType:load');
  return Promise.resolve();
};

const unload = () => () => {
  AppDispatcher.trigger('paymentType:unload');
  return Promise.resolve();
};

export { selectPaymentType, setPaymentTypeId, load, unload };
