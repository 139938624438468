import { useSelector } from 'react-redux';
import Loading from 'views/page/loading';
import ClientSearch from 'views/client/client_search';
import CustomerDetails from 'views/client/customer_details';
import ClientHistory from './client_history';
import classNames from 'classnames';
import { selectBooking } from 'selectors/booking_selectors';
import { selectOpenSection } from 'selectors/ui_selectors';

const ClientScreen = () => {
  const booking = useSelector((state) => selectBooking(state));
  const sectionOpen = useSelector((state) => selectOpenSection(state));

  const isOpen = sectionOpen === 'ClientScreen';

  if (booking == null) {
    return <Loading text="Client Search" />;
  }

  const className = classNames({ hide: !isOpen }, 'product-content');

  return (
    <div className={className}>
      <CustomerDetails />
      <ClientSearch />
      <ClientHistory />
    </div>
  );
};

ClientScreen.displayName = 'ClientScreen';
export default ClientScreen;
