import Backbone from 'backbone';
import Categories from 'collections/categories';

const ProductType = Backbone.Model.extend({
  categories() {
    const categories = Categories.where({ product_type_id: this.id }).filter(
      (category) => category.resources().length > 0
    );

    const nullCategory = Categories.null(this.id);
    if (nullCategory.resources().length > 0) {
      categories.push(Categories.null(this.id));
    }
    return categories;
  },
});

export default ProductType;
