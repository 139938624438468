import { useSelector, useDispatch } from 'react-redux';
import {
  selectPrinters,
  selectSelectedPrinter,
} from 'selectors/printer_selectors';
import { selectPrinter } from 'actions/redux/thunks/printer_thunks';

import PrinterSelect from 'components/payment/printer_select';

const ConnectedPrinterSelect = () => {
  const dispatch = useDispatch();

  const printers = useSelector((state) => selectPrinters(state));
  const selectedPrinterId = useSelector(
    (state) => selectSelectedPrinter(state)?.id
  );

  const onSelectPrinter = (printer) => {
    dispatch(selectPrinter(printer));
  };

  return (
    <PrinterSelect
      printers={printers}
      selectedId={selectedPrinterId}
      onSelectPrinter={onSelectPrinter}
    />
  );
};

export default ConnectedPrinterSelect;
