import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PaymentTypeButton } from 'components/payment_type_button';
import PaymentStore from 'stores/payment_store';

function renderItems(paymentTypes, selected, handleClick) {
  return paymentTypes.map((paymentType) => {
    const isSelected = selected && paymentType.id === selected.id;

    return (
      <PaymentTypeButton
        key={`paymentType-${paymentType.id}`}
        selected={isSelected}
        paymentType={paymentType}
        className={'grid-12 grid-large-6'}
        onClick={handleClick}
      />
    );
  });
}

export const PaymentTypeGrid = (props) => {
  const handleClick = useCallback((event) => {
    PaymentStore.trigger('change');
    props.onSelect(event);
  });

  const { paymentTypes, selected } = props;

  if (paymentTypes.length === 0) {
    return null;
  }

  return (
    <div className="payment-methods row">
      {renderItems(paymentTypes, selected, handleClick)}
    </div>
  );
};

PaymentTypeGrid.propTypes = {
  selected: PropTypes.object,
  paymentTypes: PropTypes.array,
  onSelect: PropTypes.func,
};

PaymentTypeGrid.defaultProps = {
  selected: null,
  paymentTypes: [],
};

export default PaymentTypeGrid;
