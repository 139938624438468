import PropTypes from 'prop-types';
import TouchSelect from 'components/touch_select';

const PrinterSelect = ({ printers, selectedId, onSelectPrinter }) => {
  const handleValueChanged = (selection) => {
    if (selection) {
      onSelectPrinter({
        id: selection.value,
        description: selection.label,
      });
    }
  };

  const printerOptions = printers.map((printer) => {
    return {
      label: printer.description,
      value: printer.id,
    };
  });

  const selectedOption = printerOptions.find((printer) => {
    return printer.value == selectedId;
  });

  return (
    <div>
      <TouchSelect
        placeholder="Printers"
        isClearable={false}
        options={printerOptions}
        value={selectedOption}
        onChange={handleValueChanged}
      />
    </div>
  );
};

PrinterSelect.displayName = 'PrinterSelect';

PrinterSelect.propTypes = {
  selectedId: PropTypes.number,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    })
  ),
  onSelectPrinter: PropTypes.func,
};

PrinterSelect.defaultProps = {
  selectedId: null,
  printers: [],
  onSelectPrinter: () => {},
};

export default PrinterSelect;
