import { useCallback } from 'react';
import { Button } from 'components/button';
import classNames from 'classnames';

function icon(code) {
  switch (code) {
    case 'cash':
      return <i className="icon-cash" />;
    case 'on_account':
      return <i className="icon-account" />;
    case 'amex':
      return <i className="icon-amex" />;
    case 'mastercard_visa':
      return <i className="icon-eftpos" />;
    case 'tcc_voucher':
      return <i className="icon-account" />;
    case 'eftpos':
      return <i className="icon-eftpos" />;
    default:
      return <i className="icon-cash" />;
  }
}

function label(code, name) {
  switch (code) {
    case 'amex':
      return 'American Express';
    case 'mastercard_visa':
      return 'MasterCard/Visa';
    default:
      return name;
  }
}

export const PaymentTypeButton = (props) => {
  const handleClick = useCallback((event) => {
    props.onClick(props.paymentType);
  });

  const { paymentType, selected } = props;
  const { code, name } = paymentType;

  const className = classNames({
    button: true,
    default: true,
    'payment-method': true,
    active: selected,
  });

  return (
    <Button
      className={classNames(props.className, className)}
      onClick={handleClick}
    >
      {icon(code)}
      {label(code, name)}
    </Button>
  );
};

PaymentTypeButton.defaultProps = {
  paymentType: {
    id: 0,
    code: '',
    name: '',
  },
  selected: false,
};

export default PaymentTypeButton;
