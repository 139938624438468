import { compact } from 'lodash';

export const selectBookingId = (state) => state?.ui?.id;

export const selectBookings = (state) => state?.entities?.booking;

export const selectClients = (state) => state?.entities?.client;

export const selectIssuedTicketEntities = (state) =>
  state.entities?.issuedTickets ?? [];

export const selectReservationEntities = (state) =>
  state.entities?.reservations ?? [];

export const selectPassengerSplitEntities = (state) =>
  state.entities?.passengerSplits ?? [];

export const selectVehicleSplitEntities = (state) =>
  state.entities?.vehicleSplits ?? [];

export const selectPaymentTypeEntities = (state) =>
  state.entities?.paymentTypes ?? [];

export const selectSelectedPaymentTypeId = (state) =>
  state?.ui?.selectedPaymentTypeId;

export const selectCommentsEntities = (state) =>
  state.entities?.booking?.comments ?? [];

export const selectReservationCodes = (state) =>
  compact(
    Object.values(state?.entities?.reservations).map((reservation) => {
      return reservation.code;
    })
  );
