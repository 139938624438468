import Config from 'config/config';

const DEFAULT_PAYMENT_TYPE_ID = 1;

class BookingDefaultPaymentTypeLookup {
  static initClass() {
    this.prototype.PAY_DIRECT = 0;
  }

  constructor(booking, paymentTypes) {
    this.booking = booking;
    this.paymentTypes = paymentTypes;
  }

  lookup() {
    const paymentType = this.isPayingOnAccount()
      ? this.onAccountPaymentType()
      : this.defaultPaymentType();
    return parseInt(paymentType.id);
  }

  isOnAccount() {
    return this.paymentMethodTypeId() !== this.PAY_DIRECT;
  }

  isPayingOnAccount() {
    return this.isOnAccount() && !this.booking.balance().isZero();
  }

  onAccountPaymentType() {
    const match = this.paymentTypes.find((paymentType) => {
      const payment_method = paymentType.get('payment_method');
      return (
        this.booking.canPayVia(paymentType.get('id')) &&
        (payment_method === 'on_account_without_reference' ||
          payment_method === 'on_account_with_reference')
      );
    });
    if (!match) {
      return this.defaultPaymentType();
    }
    return match;
  }

  paymentMethodTypeId() {
    const client = this.booking.client();
    if (!client) {
      return this.PAY_DIRECT;
    }
    return client.payment_method_type_id;
  }

  defaultPaymentType() {
    return {
      id: Config.payments?.defaultPaymentTypeId || DEFAULT_PAYMENT_TYPE_ID,
    };
  }
}
BookingDefaultPaymentTypeLookup.initClass();

export default BookingDefaultPaymentTypeLookup;
