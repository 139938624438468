import { useState } from 'react';
import Modal from 'libs/modal';
import PropTypes from 'prop-types';
import ExtraPickItem from './extra_pick_item';
import { isEmpty } from 'lodash';
const ExtraPickModal = ({
  extraPicks,
  onClose = () => {},
  onSelectExtraPicks = () => {},
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const hasExtraPicks = !isEmpty(extraPicks);

  const handleClose = () => {
    onClose();
  };

  const addReservationWithExtraPicks = () => {
    handleClose();
    onSelectExtraPicks(selectedIds);
  };

  const handleSelection = (id) => {
    const isSelected = selectedIds.includes(id);
    if (isSelected) {
      setSelectedIds((prev) => prev.filter((prevId) => prevId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };

  const renderExtraPicks = () => {
    if (!hasExtraPicks) {
      return '';
    }
    return extraPicks.map((extraPick) => {
      return (
        <ExtraPickItem
          key={`extra-item-${extraPick.id}`}
          onChange={handleSelection}
          selected={selectedIds.includes(extraPick.id)}
          extraPick={extraPick}
        />
      );
    });
  };

  const modalClass = hasExtraPicks ? '' : 'hide';

  return (
    <Modal
      key="modal"
      visible={hasExtraPicks}
      closable={true}
      cancelText="Cancel"
      confirmBtn={true}
      confirmText="Add"
      cancelBtn={true}
      onSaveBtn={addReservationWithExtraPicks}
      className={modalClass}
      onCancelBtn={handleClose}
      modalTitle="Extra Picks"
    >
      <div className="row grid-12">
        <ul className="extrapick-list list-unstyled">{renderExtraPicks()}</ul>
      </div>
    </Modal>
  );
};

ExtraPickModal.propTypes = {
  extraPicks: PropTypes.array,
  onClose: PropTypes.func,
  onSelectExtraPicks: PropTypes.func,
};

export default ExtraPickModal;
