import { createReducer } from '@reduxjs/toolkit';
import { setCountries } from 'actions/redux/country_actions';

const INITIAL_STATE = {
  list: {},
};

const CountryReducer = createReducer(INITIAL_STATE, {
  [setCountries]: (state, action) => {
    state.list = action.payload;
  },
});

export default CountryReducer;
