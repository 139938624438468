import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCountryOptions } from 'selectors/country_selectors';
import { updateBookingAttributes } from 'actions/redux/thunks/booking_thunks';
import TouchSelect from 'components/touch_select';
import { selectBooking } from 'selectors/booking_selectors';
import { isNil, isEmpty } from 'lodash';
import { selectTenantConfig } from 'selectors/tenant_selectors';
import { useFocus } from 'utils/use_focus';
import { useDebounce } from '@react-hooks-library/core';

const LocalityController = () => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => selectBooking(state));
  const countries = useSelector((state) => selectCountryOptions(state));
  const config = useSelector((state) => selectTenantConfig(state));

  const isValidBooking = booking && booking?.id > 0;

  const selectedCountry = countries.find(
    (country) => country.value === booking?.country_id
  );

  const [postCodeRequired, setPostCodeRequired] = useState(
    selectedCountry?.requiresPostCode
  );

  const [postCode, setPostCode] = useState(booking?.post_code || '');
  const debouncedPostCode = useDebounce(postCode, 350);

  const [postCodeRef, focusPostCodeInput] = useFocus();
  const [open, setOpen] = useState(isNil(booking?.country_id));
  useEffect(() => {
    setOpen(isNil(booking?.country_id));
    setPostCode(booking?.post_code || '');
  }, [booking?.country_id, booking?.post_code]);

  useEffect(() => {
    if (postCodeRequired && isEmpty(postCode)) {
      focusPostCodeInput();
    }
  }, [postCodeRequired, postCode]);

  useEffect(() => {
    if (
      !isValidBooking ||
      debouncedPostCode === undefined ||
      debouncedPostCode == booking?.post_code
    ) {
      return;
    }
    const length = debouncedPostCode?.length || null;

    if (length >= 3) {
      dispatch(
        updateBookingAttributes(booking.id, { post_code: debouncedPostCode })
      );
    }
  }, [debouncedPostCode, booking?.post_code]);

  if (!isValidBooking || !config.countries?.required) {
    return null;
  }

  const updateBookingCountry = (option) => {
    setPostCodeRequired(option?.requiresPostCode || false);

    dispatch(
      updateBookingAttributes(booking.id, { country_id: option?.value || '' })
    );
  };

  const updateBookingPostCode = (event) => {
    const postCode = event.target.value;
    setPostCode(postCode);
  };

  const renderPostCode = () => {
    if (!postCodeRequired) {
      return null;
    }

    return (
      <div className="grid-6 col text-center">
        <input
          ref={postCodeRef}
          className="font-condensed full"
          type="text"
          placeholder="Postcode"
          pattern="[0-9]*"
          maxLength="4"
          value={postCode}
          onChange={updateBookingPostCode}
        />
      </div>
    );
  };

  const renderCountry = () => {
    return (
      <div className="grid-6 col">
        <TouchSelect
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => setOpen(false)}
          placeholder="Country"
          isClearable
          options={countries}
          value={selectedCountry}
          onChange={updateBookingCountry}
        />
      </div>
    );
  };

  return (
    <div className="row">
      {renderCountry()}
      {renderPostCode()}
    </div>
  );
};

export default LocalityController;
