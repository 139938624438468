import { createReducer } from '@reduxjs/toolkit';
import { setPaymentReference } from 'actions/redux/payment_actions';
import { resetEntities } from 'actions/redux/entity_actions';

const INITIAL_STATE = {
  reference: null,
};

const PaymentReducer = createReducer(INITIAL_STATE, {
  [setPaymentReference]: (state, action) => {
    state.reference = action.payload;
  },
  [resetEntities]: () => {
    return INITIAL_STATE;
  },
});

export default PaymentReducer;
