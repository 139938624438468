import { createReducer } from '@reduxjs/toolkit';

import localStore from 'utils/local_store';

import {
  loginSucceeded,
  loginFailure,
  logoutSucceeded,
} from 'actions/redux/thunks/login_thunks';

const defaultInitialState = { authenticatedUser: undefined };

export const initialState = () => {
  const user = localStore.get('user');

  if (user != null) {
    return { ...defaultInitialState, authenticatedUser: user };
  }

  const oktaUser = localStore.get('okta-token-storage');

  if (oktaUser != null) {
    const user = {
      access_token: oktaUser?.accessToken?.accessToken,
      created_at: oktaUser?.accessToken?.claims.auth_time,
      expires_in: oktaUser?.accessToken?.expiresAt,
      scope: oktaUser?.accessToken?.scopes,
      token_type: oktaUser?.accessToken?.tokenType,
      username: oktaUser?.idToken?.claims.name,
    };
    localStore.set('user', user);
    return {
      ...defaultInitialState,
      authenticatedUser: user,
    };
  }

  return defaultInitialState;
};

const LoginReducer = createReducer(initialState(), {
  [loginSucceeded]: (state, action) => {
    state.authenticatedUser = action.payload;
    localStore.set('user', action.payload);
  },
  [loginFailure]: () => {
    localStore.unset('user');
    localStore.unset('defaultProductType');
    localStore.unset('defaultTenant');
    localStore.unset('okta-cache-storage');
    localStore.unset('okta-token-storage');
    localStore.unset('okta-shared-transaction-storage');
    localStore.unset('okta-original-uri-storage');
    return defaultInitialState;
  },
  [logoutSucceeded]: () => {
    localStore.unset('user');
    localStore.unset('defaultProductType');
    localStore.unset('defaultTenant');
    localStore.unset('okta-cache-storage');
    localStore.unset('okta-token-storage');
    localStore.unset('okta-shared-transaction-storage');
    localStore.unset('okta-original-uri-storage');
    return defaultInitialState;
  },
});

export default LoginReducer;
