/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';
import Money from '@sealink/money_beans';
import MoneyString from 'libs/money_string';
import PaymentTypes from 'collections/payment_types';
import UidGenerator from 'libs/uid_generator';
import BookingDefaultPaymentTypeLookup from 'services/booking_default_payment_type_lookup';

let _uid = null;

class Payment {
  constructor(opts) {
    if (opts == null) {
      opts = {};
    }
    this.booking = opts.booking;

    const payment_type_id =
      opts.selectedPaymentType?.id ||
      new BookingDefaultPaymentTypeLookup(this.booking, PaymentTypes).lookup();

    this.amount_entered = new MoneyString();

    this.payment_type_id = payment_type_id;
    this.folioDetails = {};
  }

  paymentType() {
    return PaymentTypes.get(this.payment_type_id);
  }

  isRoomCharge() {
    return this.paymentType().get('payment_method') === 'room_charge';
  }

  selectPaymentType(id) {
    return (this.payment_type_id = id);
  }

  amountEntered() {
    return this.amount_entered.money();
  }

  amountPaying() {
    if (this.amountEntered().isZero()) {
      return this.booking.balance();
    }
    return _.min(
      [this.amountEntered(), this.booking.balance()],
      (money) => money.cents
    );
  }

  resetAmountEntered() {
    return this.amount_entered.reset();
  }

  appendDigitToAmountEntered(digit) {
    return this.amount_entered.appendDigit(digit);
  }

  determineDue() {
    if (this.booking.refundable()) {
      return new Money(0);
    }
    return this.calculateNonRefundableDue();
  }

  calculateNonRefundableDue() {
    const amountTendered = _.max(
      [this.amountPaying(), this.amountEntered()],
      (money) => money.cents
    );
    return this.booking.balance().subtract(amountTendered);
  }

  recordChange() {
    return (this.change = this.determineDue());
  }

  uid() {
    return _uid != null ? _uid : (_uid = UidGenerator.generate());
  }

  resetUid() {
    return (_uid = null);
  }

  complete() {
    this.recordChange();
    this.resetAmountEntered();
    return this.resetUid();
  }

  setFolioDetails(newFolioDetails) {
    return (this.folioDetails = newFolioDetails);
  }
}

export default Payment;
