import { createAction } from '@reduxjs/toolkit';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { countries } from 'api/countries_api';
import { setCountries } from 'actions/redux/country_actions';

const fetchCountriesStarted = createAction('countries/fetch/started');
const fetchCountriesSucceeded = createAction('countries/fetch/succeeded');
const fetchCountriesFailed = createAction('countries/fetch/failure');

const fetchCountries = () => (dispatch, getState) => {
  dispatch(fetchCountriesStarted());

  const user = selectAuthenticatedUser(getState());
  return countries(user?.access_token)
    .then((result) => {
      dispatch(setCountries(result));
      dispatch(fetchCountriesSucceeded(result));
    })
    .catch((error) => dispatch(fetchCountriesFailed(error.message)));
};

export { fetchCountries };
