import { memo } from 'react';

const BoardedResult = () => {
  return (
    <div className="validation-result success">
      <div>Boarded</div>
    </div>
  );
};

export default memo(BoardedResult);
