import BookingListControls from 'components/booking_search/booking_list_controls';
import { formatDistanceToNow, parseJSON } from 'date-fns';
import Config from 'config/config';
import Money from '@sealink/money_beans';
import { useBookingSearch } from './use_booking_search';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { newBooking } from 'actions/redux/thunks/booking_thunks';

const BookingsListScreen = () => {
  const {
    bookings,
    totalPages,
    currentPage,
    nextPage,
    previousPage,
    reset,
    search,
  } = useBookingSearch();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSearch = (input) => {
    search(input);
  };

  const onNextPage = () => {
    nextPage();
  };

  const onPreviousPage = () => {
    previousPage();
  };

  const onShowRecentBookings = () => {
    reset();
  };

  const onClose = () => {
    navigate('../bookings/new', { replace: true });
    dispatch(newBooking());
  };

  const clientHyperlink = (client) => {
    if (client == null) {
      return null;
    }

    const { party_id, name } = client;
    if (party_id == null || name == null) {
      return null;
    }

    return (
      <a
        target="_blank"
        href={`${Config.quicktravelUrl}/parties/${party_id}`}
        rel="noreferrer"
      >
        {name}
      </a>
    );
  };

  const noResults = () => {
    return (
      <tr>
        <td colSpan="6" className="text-center">
          {' '}
          No results matched your search query!{' '}
        </td>
      </tr>
    );
  };

  const renderList = () => {
    if (bookings.length === 0) {
      return noResults();
    }

    return bookings.map((booking) => {
      return (
        <tr key={`booking-list-${booking.id}`}>
          <td>
            <Link to={`../bookings/${booking.id}`}>{booking.reference}</Link>
          </td>
          <td>
            {formatDistanceToNow(parseJSON(booking.created_at), {
              addSuffix: true,
            })}
          </td>
          <td>
            {formatDistanceToNow(parseJSON(booking.updated_at), {
              addSuffix: true,
            })}
          </td>
          <td>{clientHyperlink(booking.client)}</td>
          <td>{booking.customer_contact_name}</td>
          <td>${new Money(booking.gross_in_cents).format()}</td>
        </tr>
      );
    });
  };

  return (
    <div className="grid-12 grid-medium-11 row row-stretch booking-list">
      <div className="grid-12 booking-list-content">
        <BookingListControls
          onShowRecentBookings={onShowRecentBookings}
          onClose={onClose}
          onSearch={onSearch}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />

        <div className="grid-12 section col">
          <table
            className="table table-horizontal table-striped booking-results"
            width="100%"
          >
            <thead>
              <tr>
                <th>Booking Reference</th>
                <th>Booking Made</th>
                <th>Last Updated At</th>
                <th>Client</th>
                <th>Customer Name</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

BookingsListScreen.displayName = 'BookingsListScreen';

export default BookingsListScreen;
