import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Config from 'config/config';

const issuer = Config.oktaIssuer || '';
const clientId = Config.oktaClientId || '';
const redirect = Config.oktaAppBase + '/callback' || '';

export const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirect,
  scopes: ['openid', 'profile', 'email'],
});

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(
    toRelativeUrl(originalUri || '/', window.location.origin)
  );
};
