import { setEntity } from 'actions/redux/entity_actions';
import { setConsumerType } from 'actions/redux/thunks/consumer_type_thunks';
import { normalize, schema } from 'normalizr';

const normalizePassengerTypes = (json) => {
  return (dispatch) => {
    const typeSchema = new schema.Array(new schema.Entity('passengerTypes'));

    const normalized = normalize(json, typeSchema);
    dispatch(setConsumerType(normalized.entities));
    return Promise.resolve(normalized);
  };
};

const normalizeBookingAttributes = (json) => {
  return (dispatch) => {
    const passengerSplitSchema = new schema.Entity('passengerSplits');
    const vehicleSplitSchema = new schema.Entity('vehicleSplits');

    const clientSchema = new schema.Entity('client');
    const paymentTypeSchema = new schema.Entity('paymentTypes');
    const reservationSchema = new schema.Entity('reservations', {
      passenger_splits: [passengerSplitSchema],
      vehicle_splits: [vehicleSplitSchema],
    });
    const paymentSchema = new schema.Entity('payments');
    const passengerSchema = new schema.Entity('passengers');
    const vehiclesSchema = new schema.Entity('vehicles');
    const partySchema = new schema.Entity('parties');
    const adjustmentSchema = new schema.Entity('adjustments', {
      creator: partySchema,
    });
    const todoItemsSchema = new schema.Entity('todoItems');
    const issuedTicketsSchema = new schema.Entity('issuedTickets');

    const bookingSchema = new schema.Entity('booking', {
      client: clientSchema,
      payment_types_attributes: [paymentTypeSchema],
      reservations_attributes: [reservationSchema],
      payment_attributes: [paymentSchema],
      passengers_attributes: [passengerSchema],
      vehicles_attributes: [vehiclesSchema],
      adjustments_attributes: [adjustmentSchema],
      todo_items: [todoItemsSchema],
      issued_tickets_attributes: [issuedTicketsSchema],
    });

    const normalized = normalize(json, bookingSchema);
    dispatch(setEntity(normalized.entities));
    return Promise.resolve(normalized);
  };
};

export { normalizeBookingAttributes, normalizePassengerTypes };
