import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPrintersStarted,
  fetchPrintersSucceeded,
  fetchPrintersFailed,
  setPrinterId,
  incrementPrintAttempts,
  resetPrintAttempts,
} from 'actions/redux/thunks/printer_thunks';

const INITIAL_STATE = {
  entities: [],
  selectedPrinterId: null,
  printAttempts: 0,
};

const printerReducer = createReducer(INITIAL_STATE, {
  [fetchPrintersStarted]: (state) => {
    delete state.error;
  },
  [fetchPrintersSucceeded]: (state, action) => {
    state.entities = action.payload;
  },
  [fetchPrintersFailed]: (state, action) => {
    state.error = action.payload;
  },
  [setPrinterId]: (state, action) => {
    state.selectedPrinterId = action.payload;
  },
  [incrementPrintAttempts]: (state) => {
    state.printAttempts += 1;
  },
  [resetPrintAttempts]: (state) => {
    state.printAttempts = 0;
  },
});

export default printerReducer;
