import { useSelector, useDispatch } from 'react-redux';
import TouchDate from 'components/touch_date';
import { changeDate } from 'actions/redux/reservation_to_add_actions';
import { selectReservationToAddDate } from 'selectors/reservation_to_add_selectors';
import { parseISO } from 'date-fns';

const BookingDate = () => {
  const date = useSelector((state) => selectReservationToAddDate(state));
  const dispatch = useDispatch();

  const onClose = (date) => {
    dispatch(changeDate(parseISO(date)));
  };

  return <TouchDate date={date} onClose={onClose} />;
};

export default BookingDate;
