import { get } from 'lodash';

export const selectAuthenticatedUser = (state) =>
  get(state, 'login.authenticatedUser');
export const selectAvailableTenants = (state) =>
  get(state, 'login.availableTenants');
export const selectSelectedTenant = (state) =>
  get(state, 'login.selectedTenant');
export const selectAccessToken = (state) =>
  get(state, 'login.authenticatedUser.access_token');
