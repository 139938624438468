/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import store from 'store';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';

class BookingReloader {
  constructor(document) {
    this._handleVisibilityChange = this._handleVisibilityChange.bind(this);
    this._typeOfHidden = this._typeOfHidden.bind(this);
    this.document = document;
  }

  enable() {
    return this.document.addEventListener(
      this._visibilityChangeEvent(),
      this._handleVisibilityChange
    );
  }

  disable() {
    return this.document.removeEventListener(
      this._visibilityChangeEvent(),
      this._handleVisibilityChange
    );
  }

  _handleVisibilityChange() {
    if (this._isHidden()) {
      return;
    }
    store.dispatch(BookingActions.reloadBooking());
  }

  _isHidden() {
    return this.document[this._typeOfHidden()];
  }

  _visibilityChangeEvent() {
    const browserPrefix = this._browserPrefix();
    if (browserPrefix === null) {
      return null;
    }
    return browserPrefix + 'visibilitychange';
  }

  _typeOfHidden() {
    return _.find(
      ['hidden', 'mozHidden', 'msHidden', 'webkitHidden'],
      (typeOfHidden) => {
        return typeof this.document[typeOfHidden] !== 'undefined';
      }
    );
  }

  _browserPrefix() {
    const typeOfHidden = this._typeOfHidden();
    if (!typeOfHidden) {
      return null;
    }
    const match = typeOfHidden.match('(.*)[h|H]idden');
    if (!match) {
      return null;
    }
    return match[1];
  }
}

export default BookingReloader;
