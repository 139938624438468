import $ from 'jquery';
import 'pickadate/picker.date';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { format } from 'date-fns';

const TouchDate = ({ date = '', onClose = () => {} }) => {
  const inputRef = useRef();

  useEffect(() => {
    const $input = $(inputRef.current).pickadate({
      format: 'dd/mm/yyyy',
      clear: false,
    });

    const picker = $input.pickadate('picker');

    picker.on('close', () => {
      const selected = picker.get('select', 'yyyy-mm-dd');
      onClose(selected);
    });

    return () => {
      picker.stop();
    };
  }, []);

  useEffect(() => {
    const $input = $(inputRef.current).pickadate();

    const picker = $input.pickadate('picker');
    picker.set('select', date);
  }, [date]);

  return (
    <div className="control-panel">
      <input
        ref={inputRef}
        type="text"
        className="full side-input"
        readOnly
        value={format(date, 'dd/MM/yyyy')}
      />
    </div>
  );
};

export default TouchDate;

TouchDate.propTypes = {
  date: PropTypes.instanceOf(Date),
  onClose: PropTypes.func,
};
