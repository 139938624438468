import PropTypes from 'prop-types';
import classNames from 'classnames';

const NumberPadKey = ({ keyButton, onKeyPress }) => {
  const sendKey = () => {
    onKeyPress(keyButton);
  };

  const onNumberPress = (e) => {
    e.preventDefault();
    sendKey();
  };

  const className = classNames({
    button: true,
    large: true,
    default: true,
    'grid-4': true,
    'keypad-key': true,
    'grid-grow': keyButton === '0',
  });

  return (
    <button className={className} onClick={onNumberPress}>
      {keyButton}
    </button>
  );
};

NumberPadKey.propTypes = {
  keyButton: PropTypes.string,
  onKeyPress: PropTypes.func,
};

export default NumberPadKey;
