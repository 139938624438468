import { oktaAuth } from 'api/okta_api';
import Config from 'config/config';

const OktaLoginButton = () => {
  const oktaEnabled = Config.oktaEnabled;

  return (
    oktaEnabled && (
      <button
        type="button"
        className="button primary full login-button"
        onClick={() => {
          oktaAuth.signInWithRedirect();
        }}
      >
        OKTA Login <i className="icon-login" />
      </button>
    )
  );
};

export default OktaLoginButton;
