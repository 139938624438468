import Config from 'config/config';
import { fetchJson } from 'utils/fetch_utils';
import $ from 'jquery';

const search = (text, accessToken) => {
  const url = new URL(Config.routes.clientSearch());
  url.searchParams.append('search_query', text);

  const request = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-ORIGIN-KEY': Config.apiKey,
    },
  };

  return fetchJson(url.toString(), request);
};

const searchClientVehicleTemplates = (client_id) => {
  return $.get(Config.routes.clientTemplatesSearch(client_id));
};

export { search, searchClientVehicleTemplates };
