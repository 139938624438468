import ClientHistory from 'components/client/client_history';
import { useDispatch, useSelector } from 'react-redux';
import { assignClient } from 'actions/redux/thunks/client_thunks';
import { selectClientHistory } from 'selectors/clients_selectors';

const ConnectedClientHistory = () => {
  const dispatch = useDispatch();
  const handleAssignClient = (client) => {
    dispatch(assignClient(client));
  };
  const clients = useSelector((state) => selectClientHistory(state));

  return (
    <ClientHistory clients={clients} onAssignClient={handleAssignClient} />
  );
};

export default ConnectedClientHistory;
