import { Fragment } from 'react';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

const ReservationDetails = ({ ticket, booking }) => {
  if (isEmpty(booking)) {
    return null;
  }
  const renderDepartureTime = () => {
    const { reservation } = ticket;
    if (isEmpty(reservation)) {
      return null;
    }

    const { departure_time, departure_date } = reservation;

    if (departure_date || departure_time) {
      return (
        <Fragment>
          <dt>Departure: </dt>
          <dd>
            {departure_time} {departure_date}
          </dd>
        </Fragment>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="ticket-section reservation">
      <dl>
        <dt>Booking: </dt>
        <dd>{booking.reference}</dd>
        {renderDepartureTime()}
      </dl>
    </div>
  );
};

ReservationDetails.propTypes = {
  ticket: PropTypes.shape({
    reservation: PropTypes.shape({
      departure_time: PropTypes.string,
      departure_date: PropTypes.string,
    }),
  }),
  booking: PropTypes.shape({
    reference: PropTypes.string.isRequired,
  }),
};

export default ReservationDetails;
