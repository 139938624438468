import { useState } from 'react';
import Money from '@sealink/money_beans';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const ExtraPickItem = ({
  extraPick,
  selected = false,
  onChange = () => {},
}) => {
  const [checked, setChecked] = useState(selected);

  const formattedPrice = () => {
    if (!extraPick.todays_price_in_cents) {
      return 'N/A';
    }
    return new Money(extraPick.todays_price_in_cents).format();
  };

  return (
    <div className="grid-12 extrapick-item">
      <div className="extrapick-price right">${formattedPrice()}</div>
      <Switch
        checked={checked}
        onChange={(checked) => {
          setChecked(checked);
          onChange(extraPick.id, checked);
        }}
      />
      <label className="sp-label extrapick-title">
        <span className="sp-ui" />
        {extraPick.name}
        <div className="text-mute text-small extrapick-desc">
          {extraPick.description}
        </div>
      </label>
    </div>
  );
};

ExtraPickItem.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  extraPick: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    todays_price_in_cents: PropTypes.number,
  }),
};

export default ExtraPickItem;
