import React, { useState, useEffect } from 'react';
import AjaxActivity from './ajax_activity';
import Config from 'config/config';

const pingPeriod = 30000; // 10 seconds

const ServerPinger = () => {
  const [serverStates, setServerStates] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);

  const servers = [
    Config.quicktravelUrl,
    'https://az-qtprint.quicktravel.com.au/',
  ];

  const pingServer = async (server) => {
    const startTime = Date.now();
    try {
      const controller = new AbortController();
      const requestTimeoutId = setTimeout(() => {
        controller.abort();
        window.Rollbar.error(
          `Timeout contacting ${server}`,
          window.serversResponses
        );
      }, pingPeriod);

      const _response = await fetch(server, {
        timeout: pingPeriod,
        signal: controller.signal,
        mode: 'no-cors',
      });
      clearTimeout(requestTimeoutId);

      const endTime = Date.now();
      const responseTime = endTime - startTime;

      setServerStates((prevServerStates) => ({
        ...prevServerStates,
        [server]: {
          responseTimes: [
            ...(prevServerStates[server]?.responseTimes || []),
            responseTime,
          ],
          minTime: Math.min(
            ...(prevServerStates[server]?.responseTimes || []),
            responseTime
          ),
          maxTime: Math.max(
            ...(prevServerStates[server]?.responseTimes || []),
            responseTime
          ),
          averageTime:
            (prevServerStates[server]?.responseTimes || []).reduce(
              (acc, current) => acc + current,
              responseTime
            ) /
            ((prevServerStates[server]?.responseTimes || []).length + 1),
          timeouts: prevServerStates[server]?.timeouts || 0,
        },
      }));
    } catch (error) {
      console.error(`Error pinging ${server}: ${error.message}`);
      setServerStates((prevServerStates) => ({
        ...prevServerStates,
        [server]: {
          responseTimes: [
            ...(prevServerStates[server]?.responseTimes || []),
            null,
          ],
          minTime: prevServerStates[server]?.minTime,
          maxTime: prevServerStates[server]?.maxTime,
          averageTime: prevServerStates[server]?.averageTime,
          timeouts: (prevServerStates[server]?.timeouts || 0) + 1,
        },
      }));
    }
  };

  // Write the response details to the global object
  servers.forEach((server) => {
    window.serversResponses = {
      ...(window.serversResponses || {}),
      [server]: { ...serverStates[server] },
    };
  });

  const pingServersRegularly = () => {
    servers.forEach((server) => {
      setInterval(() => {
        pingServer(server);
      }, pingPeriod);
    });
  };

  // Write down the initial start datetime
  useEffect(() => {
    window.serversResponses = {
      ...(window.serversResponses || {}),
      monitoringStarted: Date.now(),
      pingPeriod,
    };
  }, []);

  useEffect(() => {
    // Perform once, and then check back
    servers.forEach((server) => {
      pingServer(server);
    });
    pingServersRegularly();
  }, []);

  const togglePopup = (e) => {
    e.preventDefault();
    setPopupVisible(!popupVisible);
  };

  const serverSummary = () => {
    return servers.map((server) => (
      <li key={server}>
        {server} Details:
        <ul>
          <ul>
            Response Times:{' '}
            {serverStates[server]?.responseTimes
              .slice(-5) // Show only the last 5 results
              .join(', ') || 'Pinging...'}
          </ul>
          <ul>
            Minimum Successful Ping Time: {serverStates[server]?.minTime} ms
          </ul>
          <ul>Maximum Ping Time: {serverStates[server]?.maxTime} ms</ul>
          <ul>Average Ping Time: {serverStates[server]?.averageTime} ms</ul>
          <ul>Number of Timeouts: {serverStates[server]?.timeouts}</ul>
        </ul>
      </li>
    ));
  };

  const panelClass = popupVisible ? 'active' : '';

  return (
    <div>
      <a
        className={`dropdown-link ${panelClass}`}
        href="#"
        onClick={togglePopup}
      >
        <AjaxActivity>
          <em className="text-small">(stats)</em>
        </AjaxActivity>
      </a>
      <div
        className={`dropdown-panel bottom ${panelClass} text-left`}
        style={{ minWidth: '600px' }}
      >
        <div className="dropdown-panel-info">
          <em>Network Stats</em>
        </div>

        <ul>{popupVisible && serverSummary()}</ul>
      </div>
    </div>
  );
};

export default ServerPinger;
