import Money from '@sealink/money_beans';
import AnimatedMoneyLabel from './animated_money_label';
import Loading from 'views/page/loading';
import PropTypes from 'prop-types';

const BookingTotal = ({
  loading,
  surchargeInCents,
  commissionInCents,
  totalInCents,
  paidInCents,
}) => {
  const surcharge = new Money(surchargeInCents);
  const commission = new Money(commissionInCents);
  const total = new Money(totalInCents);
  const paid = new Money(paidInCents);

  const balance = total.subtract(paid).add(surcharge);

  const renderBookingTotal = () => {
    return (
      <div className="booking-total">
        <span className="left text-mute">Total</span>
        <span className="booking-price">${total.format()}</span>
      </div>
    );
  };

  const renderPaid = () => {
    return (
      <div className="booking-total">
        <span className="left text-mute">Paid</span>
        <span hidden={surcharge.isZero()} className="booking-price surcharge">
          Surcharge: {surcharge.format()}
        </span>
        <span className="booking-price">${paid.format()}</span>
      </div>
    );
  };

  const renderBalance = () => {
    return (
      <div className="booking-total">
        <span className="left text-mute">Balance</span>
        <AnimatedMoneyLabel amount={balance.dollars()} />
      </div>
    );
  };

  const renderCommission = () => {
    return (
      <div hidden={commission.isZero()} className="booking-total">
        <span className="left text-mute">Commission</span>
        <AnimatedMoneyLabel amount={commission.dollars()} />
      </div>
    );
  };

  if (loading) {
    return <Loading text="Totals" />;
  }

  return (
    <div className="booking-totals text-right grid-12">
      <div>
        {renderBookingTotal()}
        {renderPaid()}
        {renderBalance()}
        {renderCommission()}
      </div>
    </div>
  );
};

BookingTotal.propTypes = {
  loading: PropTypes.bool,
  surchargeInCents: PropTypes.number,
  commissionInCents: PropTypes.number,
  totalInCents: PropTypes.number,
  paidInCents: PropTypes.number,
};

BookingTotal.defaultProps = {
  loading: false,
  surchargeInCents: 0,
  commissionInCents: 0,
  totalInCents: 0,
  paidInCents: 0,
};

export default BookingTotal;
