import Config from 'config/config';
import { checkStatus } from 'utils/fetch_utils';

export const createAdjustment = (
  adjustment_props,
  booking_id,
  access_token
) => {
  const url = `${Config.routes.bookings()}/${booking_id}/adjustments.json`;
  const requestStartTime = new Date().getTime();
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json;charset=utf-8',
      'X-ORIGIN-KEY': Config.apiKey,
    },
    body: JSON.stringify(adjustment_props),
  })
    .then((response) => checkStatus(response, requestStartTime, url))
    .then((response) => response.json());
};
