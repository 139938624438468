import Backbone from 'backbone';

const PaymentType = Backbone.Model.extend({
  surchargeMultiplier() {
    return parseFloat(this.get('transaction_fee')) / 100.0;
  },

  name() {
    return this.get('name');
  },

  surchargeable() {
    return this.get('surchargeable');
  },
});

export default PaymentType;
