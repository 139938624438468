import { createReducer } from '@reduxjs/toolkit';
import {
  setBookingModalVisibility,
  setCommentsModalVisibility,
  setQuantityModalVisibility,
  setBookingId,
  setOpenSection,
  toggleReservation,
  selectReservations,
  unselectReservation,
} from 'actions/redux/ui_actions';
import { compact } from 'lodash';

import { setPaymentTypeId } from 'actions/redux/thunks/payment_type_thunks';

const INITIAL_UI_STATE = {
  id: 0,
  bookingCompletedModalVisible: false,
  commentsModalVisible: false,
  quantityModalVisible: false,
  openSection: '',
  selectedPaymentTypeId: 0,
  selectedReservationIds: [],
};

const uiReducer = createReducer(INITIAL_UI_STATE, {
  [setBookingModalVisibility]: (state, action) => {
    state.bookingCompletedModalVisible = action.payload;
  },
  [setBookingId]: (state, action) => {
    state.id = action.payload;
  },
  [setPaymentTypeId]: (state, action) => {
    state.selectedPaymentTypeId = action.payload;
  },
  [setCommentsModalVisibility]: (state, action) => {
    state.commentsModalVisible = action.payload;
  },
  [setQuantityModalVisibility]: (state, action) => {
    state.quantityModalVisible = action.payload;
  },
  [setOpenSection]: (state, action) => {
    state.openSection = action.payload;
  },
  [toggleReservation]: (state, action) => {
    const reservationId = action.payload;
    const currentSelection = state.selectedReservationIds;
    if (currentSelection.includes(reservationId)) {
      state.selectedReservationIds = currentSelection.filter(
        (item) => item !== reservationId
      );
    } else {
      state.selectedReservationIds = [...currentSelection, action.payload];
    }
  },
  [unselectReservation]: (state, action) => {
    const reservationId = action.payload;
    const currentSelection = state.selectedReservationIds;
    if (currentSelection.includes(reservationId)) {
      state.selectedReservationIds = currentSelection.filter(
        (item) => item !== reservationId
      );
    }
  },
  [selectReservations]: (state, action) => {
    state.selectedReservationIds = compact(action.payload);
  },
});

export default uiReducer;
