import { useNavigate } from 'react-router-dom';
import BookingStore from 'stores/booking_store';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import * as NoticeActions from 'actions/redux/notice_actions';
import { useSelector, useDispatch } from 'react-redux';
import { selectBookingIsCompleted } from 'selectors/booking_selectors';
import { selectOpenSection } from 'selectors/ui_selectors';

const CompleteBookingButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sectionOpen = useSelector((state) => selectOpenSection(state));
  const isBookingCompleted = useSelector((state) =>
    selectBookingIsCompleted(state)
  );

  const isCheckoutSectionOpen = sectionOpen === 'PaymentScreen';
  const text = isCheckoutSectionOpen ? 'Cancel' : 'Complete';
  const btnClass = isCheckoutSectionOpen ? 'default' : 'primary';

  const buttonClicked = (e) => {
    e.preventDefault();
    if (isCheckoutSectionOpen) {
      if (isBookingCompleted) {
        dispatch(
          NoticeActions.prompt(
            'This booking has already been paid, are you sure you want to go back to it?'
          )
        );
      }
      navigate('../bookings/new', { replace: true });
      dispatch(BookingActions.displayProductListScreen());
    } else {
      navigate(`../bookings/${BookingStore.currentBooking().id}/pay`, {
        replace: true,
      });
      dispatch(BookingActions.payCurrentBooking());
    }
  };

  return (
    <button
      className={`grid-12 grid-medium-4 button ${btnClass} large full button-complete`}
      onClick={buttonClicked}
    >
      {text}
    </button>
  );
};

export default CompleteBookingButton;
