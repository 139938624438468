import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFocus } from 'utils/use_focus';
import Modal from 'libs/modal';
import { NumberPadKey } from 'components/number_pad';

const QuantityModal = ({
  open,
  onClose = () => {},
  onUpdateQuantity = () => {},
}) => {
  const [quantity, setQuantity] = useState('');
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (open) {
      setQuantity('');
      setInputFocus();
    }
  }, [open]);

  const saveQuantity = (e) => {
    e.preventDefault();
    onUpdateQuantity(parseInt(quantity, 10));
    closeModal();
  };

  const closeModal = () => {
    onClose();
  };

  const cancelModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    closeModal();
  };

  const closeOnEnter = (e) => {
    if (e.key === 'Enter') {
      saveQuantity(e);
    }
  };

  const keyPad = () => {
    return ['9', '8', '7', '6', '5', '4', '3', '2', '1', '0', 'c'];
  };

  const keyPressed = (key) => {
    const newQuantity = key === 'c' ? '' : quantity + key;
    setQuantity(newQuantity);
  };

  const onKeyPress = (e) => {
    setQuantity(e.target.value);
  };

  const quantityPad = () => {
    return (
      <div className="keypad row row-reverse text-center-row">
        {keyPad().map((key) => {
          return (
            <NumberPadKey
              keyButton={key}
              key={`key-${key}`}
              onKeyPress={keyPressed}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      key="modal"
      visible={open}
      closable={true}
      onHide={closeModal}
      cancelText="Cancel"
      confirmBtn={true}
      confirmText="Ok"
      cancelBtn={true}
      onSaveBtn={saveQuantity}
      className={open ? '' : 'hide'}
      onCancelBtn={cancelModal}
      modalTitle="Quantity"
    >
      <div>
        <input
          ref={inputRef}
          pattern="[0-9]*"
          type="number"
          step="1"
          value={quantity}
          onChange={onKeyPress}
          onKeyPress={closeOnEnter}
          className="full quantity-number-input"
          placeholder="quantity"
        />
        {quantityPad()}
      </div>
    </Modal>
  );
};

QuantityModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateQuantity: PropTypes.func,
};

export default QuantityModal;
