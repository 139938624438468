import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import PaymentStore from 'stores/payment_store';
import RequestQueueStore from 'stores/request_queue_store';
import BookingStore from 'stores/booking_store';
import { useNavigate } from 'react-router-dom';
import * as PaymentActions from 'actions/redux/payment_actions';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import * as NoticeActions from 'actions/redux/notice_actions';
import PaymentReference from 'views/payment/payment_reference';

import PayableTotal from 'components/payment/payable_total';
import TillSelect from 'views/checkout/till_select';
import {
  NewBookingButton,
  PayPrintButton,
  ReceiptButton,
} from 'components/checkout';
import { NumberPad } from 'components/number_pad';
import EftposModal from 'components/eftpos_modal';
import Loading from 'views/page/loading';
import LocalityController from 'views/checkout/locality_container';
import FolioSearch from 'features/payments/folio_search';
import PrinterSelect from 'features/payments/printer_select';
import PaymentTypeGrid from 'features/payments/payment_type_grid';
import { debounce, isEmpty } from 'lodash';
import { sendKey } from 'actions/redux/pc_eftpos_actions';
import { printReceipt } from 'actions/redux/thunks/print_thunks';

import { completeBooking } from 'actions/redux/thunks/checkout_thunks';
import { selectBookingFinalised } from 'selectors/booking_selectors';
import { selectSelectedTillOption } from 'selectors/till_selectors';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { selectOpenSection } from 'selectors/ui_selectors';
import {
  selectPaymentReference,
  selectIsReferenceRequired,
} from 'selectors/payment_selectors';

const referenceRequiredMessage =
  'Reference is required for on account payment type';

const paymentTypeRequiredMessage = 'Please select a payment type';

const PaymentScreen = ({ booking }) => {
  const section = useSelector(selectOpenSection);
  const isOpen = section === PaymentScreen.displayName;
  const reference = useSelector(selectPaymentReference);
  const isReferenceRequired = useSelector(selectIsReferenceRequired);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleOnChange = () => {
      setPayment(PaymentStore.currentPayment());
      setShowBookingCompleting(BookingStore.completing);
      setRequestQueueBusy(RequestQueueStore.busy);
      forceUpdate();
    };

    PaymentStore.addChangeListener(handleOnChange);
    RequestQueueStore.addChangeListener(handleOnChange);

    return () => {
      PaymentStore.removeChangeListener(handleOnChange);
      RequestQueueStore.removeChangeListener(handleOnChange);
    };
  }, []);

  const onPrintReceipt = useCallback(
    debounce(() => {
      dispatch(printReceipt());
    }, 300),
    []
  );

  const onCompleteBooking = useCallback(
    debounce(() => {
      if (BookingStore.completing || RequestQueueStore.busy) {
        return false;
      }

      if (!PaymentStore.paymentTypeMissing()) {
        showError(paymentTypeRequiredMessage, 'dismiss');
        return false;
      }

      if (isReferenceRequired && isEmpty(reference)) {
        showError(referenceRequiredMessage, 'dismiss');
        return false;
      }

      dispatch(completeBooking(BookingStore.currentBooking()));
    }, 300),
    [isReferenceRequired, reference]
  );

  const debouncedCompleteBooking = (e) => {
    e.preventDefault();
    onCompleteBooking();
  };

  const debouncedPrintReceipt = (e) => {
    e.preventDefault();
    onPrintReceipt();
  };

  const eftposVisible = useSelector((state) => state.eftpos.visible);

  const selectedTillId = useSelector(
    (state) => selectSelectedTillOption(state)?.value
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showError = (error, dismissAction) => {
    dispatch(NoticeActions.showError(error, dismissAction));
  };

  const handleEftposClick = (key, data) => {
    dispatch(
      sendKey({
        uid: PaymentStore.currentPayment().uid(),
        key: key,
        till_id: selectedTillId,
        data: data,
      })
    );
  };

  const [payment, setPayment] = useState(PaymentStore.currentPayment());
  const [bookingCompleting, setShowBookingCompleting] = useState(
    BookingStore.completing
  );
  const [requestQueueBusy, setRequestQueueBusy] = useState(
    RequestQueueStore.busy
  );

  const onNewBooking = (e) => {
    e.preventDefault();
    navigate('../bookings/new', { replace: true });
    dispatch(BookingActions.newBooking());
  };

  const checkoutActionName = () => {
    if (booking.payable()) {
      return 'Pay';
    } else if (booking.activatable()) {
      return 'Activate';
    } else if (booking.refundable()) {
      return 'Refund';
    }
  };

  const printActionName = () => {
    if (!booking.printable()) {
      return null;
    }
    if (booking.fullyIssued()) {
      return 'Re-print';
    } else {
      return 'Print';
    }
  };

  const checkoutButtonName = () => {
    const buttonActions = [];
    const firstActionName = checkoutActionName();
    if (firstActionName) {
      buttonActions.push(firstActionName);
    }

    const secondActionName = printActionName();
    if (secondActionName) {
      buttonActions.push(secondActionName);
    }
    return buttonActions.join(' & ');
  };

  if (booking == null) {
    return <Loading />;
  }

  const checkoutButtonsDisabled = bookingCompleting || requestQueueBusy;

  const className = classNames(
    { hide: !isOpen },
    'grid-12',
    'grid-medium-12',
    'row',
    'row-stretch',
    'booking-payment',
    'row-center'
  );

  const payableTotals = () => {
    const amountEntered = payment.amountEntered();
    const amountPaying = payment.amountPaying();
    const amount = amountEntered.isZero() ? amountPaying : amountEntered;
    const surcharge = booking.calculateSurcharge(amount);
    const total = amount.add(surcharge);
    const due =
      payment.change != null ? payment.change : payment.determineDue();

    return (
      <PayableTotal
        totalInCents={total.cents}
        amountInCents={amount.cents}
        surchargeInCents={surcharge.cents}
        dueInCents={due.cents}
      />
    );
  };

  return (
    <>
      {eftposVisible && <EftposModal handleEftposClick={handleEftposClick} />}
      <FolioSearch />
      <div className={className}>
        <div className="grid-6 col section">
          <TillSelect />
        </div>
        <div className="grid-6 col section">
          <PrinterSelect />
        </div>
        <div className="grid-12 section-bottom">
          <LocalityController />
        </div>
        <div className="grid-4 row row-column col-left">
          <PaymentTypeGrid />
          <PaymentReference payment={payment} />
        </div>
        <div className="grid-8 col">
          {payableTotals()}
          <NumberPad
            processKeyEvents={isOpen}
            onReset={() => {
              dispatch(PaymentActions.resetAmountEntered());
            }}
            onAppendDigit={(key) =>
              dispatch(PaymentActions.appendDigitToAmountEntered(key))
            }
            disabled={!booking.payable()}
          />
        </div>
        <div className="grid-12 row grid-bottom">
          {selectBookingFinalised(booking) && (
            <NewBookingButton
              disabled={checkoutButtonsDisabled}
              onClick={onNewBooking}
            />
          )}
          {booking.completed() && (
            <ReceiptButton
              disabled={checkoutButtonsDisabled}
              onClick={debouncedPrintReceipt}
            />
          )}
          {booking.canComplete() && (
            <PayPrintButton
              caption={checkoutButtonName()}
              variant={booking.alreadyCompleted() ? 'Redo' : 'Success'}
              disabled={checkoutButtonsDisabled}
              onClick={debouncedCompleteBooking}
            />
          )}
        </div>
      </div>
    </>
  );
};

PaymentScreen.propTypes = {
  booking: PropTypes.object,
};

PaymentScreen.displayName = 'PaymentScreen';

export default PaymentScreen;
