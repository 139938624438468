import Resource from 'components/ticket/resource';
import ReservationDetails from 'components/ticket/reservation_details';
import ClientDetails from 'components/ticket/client_details';
import ConsumerDetails from 'components/ticket/consumer_details';
import TodoItems from 'components/ticket/todo_items';
import PropTypes from 'prop-types';

const Ticket = ({ ticket, booking }) => {
  return (
    <div className="ticket">
      <Resource resource={ticket.resource} />
      <ReservationDetails ticket={ticket} booking={booking} />
      <ClientDetails booking={booking} />
      <ConsumerDetails ticket={ticket} />
      <TodoItems ticket={ticket} />
    </div>
  );
};

Ticket.propTypes = {
  ticket: PropTypes.object,
  booking: PropTypes.object,
};

export default Ticket;
