import { searchAndAssignClientByBarcode } from 'actions/redux/thunks/vehicle_thunks';
import { scanTicketBarcode } from 'actions/redux/thunks/ticket_thunks';

const ticketBarcodeLength = 8;

const processBarcode = (barcode) => {
  return (dispatch) => {
    // TODO: In future when there are more barcodes, use
    // some kind of switch / regex to split between barcodes
    if (barcode.length === ticketBarcodeLength) {
      dispatch(scanTicketBarcode(barcode));
    } else {
      dispatch(searchAndAssignClientByBarcode(barcode));
    }
  };
};

export { processBarcode };
