import Select from 'react-select';
import PropTypes from 'prop-types';

const PromoCodeInput = ({ promoCodes, selected, onChange }) => {
  // We want to hide the drop-down indicator and we want
  // to ensure the text can overflow to support the
  // huge promo-codes we see in the bruny tenant.
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none', // This line disable the blue border
    }),
    singleValue: (base) => ({
      ...base,
      ...{
        overflow: 'visible',
        whiteSpace: 'normal',
        marginRight: '0px',
      },
    }),
  };

  return (
    <Select
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={style}
      isClearable
      placeholder="Promo"
      className="side-select"
      options={promoCodes}
      value={selected}
      onChange={onChange}
    />
  );
};

PromoCodeInput.propTypes = {
  promoCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

PromoCodeInput.defaultProps = {
  onChange: () => {},
};

export default PromoCodeInput;
