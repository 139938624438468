import Backbone from 'backbone';
import Config from 'config/config';
import PaymentType from 'models/payment_type';

const PaymentTypes = Backbone.Collection.extend({
  model: PaymentType,
  url: Config.routes.paymentTypes,
  headers: { 'X-ORIGIN-KEY': Config.apiKey },
});

export default new PaymentTypes();
