import PropTypes from 'prop-types';

const Notice = ({
  hasMessages,
  noticeMessages = [],
  noticeType,
  dismissAction,
  reloadBooking = () => {},
  newBooking = () => {},
  dismiss = () => {},
  returnToPayment = () => {},
}) => {
  const noticeContent = () => {
    if (noticeType === 'loading') {
      return loadingNotice();
    } else if (noticeType === 'prompt') {
      return prompt();
    } else if (noticeType === 'flash') {
      return flashNotice();
    } else {
      return errorNotice();
    }
  };

  const renderNoticeMessages = () => {
    return noticeMessages.map((message, index) => (
      <span key={index} className="notice-message-line">
        {message}
      </span>
    ));
  };

  const loadingNotice = () => {
    return (
      <div className={`info text-center notice alert ${displayNotice()}`}>
        <div className="notice-message">{renderNoticeMessages()}</div>
      </div>
    );
  };

  const errorNotice = () => {
    return (
      <div className={`danger text-center notice alert ${displayNotice()}`}>
        <div className="notice-message">
          <i className={`icon-${noticeType}`} />
          {renderNoticeMessages()}
        </div>
        {renderDismissButton()}
      </div>
    );
  };

  const flashNotice = () => {
    return (
      <div className={`info text-center notice alert ${displayNotice()}`}>
        <div className="notice-message">{renderNoticeMessages()}</div>
        {renderDismissButton()}
      </div>
    );
  };

  const prompt = () => {
    return (
      <div className={`info text-center notice alert ${displayNotice()}`}>
        <div className="notice-message">
          <i className={`icon-${noticeType}`} />
          {renderNoticeMessages()}
        </div>
        {renderPromptButtons()}
      </div>
    );
  };

  const renderDismissButton = () => {
    switch (dismissAction) {
      case 'reload':
        return reloadButton();
      case 'dismiss':
        return dismissButton();
      case 'newBooking':
        return newBookingButton();
      default:
        return reloadButton();
    }
  };

  const renderPromptButtons = () => {
    return (
      <div className="text-center">
        <a className="button default" onClick={dismiss}>
          Yes
        </a>
        <div className="divider" />
        <a className="button default" onClick={returnToPayment}>
          No
        </a>
      </div>
    );
  };

  const reloadButton = () => {
    return (
      <a className="button default" onClick={reloadBooking}>
        Reload Booking
      </a>
    );
  };

  const newBookingButton = () => {
    return (
      <a className="button default" onClick={newBooking}>
        New Booking
      </a>
    );
  };

  const dismissButton = () => {
    return (
      <a className="button default" onClick={dismiss}>
        Dismiss
      </a>
    );
  };

  const displayNotice = () => {
    if (hasMessages) {
      return '';
    } else {
      return 'hide';
    }
  };

  const smokeScreen = () => {
    if (hasMessages) {
      return 'smoke-screen';
    } else {
      return '';
    }
  };

  return <div className={smokeScreen()}>{noticeContent()}</div>;
};

Notice.propTypes = {
  hasMessages: PropTypes.bool,
  noticeMessages: PropTypes.array,
  noticeType: PropTypes.string,
  dismissAction: PropTypes.string,
  reloadBooking: PropTypes.func,
  newBooking: PropTypes.func,
  dismiss: PropTypes.func,
  returnToPayment: PropTypes.func,
};

export default Notice;
