/* eslint-disable
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';
import Backbone from 'backbone';
import ProductType from 'models/product_type';
import ProductTypes from 'collections/product_types';
import Category from 'models/category';
import Categories from 'collections/categories';
import Resources from 'collections/resources';

import AppDispatcher from 'app_dispatcher';
import localStore from 'utils/local_store';

//# Private helper methods

// When backbone collection loads trigger a change

const loadProductTypes = () =>
  $.when(ProductTypes.fetch(), Categories.fetch(), Resources.fetch()).then(
    function () {
      setDefaultProductType(ProductTypes.models[0]);
      selectFirstCategory(defaultProductType());
      return ProductStore.trigger('change');
    }
  );
const unloadProductTypes = function () {
  ProductTypes.reset();
  Categories.reset();
  return Resources.reset();
};

var selectFirstCategory = function (productType) {
  const categories = productType.categories();
  if (_.isEmpty(categories)) {
    return;
  }
  return selectProductTypeCategory(categories[0]);
};

var canChangeDefaultType = function () {
  // If there is a previous selection in local storage make
  // sure that it also belongs to the current tenant, if not than
  // allow resetting the default tenant.
  const previouslySelected = localStore.get('defaultProductType');
  if (previouslySelected) {
    const match = ProductTypes.findWhere({ name: previouslySelected.name });
    return !match;
  }
  return true;
};

var setDefaultProductType = function (productType) {
  if (canChangeDefaultType()) {
    selectFirstCategory(productType);
    ProductStore.selectedProductType = productType;
    localStore.set('defaultProductType', productType);
  }
};

const selectProductType = function (productType) {
  ProductStore.selectedProductType = productType;
  selectFirstCategory(productType);
  return localStore.set('defaultProductType', productType);
};

var selectProductTypeCategory = function (productTypeCategory) {
  ProductStore.productFilterText = '';
  return (ProductStore.selectedProductTypeCategory = productTypeCategory);
};

var defaultProductType = () =>
  new ProductType(localStore.get('defaultProductType'));

const defaultProductTypeCategory = function () {
  if (localStore.get('defaultProductType') == null) {
    return Categories.null();
  }
  if (Categories.isEmpty()) {
    return Categories.null(defaultProductType().id);
  }
};

const isPassengerBasedProductType = function () {
  const productType = ProductStore.selectedProductType;
  return (
    productType.get('needs_passengers') || !productType.get('can_have_quantity')
  );
};

const searchProducts = (searchWord) =>
  (ProductStore.productFilterText = searchWord);

//# Public Exposed ProductStore object

// TODO: Make all view components use this store, and not the backbone model directly
// for two reasons: 1) one-way data flow, 2) separate actions from display

var ProductStore = _.extend({}, Backbone.Events, {
  // TODO: Need to store in localStorage the previous 'default' selected product type...
  // ProductTypes.findWhere( (p) -> p.id == settings.defaultTabSetting().get('value'))
  selectedProductType: defaultProductType(),
  selectedProductTypeCategory: defaultProductTypeCategory(),

  productTypes: ProductTypes,

  passengersRequired: isPassengerBasedProductType,

  productFilterText: '',

  products() {
    if (this.isFiltering()) {
      return this.filteredResources();
    } else {
      return this.selectedProductTypeCategory.resources();
    }
  },

  isFiltering() {
    if (this.productFilterText.length < 2) {
      return false;
    }
    return this.productFilterText !== '';
  },

  filteredResources() {
    return _.filter(Resources.models, (resource) => {
      const text = new RegExp(this.productFilterText, 'i');
      return (
        resource.get('name').replace(/\./g, '').search(text) !== -1 &&
        resource.get('active')
      );
    });
  },

  addChangeListener(listener) {
    return this.on('change', listener);
  },

  removeChangeListener(listener) {
    return this.off('change', listener);
  },

  getResource(id) {
    return Resources.get(id);
  },

  hasResources() {
    return !Resources.isEmpty();
  },

  hasProductTypes() {
    return !ProductTypes.isEmpty();
  },

  hasCategories() {
    return !Categories.isEmpty();
  },
});

//# Registered AppDispatcher events

AppDispatcher.on('product:selectProductType', function (productType) {
  selectProductType(productType);
  return ProductStore.trigger('change');
});

AppDispatcher.on(
  'product:selectProductTypeCategory',
  function (productTypeCategory) {
    selectProductTypeCategory(productTypeCategory);
    return ProductStore.trigger('change');
  }
);

AppDispatcher.on('product:load loggedIn', function () {
  loadProductTypes();
  return ProductStore.trigger('change');
});

AppDispatcher.on('product:unload', () => unloadProductTypes());

AppDispatcher.on('product:searchProducts', function (searchWord) {
  searchProducts(searchWord);
  return ProductStore.trigger('change');
});

export default ProductStore;
