import { isEmpty } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { NumberPlate } from 'components/vehicle/number_plate';

const NumberPlateSelector = ({
  numberPlate,
  invalidNumberPlate,
  vehicleTemplates,
}) => {
  const renderErrorMessage = () => {
    if (!invalidNumberPlate || isEmpty(numberPlate)) {
      return null;
    }

    return (
      <div className="error danger">
        <span>The scanned vehicle and ticket do not match</span>
      </div>
    );
  };

  if (isEmpty(vehicleTemplates)) {
    return null;
  }

  const message = isEmpty(numberPlate)
    ? 'Please Scan Vehicle Barcode'
    : 'Selected Vehicle';

  return (
    <div
      className={classNames('number-plate-selector', {
        redo: isEmpty(numberPlate),
      })}
    >
      <div className="prompt">
        <div>{message}</div>
        <NumberPlate numberPlate={numberPlate} />
      </div>
      {renderErrorMessage()}
    </div>
  );
};

NumberPlateSelector.propTypes = {
  numberPlate: PropTypes.string,
  invalidNumberPlate: PropTypes.bool,
  vehicleTemplates: PropTypes.array,
};

export default NumberPlateSelector;
