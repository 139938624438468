import * as NoticeActions from 'actions/redux/notice_actions';

const _dismissAction = function (response) {
  switch (response.status) {
    case 401:
      return 'dismiss';
    case 404:
      return 'newBooking';
    case 422:
      return 'reload';
    default:
      return 'reload';
  }
};

const show = function (dispatch, message, dismissAction) {
  if (dismissAction == null) {
    dismissAction = 'reload';
  }

  dispatch(NoticeActions.showError(message, dismissAction));
};

const ErrorHandler = {
  handleJsonResponse(dispatch, response, dismissAction = null) {
    const responseJson = response.responseJSON;
    const message =
      responseJson != null
        ? responseJson.error
        : 500 <= response.status && response.status <= 599
        ? `An error has occurred on the server. Status ${response.status}`
        : response.status !== 0
        ? `An error has occurred. Status ${response.status}`
        : response.readyState === 0
        ? 'Could not connect to QuickTravel'
        : 'An unknown error has occurred.';
    if (dismissAction == null) {
      dismissAction = _dismissAction(response);
    }
    return show(dispatch, message, dismissAction);
  },

  show,
};

export default ErrorHandler;
