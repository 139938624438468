import { createReducer } from '@reduxjs/toolkit';
import { addMessage, clearMessages } from 'actions/redux/notice_actions';

const INITIAL_STATE = {
  messages: [],
  type: 'error',
  dismissAction: 'reload',
};

const NoticeReducer = createReducer(INITIAL_STATE, {
  [addMessage]: (state, action) => {
    const payload = action.payload;

    state.messages.push(payload.message);
    state.type = payload.type;
    state.dismissAction = payload.dismissAction;
  },
  [clearMessages]: () => {
    return INITIAL_STATE;
  },
});

export default NoticeReducer;
