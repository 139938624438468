import { createAction } from '@reduxjs/toolkit';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { tillsIndex } from 'api/tills_api';
import { setTills, setSelectedTillOption } from 'actions/redux/till_actions';
import LocalStore from 'utils/local_store';

const fetchTillsStarted = createAction('tills/fetch/started');
const fetchTillsSucceeded = createAction('tills/fetch/succeeded');
const fetchTillsFailed = createAction('tills/fetch/failure');

const selectTill = (option) => (dispatch) => {
  if (option) {
    LocalStore.set('selectedTillId', option.value);
  } else {
    LocalStore.unset('selectedTillId');
  }
  dispatch(setSelectedTillOption(option));
  return Promise.resolve();
};

const fetchTills = () => (dispatch, getState) => {
  dispatch(fetchTillsStarted());

  const user = selectAuthenticatedUser(getState());
  return tillsIndex(user?.access_token)
    .then((result) => {
      dispatch(setTills(result));
      const selected = result.find(
        (tills) => tills.id === parseInt(LocalStore.get('selectedTillId'))
      );

      if (selected) {
        dispatch(
          setSelectedTillOption({ value: selected.id, label: selected.name })
        );
      } else {
        dispatch(setSelectedTillOption(null));
      }
      return dispatch(fetchTillsSucceeded(result));
    })
    .catch((error) => dispatch(fetchTillsFailed(error.message)));
};

export {
  fetchTills,
  fetchTillsStarted,
  fetchTillsSucceeded,
  fetchTillsFailed,
  selectTill,
};
