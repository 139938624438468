import PropTypes from 'prop-types';

const ClientHistory = ({ clients = [], onAssignClient = () => {} }) => {
  const handleAssignClient = (client, e) => {
    e.preventDefault();
    onAssignClient(client);
  };

  const history = () => {
    return clients.map((client) => {
      return (
        <tr
          key={`client-history-${client.id}`}
          data-id={client.id}
          onClick={(e) => handleAssignClient(client, e)}
        >
          <td>{client.name}</td>
          <td>{client.type}</td>
          <td>{client.paymentMethod}</td>
        </tr>
      );
    });
  };

  const noHistory = () => {
    return (
      <tr>
        <td colSpan="3">No previous clients to display</td>
      </tr>
    );
  };

  return (
    <div className="grid-12 col section">
      <em>Previously Assigned Clients</em>
      <table
        className="table client-results table-horizontal table-striped"
        width="100%"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Payment Method</th>
          </tr>
        </thead>
        <tbody>{clients.length === 0 ? noHistory() : history()}</tbody>
      </table>
    </div>
  );
};

ClientHistory.propTypes = {
  onAssignClient: PropTypes.func,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      paymentMethod: PropTypes.string,
    })
  ),
};

export default ClientHistory;
