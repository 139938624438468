import Backbone from 'backbone';
import _ from 'underscore';
import PaymentTypes from 'collections/payment_types';
import AppDispatcher from 'app_dispatcher';
import { find } from 'lodash';

const loadPaymentTypes = () =>
  PaymentTypes.fetch({
    success() {
      return PaymentTypeStore.trigger('change');
    },
  });
const unloadPaymentTypes = () => PaymentTypes.reset();

var PaymentTypeStore = _.extend({}, Backbone.Events, {
  paymentTypes: PaymentTypes,

  addListener(event, listener) {
    return this.on(event, listener);
  },

  removeListener(event, listener) {
    return this.off(event, listener);
  },

  roomChargePaymentType() {
    return find(PaymentTypes.models, (paymentType) => {
      return paymentType.attributes.payment_method == 'room_charge';
    });
  },
});

AppDispatcher.on('paymentType:load loggedIn', () => loadPaymentTypes()).on(
  'paymentType:unload',
  () => unloadPaymentTypes()
);

export default PaymentTypeStore;
