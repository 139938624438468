import { createReducer } from '@reduxjs/toolkit';
import { setTills, setSelectedTillOption } from 'actions/redux/till_actions';

const INITIAL_STATE = {
  list: [],
  selected: null,
};

const TillReducer = createReducer(INITIAL_STATE, {
  [setTills]: (state, action) => {
    state.list = action.payload;
  },
  [setSelectedTillOption]: (state, action) => {
    state.selected = action.payload;
  },
});

export default TillReducer;
