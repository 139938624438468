import { configureStore } from '@reduxjs/toolkit';
import VehicleReducer from 'reducers/vehicle_reducer';
import PcEftposNotificationReducer from 'reducers/pc_eftpos_reducer';
import TicketReducer from 'reducers/ticket_reducer';
import BarcodeScannerReducer from 'reducers/barcode_scanner_reducer';
import uiReducer from 'reducers/ui_reducer';
import TenantReducer from 'reducers/tenant_reducer';
import EntityReducer from 'reducers/entities_reducer';
import PrinterReducer from 'reducers/printer_reducer';
import LoginReducer from 'reducers/login_reducer';
import ConsumerTypeReducer from 'reducers/consumer_type_reducer';
import FolioSearchReducer from 'reducers/folio_search_reducer';
import PromoCodeReducer from 'reducers/promo_code_reducer';
import CountryReducer from 'reducers/country_reducer';
import TillReducer from 'reducers/till_reducer';
import ReservationToAddReducer from 'reducers/reservation_to_add_reducer';
import NoticeReducer from 'reducers/notice_reducer';
import ClientReducer from 'reducers/clients_reducer';
import PaymentReducer from 'reducers/payment_reducer';
import { apiSlice } from 'features/api/api_slice';

export const setupStore = () => {
  return {
    reducer: {
      clients: ClientReducer,
      vehicle: VehicleReducer,
      eftpos: PcEftposNotificationReducer,
      ticket: TicketReducer,
      barcodeScanner: BarcodeScannerReducer,
      ui: uiReducer,
      tenant: TenantReducer,
      entities: EntityReducer,
      consumerTypes: ConsumerTypeReducer,
      printers: PrinterReducer,
      login: LoginReducer,
      folioSearch: FolioSearchReducer,
      promoCodes: PromoCodeReducer,
      countries: CountryReducer,
      tills: TillReducer,
      reservationToAdd: ReservationToAddReducer,
      notices: NoticeReducer,
      payment: PaymentReducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  };
};

const store = configureStore(setupStore());

export default store;
