import AppDispatcher from 'app_dispatcher';

const selectProductType = (productType) => () => {
  AppDispatcher.trigger('product:selectProductType', productType);
  return Promise.resolve();
};

const selectProductTypeCategory = (productTypeCategory) => () => {
  AppDispatcher.trigger(
    'product:selectProductTypeCategory',
    productTypeCategory
  );
  return Promise.resolve();
};

const load = () => () => {
  AppDispatcher.trigger('product:load');
  return Promise.resolve();
};

const unload = () => () => {
  AppDispatcher.trigger('product:unload');
  return Promise.resolve();
};

const searchProducts = (searchWord) => () => {
  AppDispatcher.trigger('product:searchProducts', searchWord);
  return Promise.resolve();
};

export {
  selectProductType,
  selectProductTypeCategory,
  load,
  unload,
  searchProducts,
};
