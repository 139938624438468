import { useSelector, useDispatch } from 'react-redux';
import { selectPassengerQuantities } from 'selectors/reservation_to_add_selectors';
import { changePassengerTypeNumbers } from 'actions/redux/reservation_to_add_actions';
import PassengerSelection from 'components/product/passenger_selection';

const ConnectedPassengerSelection = () => {
  const dispatch = useDispatch();
  const passengerTypeNumbers = useSelector((state) =>
    selectPassengerQuantities(state)
  );

  return (
    <PassengerSelection
      passengerTypeNumbers={passengerTypeNumbers}
      onSelectPassengerType={(passengerTypeId) => {
        dispatch(changePassengerTypeNumbers(passengerTypeId));
      }}
    />
  );
};

export default ConnectedPassengerSelection;
