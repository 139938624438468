/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';
import Money from '@sealink/money_beans';

class MoneyString {
  constructor(value) {
    if (value == null) {
      value = '';
    }
    this.value = value;
  }

  money() {
    if (_.isEmpty(this.value)) {
      return new Money(0);
    }
    return new Money(parseFloat(this.value) * 100);
  }

  appendDigit(digit) {
    const digitValue = digit.match(/\d|\./);
    if (!digitValue) {
      return;
    }
    if (this.hasDecimalPoint() && (digit === '.' || this.hasAllCents())) {
      return;
    }
    return (this.value += digitValue);
  }

  reset() {
    return (this.value = '');
  }

  hasDecimalPoint() {
    return _.contains(this.value, '.');
  }

  hasAllCents() {
    if (!this.hasDecimalPoint()) {
      return false;
    }
    return this.value.match(/\.(\d*)/)[1].length >= 2;
  }
}

export default MoneyString;
