import RequestQueue from 'libs/request_queue';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';
import * as ServerResponses from 'actions/redux/server_responses';
import Config from 'config/config';
import { normalizeBookingAttributes } from 'actions/redux/normalizer_actions';

export const fetchCurrentBooking = () => (dispatch) => {
  return RequestQueue.get(Config.routes.bookings() + '/current_booking.json')
    .done((response) =>
      dispatch(ServerResponses.currentBookingFetched(response))
    )
    .fail((response) =>
      dispatch(ErrorHandlerActions.handleJsonResponse(response))
    );
};

export const update = (booking, attrs) => (dispatch) => {
  const booking_url = Config.routes.bookings() + `/${booking.id}.json`;
  return RequestQueue.put(booking_url, {
    booking: attrs,
    full_response: true,
  })
    .done((response) => {
      dispatch(normalizeBookingAttributes({ id: booking.id, ...response }));
      dispatch(ServerResponses.bookingUpdate(response));
    })
    .fail((response) =>
      dispatch(ErrorHandlerActions.handleJsonResponse(response))
    );
};

export const activate = (booking) => (dispatch) => {
  return RequestQueue.put(
    Config.routes.bookings() + '/' + booking.id + '/activate'
  )
    .done((response) => dispatch(ServerResponses.bookingActivated(response)))
    .fail((response) =>
      dispatch(ErrorHandlerActions.handleJsonResponse(response))
    );
};

export const reload = (booking) => (dispatch) => {
  return booking
    .fetch()
    .then((response) => dispatch(ServerResponses.bookingReloaded(response)))
    .fail((response) =>
      dispatch(ServerResponses.bookingReloadFailed(response))
    );
};
