import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';

import Config from 'config/config';

import Bookings from 'collections/bookings';
import Booking from 'models/booking';
import AppDispatcher from 'app_dispatcher';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';
import * as BookingServerActions from 'actions/redux//booking_server_actions';
import * as NoticeActions from 'actions/redux/notice_actions';
import { AUTO_CODE_OFF } from 'models/promo_codes';

import { setOpenSection } from 'actions/redux/ui_actions';
import { fetchAutoSeasonalityCode } from 'actions/redux/thunks/promo_code_thunks';
import { updatePromoCode } from 'actions/redux/thunks/booking_thunks';
import { resetAll } from 'actions/redux/reservation_to_add_actions';

import store from 'store';
import { selectAutoPromoCode } from 'selectors/promo_code_selectors';
import { selectReservationToAdd } from 'selectors/reservation_to_add_selectors';
import { setPreviousBookingId } from '../actions/redux/ticket_actions';

//# Private helper methods and state

let _currentBooking = null;

const loadBooking = function (id) {
  // placeholder booking object - has id to allow fetch()
  // silence is so no events will fire
  const bookingToLoad = new Booking({ id }, { silent: true });
  return bookingToLoad
    .fetch()
    .then(() => setCurrentBooking(bookingToLoad))
    .then(function () {
      const sectionToOpen =
        _currentBooking.get('state') === 'active'
          ? 'PaymentScreen'
          : 'ProductListScreen';
      return updateSectionOpen(sectionToOpen);
    })
    .fail(() => (_currentBooking = null))
    .always(() => BookingStore.trigger('change'));
};

const unloadBooking = () => (_currentBooking = null);

const promoCodeRefresher = () => {
  if (Config.autoPromoCode.mode === 'seasonality') {
    return store.dispatch(
      fetchAutoSeasonalityCode(Config.autoPromoCode.seasonalities)
    );
  }
  return Promise.resolve(); //Manual chosen
};

const processAutoPromoCode = (booking) => {
  return promoCodeRefresher().then(() => {
    // If we have an auto promo code now is the time to apply it.
    const autoPromoCode = selectAutoPromoCode(store.getState());
    if (autoPromoCode.value !== AUTO_CODE_OFF.value) {
      booking.set({
        promo_code_id: autoPromoCode.value,
      });

      // If we are coming in from new booking we don't won't to double save
      if (booking.id > 0) {
        store.dispatch(
          updatePromoCode(booking.id, {
            promo_code_id: autoPromoCode.value,
          })
        );
      }
    }
    return Promise.resolve();
  });
};

// Fetches current new booking from QT, or creates one if not present
// If forceCreate option passed, create new booking regardless
const newBooking = function (forceCreate) {
  if (forceCreate == null) {
    forceCreate = false;
  }
  const bookingReady = $.Deferred();
  fetchCurrentBooking().then(function () {
    if (_currentBooking.id != null && !forceCreate) {
      if (_currentBooking.reservations().length == 0) {
        return processAutoPromoCode(_currentBooking).then(() =>
          bookingReady.resolve()
        );
      } else {
        return bookingReady.resolve();
      }
    }
    return $.when(destroyCurrentBooking())
      .then(createNewBooking)
      .then(() => bookingReady.resolve());
  });

  return bookingReady;
};

var createNewBooking = () => {
  const bookingCreated = $.Deferred();
  const _newBooking = new Booking();

  return processAutoPromoCode(_newBooking).then(() => {
    _newBooking.save(
      {},
      {
        url: _newBooking.urlRoot() + '.json',
        headers: { 'X-ORIGIN-KEY': Config.apiKey },
        success() {
          return bookingCreated.resolve();
        },
        error(model, response) {
          store.dispatch(ErrorHandlerActions.handleJsonResponse(response));
        },
      }
    );
    setCurrentBooking(_newBooking);
    return bookingCreated;
  });
};

const fetchCurrentBooking = () => {
  return store.dispatch(BookingServerActions.fetchCurrentBooking());
};

const destroyCurrentBooking = () => {
  return _currentBooking.destroy({
    error(model, response) {
      return store.dispatch(ErrorHandlerActions.handleJsonResponse(response));
    },
  });
};

const displayBooking = (shouldDisplay) =>
  (BookingStore.isBookingDisplayed = shouldDisplay);

var updateSectionOpen = function (newSection) {
  store.dispatch(setOpenSection(newSection));
  BookingStore.previousSectionOpen = BookingStore.sectionOpen;
  BookingStore.sectionOpen = newSection;
};

var setCurrentBooking = function (booking) {
  if (booking?.id !== _currentBooking?.id) {
    store.dispatch(setPreviousBookingId(_currentBooking?.id));
  }
  _currentBooking = booking;
  AppDispatcher.trigger('booking:loaded', booking);
};

const bookingCompleted = function () {
  BookingStore.completing = false;
  _currentBooking.set('state', 'active');
  return BookingStore.trigger('change');
};

const update = function (attrs) {
  _currentBooking.set(attrs);
  BookingStore.trigger('change');
  return _currentBooking.update(attrs);
};

const updateClient = (attrs) =>
  update(attrs).then(
    updateSectionOpen(BookingStore.previousSectionOpen || 'ProductListScreen')
  );
const localUpdate = function (attrs) {
  _currentBooking.set(attrs);
  return BookingStore.trigger('change');
};

const isPostCodeComplete = (postCode) =>
  postCode.length > 3 || postCode.length === 0;

//# Public Exposed BookingStore object

const BookingStore = _.extend({}, Backbone.Events, {
  isBookingDisplayed: true,
  sectionOpen: 'ProductListScreen',
  previousSectionOpen: null,
  completing: false,

  currentBooking() {
    return _currentBooking;
  },

  bookingList() {
    return Bookings.models;
  },

  addChangeListener(listener) {
    return this.on('change', listener);
  },

  removeChangeListener(listener) {
    return this.off('change', listener);
  },
});

//# Registered AppDispatcher events

AppDispatcher.on('booking:loadRecentBookings', function () {
  displayBooking(false);
  BookingStore.trigger('change');
})
  .on('booking:load', function (id) {
    displayBooking(true);
    return loadBooking(id);
  })
  .on('booking:unload', unloadBooking)

  .on('booking:new', (forceCreate) =>
    newBooking(forceCreate).then(function () {
      displayBooking(true);
      BookingStore.trigger('change');
      AppDispatcher.trigger('booking:sectionOpen', 'ProductListScreen');
      store.dispatch(resetAll());
    })
  )
  .on('booking:change', () => BookingStore.trigger('change'))
  .on('booking:sectionOpen', function (sectionOpen) {
    updateSectionOpen(sectionOpen);
    // The order of these events is important in order for the ClientScreen to work
    // TODO Find a solution that doesn't constrict behaviour that does not belong to ClientScreen
    BookingStore.trigger('change');
    AppDispatcher.trigger('updatedSectionOpen');
  })
  .on('booking:display', function (shouldDisplay) {
    displayBooking(shouldDisplay);
    BookingStore.trigger('change');
  })
  .on('booking:updateNewReservationDate', function (dateValue) {
    _currentBooking.updateNewReservationDate(dateValue);
    BookingStore.trigger('change');
  })
  .on('checkout:failure', function () {
    BookingStore.completing = false;
    _currentBooking.completing = false;
    BookingStore.trigger('change');
  })
  .on('reservation:addSelected', function () {
    _currentBooking.addReservation(selectReservationToAdd(store.getState()));
    BookingStore.trigger('change');
  })
  .on('reservation:destroy', function (reservation) {
    _currentBooking.destroyReservation(reservation);
    BookingStore.trigger('change');
  })
  .on('reservation:toggle', function (reservation) {
    _currentBooking.toggleReservation(reservation);
    return BookingStore.trigger('change');
  })
  .on('reservation:createdOnServer', function (reservation, response) {
    _currentBooking.updateReservationFromResponse(reservation, response);
    return BookingStore.trigger('change');
  })
  .on('reservation:update', (reservation, attrs) =>
    _currentBooking.updateReservation(reservation, attrs)
  )
  .on('price:update', function (booking_price_breakdown) {
    _currentBooking.updatePrices(booking_price_breakdown);
    return BookingStore.trigger('change');
  })
  .on('booking:completed', bookingCompleted)

  .on('checkout:success', function (_response, requestData) {
    _currentBooking.updateFromSuccessfulCheckout(requestData);
    return BookingStore.trigger('change');
  })
  .on('adjustment:selectAdjustmentDefinition', (adjustmentDefinition) =>
    _currentBooking.addAdjustment(adjustmentDefinition)
  )
  .on('booking:localUpdate', (attrs) => localUpdate(attrs))
  .on('booking:update', function (attrs) {
    if (attrs.promo_code_id) {
      store.dispatch(
        NoticeActions.loadingNotice('Promo code is being set, please wait')
      );
    }
    return update(attrs);
  })
  .on('booking:updatePostcode', function (postCode) {
    const attrs = { post_code: postCode };
    if (isPostCodeComplete(postCode)) {
      return update(attrs);
    }
    return localUpdate(attrs);
  })
  .on('client:assigned', (client) => updateClient({ client_id: client.id }))
  .on('booking:unassignClient', () =>
    updateClient({
      client_id: null,
      customer_contact_name: null,
      customer_contact_phone: null,
      customer_contact_mobile: null,
      customer_contact_email: null,
    })
  )
  .on('currentBooking:fetched', function (response) {
    setCurrentBooking(new Booking(response, { silent: true, parse: true }));
    BookingStore.trigger('change');
  })
  .on('booking:activated', function () {
    _currentBooking.set('state', 'active');
    BookingStore.trigger('change');
  })
  .on('booking:reload', () =>
    store.dispatch(BookingServerActions.reload(_currentBooking))
  )
  .on('booking:reloaded', () => {
    BookingStore.trigger('change');
  })
  .on('booking:updated', function (attrs) {
    setCurrentBooking(new Booking(attrs, { parse: true }));
    BookingStore.trigger('change');
  })
  .on('print:success', () =>
    _currentBooking.fetch().then(() => bookingCompleted())
  );

export default BookingStore;
