import _ from 'underscore';
import { useSelector } from 'react-redux';
import { selectPassengerTypes } from 'selectors/consumer_type_selectors';

const ReservationPassengerTypes = ({ booking, reservation }) => {
  const passengerTypes = useSelector((state) => selectPassengerTypes(state));

  const passengerTypeNumbers = () => {
    return reservation.get('passenger_type_numbers');
  };

  const reservationPassengers = () => {
    if (passengerTypeNumbers() != null) {
      return reservation.activePassengerTypes();
    }
    const passenger_ids = reservation.get('passenger_ids');
    return booking.getReservationPassengerTypeCounts(passenger_ids);
  };

  const getCode = (passenger_type_id) => {
    return passengerTypes[passenger_type_id].code;
  };

  const renderPassengers = () => {
    return _.map(reservationPassengers(), (count, passenger_type_id) => {
      return (
        <div
          className="grid-base item-pax-count"
          key={`pas-${passenger_type_id}`}
        >
          {`${count} `}
          {getCode(passenger_type_id)}
        </div>
      );
    });
  };

  return <div className="item-pax-counts right">{renderPassengers()}</div>;
};

ReservationPassengerTypes.displayName = 'ReservationPassengerTypes';

export default ReservationPassengerTypes;
