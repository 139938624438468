import ProductStore from 'stores/product_store';
import ProductButton from 'views/products/product_button';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const ProductList = ({ disabled = false }) => {
  const noResults = () => {
    return (
      <li className="product-li grid-12">
        <div className="text-mute text-center">No results.</div>
      </li>
    );
  };

  const productButtons = () => {
    const products = ProductStore.products();
    if (isEmpty(products)) {
      return noResults();
    }
    return products.map((resource, i) => {
      return (
        <ProductButton
          resource={resource}
          key={`resource${i}`}
          disabled={disabled}
        />
      );
    });
  };

  return <ul className="product-list row list-unstyled">{productButtons()}</ul>;
};

ProductList.propTypes = {
  disabled: PropTypes.bool,
};

export default ProductList;
