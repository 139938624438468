import classNames from 'classnames';
import PropTypes from 'prop-types';

const ReceiptButton = ({ disabled, onClick }) => {
  const buttonClass = classNames({
    button: 'button',
    info: 'info',
    large: 'large',
    'grid-grow': 'grid-grow',
    disabled: disabled,
  });

  return (
    <button disabled={disabled} onClick={onClick} className={buttonClass}>
      Print Receipt
    </button>
  );
};

ReceiptButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ReceiptButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default ReceiptButton;
