import { createAction } from '@reduxjs/toolkit';
import AppDispatcher from 'app_dispatcher';
import * as NoticeActions from 'actions/redux/notice_actions';
import * as UIActions from 'actions/redux/ui_actions';
import { updateBooking } from 'api/booking_api';
const bookingUpdateStart = createAction('booking/update/started');
const bookingUpdateSuccess = createAction('booking/update/success');
const bookingUpdateFailure = createAction('booking/update/failure');
const optimisticallyUpdateBooking = createAction('booking/attributes/set');
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { resetEntities } from 'actions/redux/entity_actions';

export const loadBooking = (id) => () => {
  AppDispatcher.trigger('booking:load', id);
  return Promise.resolve();
};

export const bookingUpdated = (response) => () => {
  AppDispatcher.trigger('booking:updated', response);
  return Promise.resolve();
};

export const unloadBooking = () => () => {
  AppDispatcher.trigger('booking:unload');
  return Promise.resolve();
};

export const reloadBooking = () => () => {
  AppDispatcher.trigger('booking:reload');
  return Promise.resolve();
};

export const listBookings = () => () => {
  AppDispatcher.trigger('booking:loadRecentBookings');
  return Promise.resolve();
};

export const addClientToBooking = () => () => {
  AppDispatcher.trigger('booking:sectionOpen', 'ClientScreen');
  return Promise.resolve();
};

export const completeBooking = () => () => {
  AppDispatcher.trigger('booking:complete');
  return Promise.resolve();
};

export const bookingCompleted = () => () => {
  AppDispatcher.trigger('booking:completed');
  return Promise.resolve();
};

export const displayProductListScreen = () => () => {
  AppDispatcher.trigger('booking:sectionOpen', 'ProductListScreen');
  return Promise.resolve();
};

export const payCurrentBooking = () => () => {
  AppDispatcher.trigger('booking:sectionOpen', 'PaymentScreen');
  return Promise.resolve();
};

export const setBookingDate = (date) => () => {
  AppDispatcher.trigger('booking:updateNewReservationDate', date);
  return Promise.resolve();
};

export const destroyReservation = (reservation) => (dispatch) => {
  AppDispatcher.trigger('reservation:destroy', reservation);
  dispatch(UIActions.unselectReservation(reservation.id));
  return Promise.resolve();
};

export const toggleReservation = (reservation) => (dispatch) => {
  AppDispatcher.trigger('reservation:toggle', reservation);
  dispatch(UIActions.toggleReservation(reservation.id));
  return Promise.resolve();
};

export const updateReservation = (reservationId, attributes) => () => {
  AppDispatcher.trigger('reservation:update', reservationId, attributes);
  return Promise.resolve();
};

export const setPromoCode = (promoCode) => () => {
  AppDispatcher.trigger('booking:update', { promo_code_id: promoCode });
  return Promise.resolve();
};

export const setCustomerDetails = (customer_detail_object) => (dispatch) => {
  dispatch(optimisticallyUpdateBooking(customer_detail_object));
  AppDispatcher.trigger('booking:localUpdate', customer_detail_object);
  return Promise.resolve();
};

export const sendCustomerDetails = (customerDetailObject) => () => {
  AppDispatcher.trigger('booking:update', customerDetailObject);
  return Promise.resolve();
};

export const assignCountry = (country_id) => () => {
  AppDispatcher.trigger('booking:update', { country_id });
  return Promise.resolve();
};

export const assignPostCode = (postCode) => () => {
  AppDispatcher.trigger('booking:updatePostcode', postCode);
  return Promise.resolve();
};

const newBooking =
  (opts = {}) =>
  (dispatch) => {
    dispatch(resetEntities());
    AppDispatcher.trigger('booking:new', opts.forceCreate);
    return Promise.resolve();
  };

const doUpdateBookingAttributes = (id, attributes, handleException = false) => {
  return (dispatch, getState) => {
    dispatch(bookingUpdateStart());

    const user = selectAuthenticatedUser(getState());
    dispatch(optimisticallyUpdateBooking({ ...attributes, id }));
    return updateBooking(id, attributes, user?.access_token)
      .then((json) => {
        dispatch(NoticeActions.clearNotice());

        dispatch(bookingUpdateSuccess());
        return Promise.resolve(json);
      })
      .catch((error) => {
        dispatch(NoticeActions.showError(error.message));
        let promise = dispatch(bookingUpdateFailure(error.message));
        if (!handleException) {
          promise = Promise.reject(error);
        }
        return promise;
      });
  };
};

const updateCustomerDetails = (id, attributes) => (dispatch) => {
  dispatch(optimisticallyUpdateBooking({ ...attributes, id }));
  AppDispatcher.trigger('booking:localUpdate', attributes);
  return Promise.resolve();
};

const updatePromoCode = (id, attributes) => {
  return (dispatch) => {
    dispatch(
      NoticeActions.loadingNotice('Promo code is being set, please wait')
    );
    dispatch(doUpdateBookingAttributes(id, attributes)).then((response) => {
      dispatch(bookingUpdated(response));
    });
  };
};

const updateBookingAttributes = (id, attributes) => {
  return (dispatch) => {
    dispatch(doUpdateBookingAttributes(id, attributes)).then((response) => {
      dispatch(bookingUpdated(response));
    });
  };
};

export {
  newBooking,
  updatePromoCode,
  updateBookingAttributes,
  updateCustomerDetails,
  optimisticallyUpdateBooking,
};
