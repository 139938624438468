import axios from 'axios';

import Config from 'config/config';

import { assignClient } from 'actions/redux/thunks/client_thunks';
import { handleJsonResponse as handleJsonErrorResponse } from 'actions/redux/error_handler_actions';

import {
  clearRegistration,
  setRegistration,
} from 'actions/redux/vehicle_actions';

const searchAndAssignClientByBarcode = (barcode) => {
  return (dispatch) => {
    dispatch(clearRegistration());

    return axios
      .get(Config.routes.clientBarcodeSearch() + '?query=' + barcode)
      .then((response) =>
        searchClientRegoByBarcode(dispatch, response.data, barcode)
      )
      .then(function (response) {
        const combinedResponse = response.clientResponse;
        combinedResponse.rego = response.rego;

        dispatch(setRegistration({ numberPlate: response.rego }));
        dispatch(assignClient(combinedResponse));
      })
      .catch(function (error) {
        console.log('ERROR IS', error);
        if (error.response.status === 404) {
          error.response.data.error = `Could not find Client with barcode: ${barcode}`;
        }
        dispatch(handleJsonErrorResponse(error.response, 'dismiss'));
      });
  };
};

const searchClientRegoByBarcode = (dispatch, clientResponse, barcodeText) => {
  return axios
    .get(Config.routes.clientTemplatesSearch(clientResponse.id))
    .then(function (response) {
      const matches = response.data.vehicle_templates.filter(
        (item) =>
          item.external_id.toUpperCase() === barcodeText.trim().toUpperCase()
      );

      return Promise.resolve({ clientResponse, rego: matches[0].registration });
    })
    .catch((error) =>
      dispatch(handleJsonErrorResponse(error.response, 'dismiss'))
    );
};

export { searchAndAssignClientByBarcode };
