import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.overlayRef = createRef();
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCloseBtnClick = this.handleCloseBtnClick.bind(this);
    this.state = { visible: false };
  }

  componentWillUnmount() {
    this.__setBodyOverflowVisible(true);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.handleBeforeComponentUpdate(props);
  }

  componentDidMount() {
    if (this.props.visible) {
      this.setState({ visible: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleComponentUpdate(prevProps, prevState);
  }

  handleBeforeComponentUpdate(props) {
    if (
      props.hasOwnProperty('visible') &&
      props.visible !== this.state.visible
    ) {
      this.setState({ visible: props.visible });
    }
  }

  handleComponentUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
      if (this.state.visible) {
        this.props.onShow();
      } else {
        this.props.onHide();
      }
      this.__setBodyOverflowVisible(!this.state.visible);
    }
  }

  __setBodyOverflowVisible(visible) {
    const visibility = !visible ? 'hidden' : null;
    document.body.style.overflow = visibility;
  }

  handleCloseBtnClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onCancelBtn();
    this.toggleVisibility();
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayRef.current && this.props.closable) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onCancelBtn();
      this.toggleVisibility();
    }
  }

  toggleVisibility() {
    const visible = !this.state.visible;
    this.setState({ visible });
  }

  render() {
    const closeBtn = this.props.closable ? (
      <div className="modal-top grid-base">
        {this.modalTitle()}
        <a
          className="modal-close"
          href="#"
          title="Close"
          onClick={this.handleCloseBtnClick}
        >
          <i className="icon-cancel" />
        </a>
      </div>
    ) : (
      <div className="modal-top grid-base">{this.modalTitle()}</div>
    );
    let overlayClass = 'modal-overlay animated grid-base';
    overlayClass += this.state.visible ? ' shown' : ' hidden';
    overlayClass += this.props.className ? ` ${this.props.className}` : '';

    return (
      <div
        className={overlayClass}
        ref={this.overlayRef}
        onClick={this.handleOverlayClick}
      >
        <div className="modal-main row row-column animated">
          {closeBtn}
          <div className="modal-content grid-base row row-column">
            {this.props.children}
          </div>
          {this.modalButtons()}
        </div>
      </div>
    );
  }

  modalTitle() {
    if (this.props.modalTitle === '') {
      return '';
    }
    return <h2 className="modal-title">{this.props.modalTitle}</h2>;
  }

  modalButtons() {
    const cancelBtn = this.props.cancelBtn ? (
      <Button
        className="left"
        onClick={this.props.onCancelBtn}
        disabled={!this.props.cancelBtnEnabled}
      >
        {this.props.cancelText}
      </Button>
    ) : (
      ''
    );

    const confirmBtn = this.props.confirmBtn ? (
      <Button
        className="right"
        variant="success"
        onClick={this.props.onSaveBtn}
        disabled={!this.props.confirmBtnEnabled}
      >
        {this.props.confirmText}
      </Button>
    ) : (
      ''
    );

    return (
      <div className="modal-footer grid-base clearfix">
        {cancelBtn}
        {confirmBtn}
      </div>
    );
  }
}

Modal.propTypes = {
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  closable: PropTypes.bool,
  onSaveBtn: PropTypes.func,
  onCancelBtn: PropTypes.func,
  cancelBtn: PropTypes.bool,
  modalTitle: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelBtnEnabled: PropTypes.bool,
  confirmBtnEnabled: PropTypes.bool,
  okBtnEnabled: PropTypes.bool,
};

Modal.defaultProps = {
  modalTitle: '',
  onShow: () => {},
  onHide: () => {},
  onSaveBtn: () => {},
  onCancelBtn: () => {},
  closable: true,
  confirmText: 'Save',
  cancelText: 'Cancel',
  cancelBtnEnabled: true,
  confirmBtnEnabled: true,
};

export default Modal;
