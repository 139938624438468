/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import store from 'store';
import { selectAccessToken } from 'selectors/login_selectors';

import Axios from 'axios';

const EmbedAuthentication = {
  call(originalOptions) {
    if (originalOptions == null) {
      originalOptions = {};
    }
    const options = { xhrFields: this.xhrFields() };

    $.extend(options, originalOptions);

    const access_token = selectAccessToken(store.getState());
    const authorizationContent = `Bearer ${access_token}`;

    Axios.defaults.headers.common['Authorization'] = authorizationContent;

    if (!originalOptions.beforeSend) {
      originalOptions.beforeSend = function (xhr) {
        xhr.setRequestHeader('Authorization', authorizationContent);
      };
    }

    //TODO We should not have to change ajax options globally and then have to handle special cases.
    // We could wrap only requests to QT, but that involves overriding backbone requests.
    if (options.type === 'GET' || options.embedCsrfToken === false) {
      return options;
    }

    return options;
  },

  xhrFields() {
    // Disable sending of cookies
    return { withCredentials: false };
  },
};

export default EmbedAuthentication;
