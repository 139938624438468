import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import * as BookingServerActions from 'actions/redux//booking_server_actions';
import CheckoutHelper from './checkout_helper';
import PaymentStore from 'stores/payment_store';
import PaymentTypeStore from 'stores/payment_type_store';
import Money from '@sealink/money_beans';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';
import * as ServerResponses from 'actions/redux/server_responses';

import store from 'store';
import { selectSelectedPaymentType } from 'selectors/payment_type_selectors';
import { printTickets } from 'actions/redux/thunks/print_thunks';
import { setModalActive } from 'actions/redux/folio_search_actions';
import { selectFolioSearchModalActive } from 'selectors/folio_search_selectors';
import {
  selectPaymentReference,
  selectIsReferenceRequired,
} from 'selectors/payment_selectors';

class CheckoutService {
  constructor(booking) {
    this.booking = booking;
  }

  checkout() {
    return this._process(this.payOrActivate());
  }

  _process(promise) {
    if (this.booking.printable()) {
      // If printable when making the promise, not when it's resolved!
      return Promise.resolve(promise)
        .then(() => this.print())
        .then(() => store.dispatch(ServerResponses.printSuccess()))
        .catch((response) => {
          store.dispatch(ErrorHandlerActions.handleJsonResponse(response));
        });
    } else {
      return Promise.resolve(promise).then(() => {
        return store.dispatch(BookingActions.bookingCompleted());
      });
    }
  }

  payOrActivate() {
    if (this.canSubmitPayment()) {
      return this.submitPayment();
    } else {
      return this.activate();
    }
  }

  submitPayment() {
    let paymentOptions = { comment: this.referenceComment() };
    let externalOptions = {};
    if (!this.canSubmitPayment()) {
      return Promise.resolve();
    }
    if (this.isRoomCharge()) {
      if (!selectFolioSearchModalActive(store.getState())) {
        store.dispatch(setModalActive(true));
        return Promise.resolve();
      }

      externalOptions.folio = this.payment().folioDetails;
    }

    return CheckoutHelper.create(this.booking, paymentOptions, externalOptions);
  }

  activate() {
    if (!this.booking.activatable()) {
      return Promise.resolve();
    }
    return store.dispatch(BookingServerActions.activate(this.booking));
  }

  payment() {
    return PaymentStore.currentPayment();
  }

  referenceComment() {
    const isReferenceRequired = selectIsReferenceRequired(store.getState());

    if (!isReferenceRequired) {
      return '';
    }
    return selectPaymentReference(store.getState());
  }

  print() {
    if (!this.booking.printable()) {
      return Promise.resolve();
    }

    return store.dispatch(printTickets());
  }

  calculateSurcharge(amount) {
    if (amount == null) {
      amount = this.booking.balance();
    }
    const paymentType = this.payment().paymentType();
    if (!paymentType) {
      return new Money(0);
    }
    return amount.multiply(paymentType.surchargeMultiplier());
  }

  canSubmitPayment() {
    return this.booking.payable() || this.booking.refundable();
  }

  isRoomCharge() {
    return (
      this.payment().payment_type_id ==
      PaymentTypeStore.roomChargePaymentType()?.id
    );
  }
}

export default CheckoutService;
