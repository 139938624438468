import Backbone from 'backbone';
import Resource from 'models/resource';
import Config from 'config/config';

const Resources = Backbone.Collection.extend({
  model: Resource,
  url: Config.routes.resources,
  headers: { 'X-ORIGIN-KEY': Config.apiKey },
});

export default new Resources();
