import { Fragment } from 'react';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

const ConsumerDetails = ({ ticket }) => {
  if (isEmpty(ticket)) {
    return null;
  }
  const renderConsumerCounts = (consumerHash) => {
    if (isEmpty(consumerHash)) {
      return null;
    }

    const consumerList = Object.keys(consumerHash).map((key) => {
      return (
        <Fragment key={`consumer-${key}`}>
          <dt>{key}</dt>
          <dd>{consumerHash[key]}</dd>
        </Fragment>
      );
    });

    return <dl>{consumerList}</dl>;
  };

  return (
    <div className="ticket-section consumer-details">
      {renderConsumerCounts(ticket.passengers)}
      {renderConsumerCounts(ticket.vehicles)}
    </div>
  );
};

ConsumerDetails.propTypes = {
  ticket: PropTypes.shape({
    passengers: PropTypes.object,
    vehicles: PropTypes.object,
  }),
};

export default ConsumerDetails;
