import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { setCommentsModalVisibility } from 'actions/redux/ui_actions';
import PropTypes from 'prop-types';

const BookingCommentBtn = createReactClass({
  displayName: 'BookingCommentBtn',

  buttonClicked(e) {
    e.preventDefault();
    this.props.setCommentsModalVisibility(true);
  },

  render() {
    return (
      <button
        visible={this.props.visible}
        className={`grid-12 grid-medium-4 button info large full button-booking-comments`}
        onClick={this.buttonClicked}
        ref="complete"
      >
        Comments
      </button>
    );
  },
});

BookingCommentBtn.propTypes = {
  visible: PropTypes.bool,
};

BookingCommentBtn.defaultValue = {
  visible: true,
};

export default connect(null, { setCommentsModalVisibility })(BookingCommentBtn);
