import {
  searchTicket,
  validateTicket as validateTicketAPI,
} from 'api/print_server_api';
import { searchClientVehicleTemplates } from 'api/clients_api';

import Booking from 'models/booking';
import { show, handleJsonResponse } from 'actions/redux/error_handler_actions';

import {
  setTicket,
  showTicket,
  hideTicket,
  setTicketBooking,
  setVehicleTemplates,
  setValidation,
} from 'actions/redux/ticket_actions';

import AppDispatcher from 'app_dispatcher';

const TICKET_FORMAT_RESERVATION = 1;
const TICKET_FORMAT_ISSUED_TICKET = 2;
const TICKET_FORMAT_ONLINE_TICKET = 3;

const supportedTicketFormats = [
  TICKET_FORMAT_RESERVATION,
  TICKET_FORMAT_ISSUED_TICKET,
  TICKET_FORMAT_ONLINE_TICKET,
];

const scanTicketBarcode = (barcode) => {
  return (dispatch) => {
    var ticket;
    return searchTicket(barcode)
      .then((ticket_response) => {
        ticket = ticket_response.data;

        if (!supportedTicketFormats.includes(ticket.format)) {
          dispatch(hideTicket());
          return Promise.reject('Not a scannable ticket');
        }

        dispatch(setTicket({ uid: barcode, ...ticket }));

        // Load the booking
        const bookingToLoad = new Booking(
          { id: ticket.reservation.booking_id },
          { silent: true }
        );
        return bookingToLoad.fetch();
      })
      .then((bookingResponse) => {
        const booking = bookingResponse;
        const { id, reference, client } = booking;
        AppDispatcher.trigger('booking:load', id);

        dispatch(setTicketBooking({ reference, client }));

        //Fetch client
        if (booking.client) {
          return searchClientVehicleTemplates(booking.client.id)
            .then((response) => {
              return Promise.resolve(response);
            })
            .catch((response) => {
              return dispatch(handleJsonResponse(response, 'dismiss'));
            });
        }

        return Promise.resolve({ vehicleTemplates: [] });
      })
      .then((vehicleTemplatesResponse) => {
        const vehicleTemplates = vehicleTemplatesResponse.vehicle_templates;

        dispatch(setVehicleTemplates(vehicleTemplates));
        dispatch(showTicket());
      })
      .catch((error) => {
        dispatch(show(error.toString(), 'dismiss'));
      });
  };
};

const validateTicket = (ticket) => {
  return (dispatch) => {
    const uid = ticket?.uid ?? ticket.reference;

    return validateTicketAPI(uid)
      .then((validated_ticket_response) => {
        dispatch(
          setValidation({ valid: true, response: validated_ticket_response })
        );
      })
      .catch((error) => {
        dispatch(
          setValidation({
            valid: false,
            response: error.toString(),
            allowOverride: error.json?.staff_can_override ?? false,
          })
        );
      });
  };
};

const processTicket = (ticket, override) => {
  return (dispatch) => {
    const uid = ticket?.uid ?? ticket.reference;

    return validateTicketAPI(uid, override)
      .then((validated_ticket_response) => {
        dispatch(
          setValidation({
            valid: true,
            response: validated_ticket_response,
            boarded: true,
          })
        );
      })
      .catch((error) => {
        dispatch(
          setValidation({
            valid: false,
            response: error.toString(),
            allowOverride: error.json?.staff_can_override ?? false,
          })
        );
      });
  };
};

export { scanTicketBarcode, validateTicket, processTicket };
