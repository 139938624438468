import { useState, useEffect } from 'react';
import ProductStore from 'stores/product_store';
import * as ProductSelectionActions from 'actions/redux/product_selection_actions';
import { useDispatch } from 'react-redux';

const ProductTypeSwitch = () => {
  const dispatch = useDispatch();

  const [productTypes, setProductTypes] = useState(
    ProductStore.productTypes.models
  );
  const [selectedProductType, setSelectedProductType] = useState(
    ProductStore.selectedProductType
  );
  const [panelOpen, setPanelOpen] = useState(false);

  const _onChange = () => {
    setProductTypes(ProductStore.productTypes.models);
    setSelectedProductType(ProductStore.selectedProductType);
  };

  useEffect(() => {
    ProductStore.addChangeListener(_onChange);
    return () => {
      ProductStore.removeChangeListener(_onChange);
    };
  });

  const togglePanel = (e) => {
    e.preventDefault();
    setPanelOpen(!panelOpen);
  };

  const onProductTypeChange = (productType, e) => {
    setPanelOpen(false);
    e.preventDefault();
    dispatch(ProductSelectionActions.selectProductType(productType));
  };

  const isActive = (productType) => {
    return productType.id === selectedProductType.id;
  };

  const productTypesTabsTemplate = (productType, index) => {
    const className = isActive(productType) ? 'active' : 'inactive';
    return (
      <a
        href="#"
        className={`dropdown-option ${className}`}
        onClick={(e) => {
          onProductTypeChange(productType, e);
        }}
        key={`productTypeTab-${index}`}
      >
        {productType.get('name')}
      </a>
    );
  };

  const productSelectedLabel = () => {
    if (selectedProductType == null) {
      return '';
    }
    return (
      <div className="booking-info info-title product-type-current">
        <i className="icon-tag" /> {selectedProductType.get('name')}
      </div>
    );
  };

  if (productTypes == null) {
    return <span />;
  }

  const panelClass = panelOpen ? 'active' : '';
  return (
    <div className="product-type-nav control-panel">
      <a href="#" title="Change destination" onClick={togglePanel}>
        {productSelectedLabel()}
      </a>
      <div className={`dropdown-panel ${panelClass}`}>
        {productTypes.map(productTypesTabsTemplate)}
      </div>
    </div>
  );
};

export default ProductTypeSwitch;
