import { createReducer } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { setConsumerType } from 'actions/redux/thunks/consumer_type_thunks';

const INITIAL_STATE = {};

const consumerTypeReducer = createReducer(INITIAL_STATE, {
  [setConsumerType]: (state, action) => {
    return merge({}, state, action.payload);
  },
});

export default consumerTypeReducer;
