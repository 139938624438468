import { useSelector, useDispatch } from 'react-redux';
import TicketModal from './ticket_modal';

import { hideTicket } from 'actions/redux/ticket_actions';
import { processTicket } from 'actions/redux/thunks/ticket_thunks';
import { unassignClient } from 'actions/redux/thunks/client_thunks';

import {
  selectVehicleTemplates,
  selectValidation,
  selectNumberPlate,
  selectIsNumberPlateInvalid,
  selectIsValidated,
  selectIsBoarded,
  selectCanValidate,
} from 'selectors/ticket_selectors';

import * as BookingActions from 'actions/redux/thunks/booking_thunks';

const ConnectedTicketModal = () => {
  const dispatch = useDispatch();

  const rawTicket = useSelector((state) => state.ticket);
  const canValidate = useSelector((state) => selectCanValidate(state));
  const numberPlate = useSelector((state) => selectNumberPlate(state));
  const isBoarded = useSelector((state) => selectIsBoarded(state));
  const isValidated = useSelector((state) => selectIsValidated(state));
  const invalidNumberPlate = useSelector((state) =>
    selectIsNumberPlateInvalid(state)
  );
  const validationResult = useSelector((state) => selectValidation(state));
  const vehicleTemplates = useSelector((state) =>
    selectVehicleTemplates(state)
  );
  const previousBookingId = useSelector(
    (state) => state.ticket.previousBookingId
  );

  const { visible, ticketDetails, booking } = rawTicket;

  return (
    <TicketModal
      numberPlate={numberPlate}
      visible={visible}
      ticket={ticketDetails}
      booking={booking}
      canValidate={canValidate}
      isBoarded={isBoarded}
      isValidated={isValidated}
      invalidNumberPlate={invalidNumberPlate}
      validationResult={validationResult}
      vehicleTemplates={vehicleTemplates}
      hideTicket={() => {
        dispatch(hideTicket());
      }}
      unassignClient={() => {
        dispatch(unassignClient());
      }}
      processTicket={(ticketDetails, override) => {
        dispatch(processTicket(ticketDetails, override));
      }}
      loadBooking={(id) => dispatch(BookingActions.loadBooking(id))}
      previousBookingId={previousBookingId}
    />
  );
};

export default ConnectedTicketModal;
