import { createAction } from '@reduxjs/toolkit';

const setBookingModalVisibility = createAction('ui/setBookingModalVisibility');
const setCommentsModalVisibility = createAction(
  'ui/setCommentsModalVisibility'
);
const setQuantityModalVisibility = createAction(
  'ui/setQuantityModalVisibility'
);
const setBookingId = createAction('ui/setBookingId');
const setOpenSection = createAction('ui/setOpenSection');

const toggleReservation = createAction('ui/reservation/toggleSelected');
const selectReservations = createAction('ui/reservation/setList');
const unselectReservation = createAction('ui/reservation/unset');

export {
  setBookingModalVisibility,
  setCommentsModalVisibility,
  setQuantityModalVisibility,
  setBookingId,
  setOpenSection,
  toggleReservation,
  selectReservations,
  unselectReservation,
};
