import Config from 'config/config';
import { PrintService } from '@sealink/printers_qt';

import store from 'store';
import { selectAuthenticatedUser } from 'selectors/login_selectors';

// private methods
const initConfig = (printServerType) => {
  const state = store.getState();
  const user = selectAuthenticatedUser(state);

  return {
    quicktravel: {
      host: Config.quicktravelUrl,
      bearerToken: user.access_token,
    },
    config: {
      host: Config.printing.host,
      printServerType: printServerType,
      bearerToken: user.access_token,
    },
  };
};

const configuredTicketService = () => {
  return new PrintService(initConfig(Config.tickets.printType));
};

const searchTicket = (barcode) => {
  return configuredTicketService().issuedTicket(barcode);
};

const boardTicket = (ticket) => {
  return configuredTicketService().boardTickets([ticket]);
};

const validateTicket = (uid, override) => {
  const options = {
    override,
    scanning_options: {
      specific_stop_id: Config.scanningOptions.specificStopId,
      specific_resource_id: Config.scanningOptions.specificResourceId,
      specific_vessel_id: Config.scanningOptions.specificVesselId,
      validate_departure: Config.scanningOptions.validateDeparture,
      validate_departure_mode: Config.scanningOptions.validateDepartureMode,
    },
  };

  return configuredTicketService().validateTicket(uid, options);
};

export { searchTicket, validateTicket, boardTicket };
