import { useState } from 'react';
import { processBarcode } from 'actions/redux/thunks/barcode_processor_thunks';
import { useSelector, useDispatch } from 'react-redux';
import AppVersion from 'views/page/app_version';
import { useNavigate } from 'react-router-dom';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import ServerPinger from 'utils/server_pinger';

import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { logout } from 'actions/redux/thunks/login_thunks';

const AppControls = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => selectAuthenticatedUser(state));
  const dispatch = useDispatch();

  const [panelOpen, setPanelOpen] = useState(false);

  const togglePanel = (e) => {
    e.preventDefault();
    setPanelOpen(!panelOpen);
  };

  const logMeOut = (e) => {
    togglePanel(e);
    dispatch(logout());
  };

  const refetchData = (e) => {
    togglePanel(e);
    localStorage.clear();
    window.location.reload(true); // reloads without cache
    navigate('/', { replace: true });
  };

  const reloadBooking = (e) => {
    e.preventDefault();
    dispatch(BookingActions.reloadBooking());
  };

  const barcodeScan = (e) => {
    e.preventDefault();
    const barcode = prompt(
      'Please scan the ticket barcode, or type it in and press <Enter>'
    );
    dispatch(processBarcode(barcode.trim()));
  };

  const openBookingList = (e) => {
    e.preventDefault();
    navigate('/list/bookings', { replace: true });
    dispatch(BookingActions.listBookings());
  };

  const panelClass = panelOpen ? 'active' : '';

  return (
    <div className="nav-section-bottom">
      <ServerPinger />
      <a
        className="dropdown-link"
        href="#"
        title="View Past Bookings"
        onClick={openBookingList}
      >
        <i className="icon-list" />
      </a>
      <a
        className={`dropdown-link ${panelClass}`}
        href="#"
        onClick={togglePanel}
      >
        <i className="icon-cog" />
      </a>
      <div className={`dropdown-panel bottom ${panelClass}`}>
        <div className="dropdown-panel-info">
          <em>{user.login}</em>
        </div>
        <div className="dropdown-divider" />
        <a className="dropdown-option" href="#" onClick={refetchData}>
          <i className="icon-reload" />
          Refetch cached data
        </a>
        <a className="dropdown-option" href="#" onClick={barcodeScan}>
          <i className="icon-reload" />
          Manual Barcode Scan
        </a>
        <a className="dropdown-option" href="#" onClick={reloadBooking}>
          <i className="icon-reload" />
          Reload Booking
        </a>
        <a className="dropdown-option" href="#" onClick={logMeOut}>
          <i className="icon-logout" />
          Logout
        </a>
      </div>
      <AppVersion />
    </div>
  );
};

export default AppControls;
