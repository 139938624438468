import { createReducer } from '@reduxjs/toolkit';
import {
  setClientHistory,
  setClientSearchResults,
} from 'actions/redux/thunks/client_thunks';

const INITIAL_STATE = {
  history: [],
  searchResults: {},
};

const ClientReducer = createReducer(INITIAL_STATE, {
  [setClientHistory]: (state, action) => {
    state.history = action.payload;
  },
  [setClientSearchResults]: (state, action) => {
    state.searchResults = action.payload;
  },
});

export default ClientReducer;
