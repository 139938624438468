import { createSelector } from '@reduxjs/toolkit';

const selectSelectedTillOption = (state) => state?.tills?.selected || null;
const selectTillEntities = (state) => state.tills?.list || [];

const selectTillOptions = createSelector([selectTillEntities], (tills) => {
  return tills
    .filter((o) => o.active)
    .map((till) => {
      return {
        label: till.name,
        value: till.id,
      };
    });
});

export { selectTillOptions, selectSelectedTillOption };
