import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

const Resource = ({ resource }) => {
  if (isEmpty(resource)) {
    return null;
  }

  return <div className="ticket-section resource">{resource.name}</div>;
};

Resource.propTypes = {
  resource: PropTypes.shape({
    name: PropTypes.string,
  }),
};
export default Resource;
