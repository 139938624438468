import { useDispatch, useSelector } from 'react-redux';
import ExtraPickModal from 'components/modals/extra_picks/extra_pick_modal';
import {
  addWithExtraPicks,
  resetSelectedResource,
} from 'actions/redux/reservation_to_add_actions';

import { selectSelectedResource } from 'selectors/reservation_to_add_selectors';

const ProductExtraPicksModal = () => {
  const dispatch = useDispatch();

  const resource = useSelector((state) => selectSelectedResource(state));

  const addReservationWithExtraPicks = (selectedIds) => {
    dispatch(addWithExtraPicks(selectedIds));
    dispatch(resetSelectedResource());
  };

  if (resource == null) {
    return null;
  }

  return (
    <ExtraPickModal
      key={`resource-${resource.id}-extra-pick-modal`}
      extraPicks={resource?.childResources || []}
      onSelectExtraPicks={addReservationWithExtraPicks}
    />
  );
};

export default ProductExtraPicksModal;
