import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import { selectOpenSection } from 'selectors/ui_selectors';
import { selectClientDetails } from 'selectors/booking_selectors';
import { selectNumberPlate } from 'selectors/ticket_selectors';

import ClientDetails from 'components/client/client_details';

const ConnectedClientDetails = () => {
  const dispatch = useDispatch();
  const clientDetails = useSelector(selectClientDetails);
  const numberPlate = useSelector(selectNumberPlate);
  const { bookingId, description, isAgent } = clientDetails;

  const isClientSectionOpen = useSelector(
    (state) => selectOpenSection(state) === 'ClientScreen'
  );

  const navigate = useNavigate();

  const navigateToClient = () => {
    if (isClientSectionOpen) {
      navigate('../bookings/new', { replace: true });
      dispatch(BookingActions.displayProductListScreen());
    } else {
      navigate(`../bookings/${bookingId}/client`, { replace: true });
      dispatch(BookingActions.addClientToBooking());
    }
  };

  return (
    <ClientDetails
      description={description}
      isAgent={isAgent}
      onClick={navigateToClient}
      numberPlate={numberPlate}
    />
  );
};

export default ConnectedClientDetails;
