import Config from 'config/config';

var CategoryColour = {
  set(element, isActive, categoryId) {
    if (!element) {
      return;
    }
    const colour = CategoryColour.get(isActive, categoryId);
    if (colour) {
      element.style.setProperty(
        'background-color',
        colour.bgColour,
        'important'
      );
      return element.style.setProperty('color', colour.fontColour, 'important');
    } else {
      element.style.removeProperty('background-color');
      return element.style.removeProperty('color');
    }
  },

  get(isActive, categoryId) {
    const colour = Config.categoryColourConfig[categoryId];
    if (!colour) {
      return null;
    }
    const bgColour = isActive ? colour.activeBackground : colour.background;

    const fontColour = isActive ? colour.activeFont : colour.font;
    return {
      bgColour,
      fontColour,
    };
  },
};

export default CategoryColour;
