import PropTypes from 'prop-types';

const PassengerSelection = ({
  passengerTypeNumbers,
  onSelectPassengerType = () => {},
}) => {
  const renderPassengers = () => {
    return passengerTypeNumbers.map((passengerType) => {
      return (
        <button
          key={`type-${passengerType.id}`}
          className="button full default button-qty"
          onClick={() => {
            onSelectPassengerType(passengerType.id);
          }}
          title={passengerType.fullName}
        >
          <span>{passengerType.quantity}</span>
          &nbsp;
          <span>{passengerType.shortName}</span>
        </button>
      );
    });
  };

  return (
    <div className="passenger-list grid-12">
      <div className="section text-center">{renderPassengers()}</div>
    </div>
  );
};

PassengerSelection.propTypes = {
  passengerTypeNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string,
      shortName: PropTypes.string,
      quantity: PropTypes.number,
    })
  ),
  onSelectPassengerType: PropTypes.func,
};

export default PassengerSelection;
