import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductSelectionTabs from 'views/products/product_selection_tabs';
import QuantityModal from 'components/product/quantity_modal';
import ProductExtraPicksModal from 'views/products/product_extra_picks_modal';
import ProductStore from 'stores/product_store';
import classNames from 'classnames';
import {
  selectQuantityModalOpen,
  selectOpenSection,
} from 'selectors/ui_selectors';
import { hasNoPassengerTypesSelected } from 'selectors/reservation_to_add_selectors';
import { changeQuantity } from 'actions/redux/reservation_to_add_actions';
import { setQuantityModalVisibility } from 'actions/redux/ui_actions';

const ProductListScreen = () => {
  const dispatch = useDispatch();
  const section = useSelector(selectOpenSection);
  const isOpen = section === ProductListScreen.displayName;

  const quantityModalOpen = useSelector((state) =>
    selectQuantityModalOpen(state)
  );

  const [selectedProductType, setSelectedProductType] = useState(
    ProductStore.selectedProductType
  );
  const [selectedProductTypeCategory, setSelectedProductTypeCategory] =
    useState(ProductStore.selectedProductTypeCategory);

  const [searchTerm, setSearchTerm] = useState(ProductStore.productFilterText);

  const noPassengersSelected = useSelector(
    (state) =>
      ProductStore.passengersRequired() && hasNoPassengerTypesSelected(state)
  );

  const handleStoreChange = () => {
    setSelectedProductType(ProductStore.selectedProductType);
    setSelectedProductTypeCategory(ProductStore.selectedProductTypeCategory);
    setSearchTerm(ProductStore.productFilterText);
  };

  useEffect(() => {
    ProductStore.addChangeListener(handleStoreChange);
    return () => {
      ProductStore.removeChangeListener(handleStoreChange);
    };
  });

  const className = classNames({
    hide: !isOpen,
    'product-list-content': true,
    row: true,
  });

  return (
    <div className={className}>
      <div className="grid-12 tab-content active bgd-white">
        <ProductSelectionTabs
          searchTerm={searchTerm}
          productType={selectedProductType}
          selectedProductTypeCategory={selectedProductTypeCategory}
          noPassengersSelected={noPassengersSelected}
        />
        <QuantityModal
          open={quantityModalOpen}
          onClose={() => {
            dispatch(setQuantityModalVisibility(false));
          }}
          onUpdateQuantity={(quantity) => {
            dispatch(changeQuantity(quantity));
          }}
        />
        <ProductExtraPicksModal />
      </div>
    </div>
  );
};

ProductListScreen.displayName = 'ProductListScreen';

export default ProductListScreen;
