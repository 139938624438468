import { Component } from 'react';
import { connect } from 'react-redux';

import {
  startScan,
  processKeyStroke,
} from 'actions/redux/thunks/barcode_scanner_thunks';

export class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnMount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (this.props.enabled) {
      const { key } = event;

      if (key == prefix) {
        this.props.startScan(event);
        event.preventDefault();
      } else if (this.props.isScanning) {
        this.props.processKeyStroke(key);
        event.preventDefault();
      }
    }
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  const { value, isScanning } = state.barcodeScanner;

  return { value, isScanning, enabled: !state.ui.bookingCompletedModalVisible };
};

export default connect(mapStateToProps, { startScan, processKeyStroke })(
  BarcodeScanner
);

export const prefix = '#';
export const suffix = ';';
