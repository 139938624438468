import { createAction } from '@reduxjs/toolkit';
import Config from 'config/config';
import localStore from 'utils/local_store';

import { fetchPassengerTypes } from 'actions/redux/thunks/consumer_type_thunks';
import { fetchPrinters } from 'actions/redux/thunks/printer_thunks';
import { fetchPromoCodes } from 'actions/redux/thunks/promo_code_thunks';
import { fetchCountries } from 'actions/redux/thunks/country_thunks';
import { fetchTills } from 'actions/redux/thunks/till_thunks';
import { newBooking } from 'actions/redux/thunks/booking_thunks';
import { reloadClientHistoryFromLocalStore } from 'actions/redux/thunks/client_thunks';
import { loadingNotice, clearNotice } from 'actions/redux/notice_actions';

const setTenants = createAction('tenant/set');
const selectTenantComplete = createAction('tenant/selection/complete');
import AppDispatcher from 'app_dispatcher';

const loadAvailableTenantsStarted = createAction(
  'loadAvailableTenants/started'
);
const loadAvailableTenantsSucceeded = createAction(
  'loadAvailableTenants/succeeded'
);
const loadAvailableTenantsFailure = createAction(
  'loadAvailableTenants/failure'
);

import { logout } from 'actions/redux/thunks/login_thunks';

import MeServerApi from 'api/me_server_api';

const selectTenant = (tenant) => {
  return (dispatch) => {
    localStore.set('defaultTenant', tenant);

    // For non redux connected components update the in-memory
    // configuration state.
    const config = Config.forTenant(tenant);
    Config.updateConfigForTenant(config);

    AppDispatcher.trigger('loggedIn'); // load backbone stores

    return Promise.all([
      dispatch(loadingNotice('Configuring tenant...')),
      dispatch(fetchPrinters(config)),
      dispatch(fetchPassengerTypes()),
      dispatch(fetchPromoCodes()),
      dispatch(fetchCountries()),
      dispatch(fetchTills()),
      dispatch(reloadClientHistoryFromLocalStore(tenant)),
    ]).then(() => {
      dispatch(selectTenantComplete(tenant));
      dispatch(clearNotice());
      dispatch(newBooking());
    });
  };
};

const loadAvailableTenants = () => {
  return (dispatch, state) => {
    dispatch(loadAvailableTenantsStarted());
    const access_token = state()?.login?.authenticatedUser?.access_token;

    return MeServerApi.availableTenants(access_token)
      .then((json) => {
        const tenants = json;

        dispatch(loadAvailableTenantsSucceeded(tenants));

        if (
          tenants.length == 1 &&
          !Config.tenantHasMultipleConfigs(tenants[0].id)
        ) {
          return dispatch(selectTenant(tenants[0].id));
        }
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(logout());
        }
        dispatch(loadAvailableTenantsFailure(error.message));
      });
  };
};

export {
  selectTenant,
  selectTenantComplete,
  setTenants,
  loadAvailableTenants,
  loadAvailableTenantsStarted,
  loadAvailableTenantsSucceeded,
  loadAvailableTenantsFailure,
};
