import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const ValidationResult = ({ valid, response }) => {
  const renderValidation = () => {
    if (isEmpty(response)) {
      return <div className="validation-message redo">Not yet validated.</div>;
    }

    if (response.ticket && isEmpty(response.ticket.ticket_template)) {
      return <div className="validation-message redo">No template.</div>;
    }

    var message;
    var validationClass;

    if (valid) {
      message = `Used: ${response.ticket.uses.length} / ${response.ticket.ticket_template.maximum_uses}`;
      validationClass = 'success';
    } else {
      message = `${response}`;
      validationClass = 'danger';
    }

    return (
      <div className={`validation-message ${validationClass}`}>
        <div>{valid ? 'VALID' : 'INVALID'}</div>
        <div>{message}</div>
      </div>
    );
  };

  return (
    <div className="validation-result">
      <div>Validation Result: </div>
      {renderValidation()}
    </div>
  );
};

ValidationResult.propTypes = {
  response: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  valid: PropTypes.bool,
};

export default ValidationResult;
