import { createAction } from '@reduxjs/toolkit';
import { PrintService } from '@sealink/printers_qt';
import { selectSelectedTenant } from 'selectors/tenant_selectors';
import {
  selectIsFullyIssued,
  selectReprintableTickets,
  selectBooking,
} from 'selectors/booking_selectors';
import {
  selectSelectedPrinter,
  selectPrintAttempts,
} from 'selectors/printer_selectors';
import { selectAccessToken } from 'selectors/login_selectors';
import localStore from 'utils/local_store';
import { showError } from 'actions/redux/notice_actions';
import {
  MAX_PRINT_RETRY_ATTEMPTS,
  PRINTER_RETRY_DELAY_MS,
  incrementPrintAttempts,
  resetPrintAttempts,
} from 'actions/redux/thunks/printer_thunks';

const printTicketStart = createAction('print/ticket/start');
const printTicketSuccess = createAction('print/ticket/success');
const printTicketFailed = createAction('print/ticket/failed');
const printReceiptStart = createAction('print/receipt/start');
const printReceiptSuccess = createAction('print/receipt/success');
const printReceiptFailed = createAction('print/receipt/failed');

const ticketConfig = (tenant, bearerToken) => {
  return {
    quicktravel: {
      host: tenant.quicktravelUrl,
      csrfToken: localStore.get('csrf_auth_token'),
      bearerToken: bearerToken,
    },
    config: {
      host: tenant.printing.host,
      printServerType: tenant.tickets.printType,
      bearerToken: bearerToken,
    },
  };
};

const receiptConfig = (tenant, bearerToken) => {
  return {
    quicktravel: {
      host: tenant.quicktravelUrl,
      csrfToken: localStore.get('csrf_auth_token'),
      bearerToken: bearerToken,
    },
    config: {
      host: tenant.printing.host,
      printServerType: tenant.receipts.printType,
      bearerToken: bearerToken,
    },
  };
};

const printOrReprint = () => {
  return (dispatch, getState) => {
    const state = getState();
    const tenant = selectSelectedTenant(state);
    const booking = selectBooking(state);
    const printer = selectSelectedPrinter(state);
    const accessToken = selectAccessToken(state);

    const printService = new PrintService(ticketConfig(tenant, accessToken));
    if (selectIsFullyIssued(state)) {
      const issued_ticket_ids = selectReprintableTickets(state).map(
        (ticket) => ticket.id
      );
      return printService.reprintTickets(
        printer.id,
        booking.id,
        issued_ticket_ids
      );
    } else {
      return printService.printReservations(printer.id, booking.id, 'all');
    }
  };
};

const printTickets = () => {
  return (dispatch, getState) => {
    dispatch(printTicketStart());

    const state = getState();
    const tenant = selectSelectedTenant(state);

    const booking = selectBooking(state);
    const selectedPrinter = selectSelectedPrinter(state);

    return dispatch(printOrReprint())
      .then(() => {
        dispatch(printTicketSuccess());
        dispatch(resetPrintAttempts());
        if (tenant.receipts.autoPrint) {
          return dispatch(doPrintReceipt(tenant, booking, selectedPrinter));
        } else {
          return Promise.resolve();
        }
      })
      .catch((error) => {
        if (selectPrintAttempts(state) < MAX_PRINT_RETRY_ATTEMPTS) {
          setTimeout(() => {
            dispatch(incrementPrintAttempts());
            dispatch(printTickets());
          }, PRINTER_RETRY_DELAY_MS);
        } else {
          Promise.all([
            dispatch(showError(`Print Error: ${error.message}`, 'dismiss')),
            dispatch(printTicketFailed(error.message)),
            dispatch(resetPrintAttempts()),
          ]);
        }
      });
  };
};

const doPrintReceipt = (tenant, booking, selectedPrinter) => {
  return (dispatch, getState) => {
    dispatch(printReceiptStart());
    const state = getState();
    const accessToken = selectAccessToken(state);

    const printService = new PrintService(receiptConfig(tenant, accessToken));
    return printService
      .printReceipt(selectedPrinter.id, booking.id)
      .then(() => {
        dispatch(resetPrintAttempts());
        dispatch(printReceiptSuccess());
      })
      .catch((error) => {
        if (selectPrintAttempts(state) < MAX_PRINT_RETRY_ATTEMPTS) {
          setTimeout(() => {
            dispatch(incrementPrintAttempts());
            dispatch(doPrintReceipt(tenant, booking, selectedPrinter));
          }, PRINTER_RETRY_DELAY_MS);
        } else {
          Promise.all([
            dispatch(showError(`Print Error: ${error.message}`, 'dismiss')),
            dispatch(printReceiptFailed(error.message)),
            dispatch(resetPrintAttempts()),
          ]);
        }
      });
  };
};

const printReceipt = () => (dispatch, getState) => {
  const state = getState();
  const tenant = selectSelectedTenant(state);
  const booking = selectBooking(state);
  const selectedPrinter = selectSelectedPrinter(state);

  return dispatch(doPrintReceipt(tenant, booking, selectedPrinter));
};

export { printTickets, printReceipt };
