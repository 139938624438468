import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import NumberPlate from 'components/vehicle/number_plate';

const ClientDetails = ({
  description = null,
  isAgent = false,
  numberPlate = '',
  onClick = () => {},
}) => {
  const hangleOnClick = (e) => {
    e.preventDefault();
    onClick();
  };

  const displayClientOrCustomer = () => {
    if (!isEmpty(description)) {
      return description;
    }

    return 'Add Client';
  };

  const displayAgentIcon = () => {
    if (isAgent) {
      return 'icon-users';
    }
    return 'icon-user';
  };

  const agentIcon = displayAgentIcon();
  return (
    <div className="control-panel">
      <a href="#" title="Add Client / Details" onClick={hangleOnClick}>
        <div className="booking-info info-title">
          <i className={agentIcon} /> {displayClientOrCustomer()}
          <NumberPlate numberPlate={numberPlate} />
        </div>
      </a>
    </div>
  );
};

ClientDetails.propTypes = {
  description: PropTypes.string,
  numberPlate: PropTypes.string,
  isAgent: PropTypes.bool,
  onClick: PropTypes.func,
};

ClientDetails.displayName = 'ClientDetails';
export default ClientDetails;
