import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedPaymentType } from './payment_type_selectors';

export const selectPaymentReference = (state) =>
  state?.payment?.reference || '';

export const selectIsReferenceRequired = createSelector(
  [selectSelectedPaymentType],
  (paymentType) => {
    if (!paymentType) {
      return false;
    }

    const result =
      paymentType.comment_required &&
      paymentType.payment_method !== 'room_charge';

    return result;
  }
);
