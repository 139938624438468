import { createAction } from '@reduxjs/toolkit';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { promoCodes } from 'api/promo_code_api';
import ServicesAPI from 'api/services_api';
import { intersection } from 'lodash';
import { AUTO_CODE_OFF } from 'models/promo_codes';
import {
  setPromoCodes,
  setAutoPromoCode,
} from 'actions/redux/promo_code_actions';

const fetchPromCodesStarted = createAction('promo_codes/fetch/started');
const fetchPromCodesSucceeded = createAction('promo_codes/fetch/succeeded');
const fetchPromCodesFailed = createAction('promo_codes/fetch/failure');

const fetchPromoCodes = () => (dispatch, getState) => {
  dispatch(fetchPromCodesStarted());

  const user = selectAuthenticatedUser(getState());
  return promoCodes(user?.access_token)
    .then((result) => {
      dispatch(setPromoCodes(result));
      dispatch(fetchPromCodesSucceeded(result));
    })
    .catch((error) => dispatch(fetchPromCodesFailed(error.message)));
};

const getDeparture = ({ departure, departureMode }) => {
  if (departureMode === 'next') {
    return ServicesAPI.nextDeparture(departure);
  } else {
    return ServicesAPI.currentDeparture(departure);
  }
};

const fetchAutoSeasonalityCode = (config) => (dispatch) => {
  return getDeparture(config)
    .then((departure) => {
      if (departure) {
        const whiteListSeasonalities = Object.keys(config.mapping).map((key) =>
          parseInt(key)
        );

        const applicableSeasonalities = intersection(
          whiteListSeasonalities,
          departure.seasonality_ids
        );

        if (applicableSeasonalities.length > 0) {
          const promoCode = config.mapping[applicableSeasonalities[0]];
          return dispatch(
            setAutoPromoCode({ label: promoCode.code, value: promoCode.id })
          );
        }
      }
      return dispatch(setAutoPromoCode(AUTO_CODE_OFF));
    })
    .catch((error) => {
      return dispatch(fetchPromCodesFailed(error.message));
    });
};

export {
  fetchPromoCodes,
  fetchPromCodesStarted,
  fetchPromCodesSucceeded,
  fetchPromCodesFailed,
  fetchAutoSeasonalityCode,
  setPromoCodes,
};
