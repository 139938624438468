import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useGetAdjustmentDefintionsQuery } from 'features/api/api_slice';
import * as AdjustmentSelectionActions from 'actions/redux/thunks/adjustment_thunks';
import Config from 'config/config';
import PropTypes from 'prop-types';

const AdjustmentItem = ({ id, active }) => {
  const dispatch = useDispatch();

  const selectAdjustmentDefinition = (adjustmentDefinition, e) => {
    if (!active) {
      return false;
    } // disables for touch (Not entirely convinced this gets reached anymore)
    e.preventDefault();

    dispatch(
      AdjustmentSelectionActions.selectAdjustmentDefinition(
        adjustmentDefinition
      )
    );
  };

  // Will select the post with the given id, and will only rerender if the given posts data changes
  const { adjustment } = useGetAdjustmentDefintionsQuery(
    Config.adjustmentCategoryId,
    {
      selectFromResult: ({ data }) => {
        return { adjustment: data?.find((adjustment) => adjustment.id === id) };
      },
    }
  );

  const shortName = (name) => {
    return name.split(' ')[0];
  };

  const className = classNames(
    'button',
    'danger',
    'full',
    'button-adjustment',
    { disabled: !active }
  );

  return (
    <button
      className={className}
      disabled={!active}
      href="#"
      title={adjustment.description}
      onClick={(e) => {
        selectAdjustmentDefinition(adjustment, e);
      }}
    >
      {shortName(adjustment.name)}
    </button>
  );
};

AdjustmentItem.propTypes = {
  id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
};

export default AdjustmentItem;
