const localStore = {
  set(data, value) {
    if (value == null) {
      console.warn(
        `You can not store \`${data}\` as ${value} - use localStore.unset().`
      );
      return null;
    }
    return (localStorage[data] = JSON.stringify(value));
  },

  get(data) {
    const rawData = localStorage[data];
    if (rawData == null || rawData === 'undefined') {
      return null;
    }
    return JSON.parse(rawData);
  },

  unset(data) {
    localStorage.removeItem(data);
  },

  exists(data) {
    return localStorage[data] != null;
  },
};

export default localStore;
