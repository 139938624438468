import { createSelector } from '@reduxjs/toolkit';

const selectSelectedPrinterId = (state) => state?.printers?.selectedPrinterId;
const selectPrinterEntities = (state) => state.printers.entities;
const selectPrintAttempts = (state) => state.printers.printAttempts;

const selectPrinters = createSelector([selectPrinterEntities], (entities) => {
  return entities ?? [];
});

const selectSelectedPrinter = createSelector(
  [selectPrinters, selectSelectedPrinterId],
  (printers, selectedId) => {
    return printers.find((printer) => printer.id === selectedId) ?? null;
  }
);

export { selectPrinters, selectSelectedPrinter, selectPrintAttempts };
