import Money from '@sealink/money_beans';
import PropTypes from 'prop-types';

const PayableTotal = ({
  totalInCents = 0,
  amountInCents = 0,
  surchargeInCents = 0,
  dueInCents = 0,
}) => {
  const due = new Money(dueInCents);
  const total = new Money(totalInCents);
  const surcharge = new Money(surchargeInCents);
  const amount = new Money(amountInCents);

  const dueOrChange = due.isNegative() ? 'Change' : 'Due';
  const surchargeClass = surcharge.isZero() ? 'hidden' : '';

  return (
    <div className="grid-12 col section text-right payable-totals">
      <div className="booking-total clearfix">
        <span className="left mute">Paying</span>
        <div>
          <span className="booking-price positive right">
            ${total.format()}
          </span>
          <span className={`booking-price surcharge right ${surchargeClass}`}>
            <div>Amount: ${amount.format()}</div>
            <div>Surcharge: ${surcharge.format()}</div>
          </span>
        </div>
      </div>
      <div className="booking-total">
        <span className="left mute">{dueOrChange}</span>
        <span className="booking-price negative">${due.absoluteFormat()}</span>
      </div>
    </div>
  );
};

PayableTotal.propTypes = {
  totalInCents: PropTypes.number,
  amountInCents: PropTypes.number,
  surchargeInCents: PropTypes.number,
  dueInCents: PropTypes.number,
};

export default PayableTotal;
