import _ from 'underscore';
import createReactClass from 'create-react-class';

const ReservationAdjustments = createReactClass({
  displayName: 'ReservationAdjustments',

  adjustments() {
    return this.props.reservation.adjustments;
  },

  hasAdjustments() {
    return this.adjustments() != null && !_.isEmpty(this.adjustments());
  },

  render() {
    return <div>{this.renderAdjustments()}</div>;
  },

  renderAdjustments() {
    if (!this.hasAdjustments()) {
      return '';
    }
    return _.map(this.adjustments(), (adjustment, i) => {
      return (
        <div className="item-reservation-info" key={`resAd-${i}`}>
          <span className="text-red">Adjustment</span> -
          <span className="text-white"> {adjustment.description}</span>
        </div>
      );
    });
  },
});

export default ReservationAdjustments;
