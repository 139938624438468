import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignClient,
  unassignClient,
  search,
  clearSearch,
} from 'actions/redux/thunks/client_thunks';
import { selectClient } from 'selectors/booking_selectors';
import {
  selectClientList,
  selectSearchResultTotalCount,
} from 'selectors/clients_selectors';
import { useFocus } from 'utils/use_focus';
import { clearRegistration } from 'actions/redux/vehicle_actions';
import { searchAndAssignClientByBarcode } from 'actions/redux/thunks/vehicle_thunks';
import { selectOpenSection } from 'selectors/ui_selectors';

const ClientSearch = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => selectClient(state));
  const clientList = useSelector((state) => selectClientList(state));
  const totalSearchResults = useSelector((state) =>
    selectSearchResultTotalCount(state)
  );
  const sectionOpen = useSelector((state) => selectOpenSection(state));

  const [searchInputRef, setSearchInputFocus] = useFocus();
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchResultsDisplayed = searchInputValue.length > 0;

  useEffect(() => {
    if (sectionOpen === 'ClientScreen') {
      setSearchInputFocus(true);
    }
  }, [sectionOpen]);

  const clearSearchText = () => {
    setSearchInputValue('');
    dispatch(clearSearch());
  };

  const handleAssignClient = (item, e) => {
    e.preventDefault();
    dispatch(assignClient(item));
  };

  const handleSearchInputChange = (event) => {
    const text = event.target.value;

    setSearchInputValue(text);

    if (text.length > 2) {
      dispatch(search(text));
    }

    if (text.length === 0) {
      dispatch(clearSearch());
    }
  };

  const removeClient = (e) => {
    e.preventDefault();
    dispatch(clearRegistration());
    dispatch(unassignClient());
  };

  const scanBarcode = () => {
    const externalId = prompt(
      'Please scan the vehicle barcode, or type it in and press <Enter>'
    );

    if (externalId) {
      setSearchInputValue(externalId);
      dispatch(searchAndAssignClientByBarcode(externalId));
    }
  };

  const renderClientList = () => {
    if (!searchResultsDisplayed) {
      return <span />;
    }
    return (
      <table
        className="table client-results table-horizontal table-striped"
        width="100%"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Contact</th>
            <th>Payment Method</th>
          </tr>
        </thead>
        <tbody>{totalSearchResults == 0 ? noResults() : results()}</tbody>
      </table>
    );
  };

  const results = () => {
    return clientList.map((item) => {
      return (
        <tr
          key={`client-list-${item.id}`}
          data-id={item.id}
          onClick={(e) => handleAssignClient(item, e)}
          className={hasNotes(item) ? 'client-result-notes' : undefined}
        >
          <td>
            {item.name}
            {showNotes(item)}
          </td>
          <td>{item.type}</td>
          <td>
            {item.email}
            <br /> {item.phone !== '' ? item.phone : undefined}
          </td>
          <td>{item.paymentMethod}</td>
        </tr>
      );
    });
  };

  const showNotes = (item) => {
    if (hasNotes(item)) {
      return;
    }
    return (
      <div>
        <b>Notes: </b>
        {item.notes}
      </div>
    );
  };

  const hasNotes = (item) => {
    return item.notes !== '' || item.notes != null;
  };

  const clearSearchBtn = () => {
    if (!(searchInputValue.length > 0)) {
      return;
    }
    return (
      <button
        className="button default small redo grid-3 grid-grow"
        onClick={clearSearchText}
      >
        Clear Search
      </button>
    );
  };

  const removeClientBtn = () => {
    if (client == null) {
      return;
    }
    return (
      <button
        className="button default small primary grid-3 grid-grow"
        onClick={removeClient}
      >
        Unassign Client
      </button>
    );
  };

  const scanBarcodeBtn = () => {
    if (client != null) {
      return;
    }
    return (
      <button
        className="button default small grid3 grid-grow"
        onClick={scanBarcode}
      >
        Scan Barcode
      </button>
    );
  };

  const noResults = () => {
    return (
      <tr>
        <td colSpan="4">No results matched your search query</td>
      </tr>
    );
  };

  return (
    <div>
      <div className="grid-12 col section">
        <div className="row">
          <input
            type="search"
            placeholder="Search clients"
            className="grid-6 grid-grow"
            ref={searchInputRef}
            value={searchInputValue}
            onChange={handleSearchInputChange}
          />
          {clearSearchBtn()}
          {removeClientBtn()}
          {scanBarcodeBtn()}
        </div>
      </div>
      <div className="grid-12 col section">{renderClientList()}</div>
    </div>
  );
};

ClientSearch.displayName = 'ClientSearch';

export default ClientSearch;
