// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// Define ConfigKeys to be used in Ticketing App
// required keys do not need a default value

const ConfigKeys = {
  tenants: {
    required: true,
    children: {
      id: {
        required: true,
      },
      tenantId: {
        required: false,
      },

      name: {
        required: true,
      },

      quicktravelUrl: {
        required: true,
      },

      productTypeIDs: {
        required: true,
      },

      adjustmentCategoryId: {
        required: false,
      },

      payments: {
        required: true,
        children: {
          allowedPaymentTypes: {
            required: true,
          },
          defaultPaymentTypeId: {
            required: false,
            default: 1,
          },
          sortMethod: {
            required: true,
          },
        },
      },
      countries: {
        required: false,
      },
      printing: {
        required: true,
        children: {
          host: {
            required: true,
          },
          catalogueId: {
            required: true,
          },
        },
      },
      tickets: {
        required: true,
        default: {
          autoPrint: true,
          printType: 'quickets',
        },
        children: {
          autoPrint: {
            required: true,
            default: true,
          },
          printType: {
            required: true,
            default: 'albert',
          },
        },
      },
      receipts: {
        required: true,
        default: {
          autoPrint: false,
          printType: 'albert',
        },
        children: {
          autoPrint: {
            required: true,
            default: true,
          },
          printType: {
            required: true,
            default: 'albert',
          },
        },
      },
      categoryColourConfig: {
        required: false,
      },
      autoPromoCode: {
        required: false,
      },
      timeZone: {
        required: false,
      },
      autoValidateTicket: {
        required: false,
        default: false,
      },
      scanningOptions: {
        required: true,
        default: {
          validateDeparture: false,
          specificStopId: null,
          specificResourceId: null,
          specificVesselId: null,
          validateDepartureMode: 'closest',
        },
      },
      apiKey: {
        required: true,
        default: null,
      },
    },
  },

  printingEnabled: {
    required: true,
  },

  showEnv: {
    required: false,
    default: false,
  },
  rollbar: {
    require: true,
    children: {
      accessToken: {
        required: true,
      },
      enabled: {
        required: true,
      },
      environment: {
        required: false,
      },
    },
  },
  datadog: {
    require: true,
    children: {
      clientToken: {
        required: true,
      },
      applicationId: {
        required: true,
      },
      environment: {
        required: true,
      },
      service: {
        required: true,
      },
    },
  },
  authServiceURL: {
    required: true,
  },
  environment: {
    required: true,
  },
  guestLinkURL: {
    required: true,
  },
  oktaIssuer: {
    required: true,
  },
  oktaClientId: {
    required: true,
  },
  oktaAppBase: {
    required: true,
  },
  oktaEnabled: {
    required: true,
  },
  googleTagManagerId: {
    required: false,
  },
};

export default ConfigKeys;
