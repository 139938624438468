import Config from 'config/config';
import { fetchJson } from 'utils/fetch_utils';

const updateBooking = (id, attributes, access_token) => {
  const request = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
      'X-ORIGIN-KEY': Config.apiKey,
    },
    body: JSON.stringify(attributes),
  };

  return fetchJson(`${Config.routes.bookings()}/${id}.json`, request);
};

const search = (search, access_token) => {
  const url = new URL(Config.routes.search());
  url.searchParams.append('input', search.input);
  url.searchParams.append('per_page', 5);

  if (search?.page) {
    url.searchParams.append('page', search.page);
  }

  const request = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
      'X-ORIGIN-KEY': Config.apiKey,
    },
  };

  return fetchJson(url.toString(), request).then((response) => {
    return response;
  });
};

const recentBookings = (access_token) => {
  const url = new URL(Config.routes.bookings());

  const request = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
      'X-ORIGIN-KEY': Config.apiKey,
    },
  };

  return fetchJson(url.toString(), request).then((response) => {
    return {
      total_pages: 1,
      results: response,
    };
  });
};

export { recentBookings, search, updateBooking };
