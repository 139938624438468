import PropTypes from 'prop-types';

const QuantitySection = ({
  quantity = 0,
  onUpdateQuantity = () => {},
  onResetQuantity = () => {},
  onOpenNumberPad = () => {},
}) => {
  const openNumPad = (e) => {
    e.preventDefault();
    onOpenNumberPad();
  };

  const handleClick = (e, newQuantity) => {
    e.preventDefault();
    onUpdateQuantity(newQuantity);
  };

  const clearQuantity = (e) => {
    e.preventDefault();
    onResetQuantity();
  };

  const smallQuantity = () => {
    return quantity <= 5;
  };

  const showLargerQuantity = () => {
    if (smallQuantity()) {
      return <i className="icon-calc" />;
    }
    return <i className="sub-icon">{quantity}</i>;
  };

  const renderButtons = () => {
    return [1, 2, 3, 4, 5].map((number) => {
      const activeClass = quantity === number ? 'info' : 'default';
      return (
        <button
          className={`button full button-qty ${activeClass}`}
          onClick={(e) => handleClick(e, number)}
          key={`qty-${number}`}
        >
          {number}
        </button>
      );
    });
  };

  const calcActiveClass = smallQuantity() ? 'default' : 'info';
  return (
    <div className="quantity-list grid-grow">
      <div className="section text-center">
        {renderButtons()}
        <button
          className={`button default full button-qty ${calcActiveClass}`}
          aria-label={'Open Keypad'}
          onClick={openNumPad}
        >
          {showLargerQuantity()}
        </button>
        <button
          className="button default full button-qty"
          aria-label={'Clear'}
          onClick={clearQuantity}
        >
          C
        </button>
      </div>
    </div>
  );
};

QuantitySection.propTypes = {
  quantity: PropTypes.number,
  onUpdateQuantity: PropTypes.func,
  onResetQuantity: PropTypes.func,
  onOpenNumberPad: PropTypes.func,
};

export default QuantitySection;
