import AppDispatcher from 'app_dispatcher';
import * as Api from 'api';

import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectBooking } from 'selectors/booking_selectors';
import { showError } from 'actions/redux/notice_actions';
import {
  adjustmentCreatedSuccess,
  adjustmentCreatedFailure,
} from 'actions/redux/adjustment_actions';

export const selectAdjustmentDefinition = (adjustmentDefinition) => () => {
  AppDispatcher.trigger(
    'adjustment:selectAdjustmentDefinition',
    adjustmentDefinition
  );
  return Promise.resolve();
};

export const load = () => () => {
  AppDispatcher.trigger('adjustment:load');
  return Promise.resolve();
};

export const unload = () => () => {
  AppDispatcher.trigger('adjustment:unload');
  return Promise.resolve();
};

const createAdjustment = (adjustment) => {
  return (dispatch, getState) => {
    if (!adjustment.reservation_ids) {
      return dispatch(showError('Please select a reservation'));
    }

    const state = getState();
    const booking = selectBooking(state);
    const user = selectAuthenticatedUser(state);

    const props = {
      adjustment_definition_id: adjustment.adjustment_definition_id,
      adjustable_item_id: adjustment.reservation_ids.join(),
      adjustable_item_type: 'Reservation',
    };

    return Api.createAdjustment(props, booking.id, user.access_token)
      .then((response) => {
        // Legacy dispatch events
        AppDispatcher.trigger('price:update', response.booking_price_breakdown);
        return dispatch(adjustmentCreatedSuccess(response));
      })
      .catch((error) => {
        dispatch(adjustmentCreatedFailure(error.message));
        return dispatch(showError(error.message));
      });
  };
};

export { createAdjustment };
