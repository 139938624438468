import { createReducer } from '@reduxjs/toolkit';
import Config from 'config/config';
import localStore from 'utils/local_store';

import {
  selectTenantComplete,
  setTenants,
  loadAvailableTenantsSucceeded,
} from 'actions/redux/thunks/tenant_thunks';

import {
  logoutStarted,
  logoutSucceeded,
  logoutFailure,
} from 'actions/redux/thunks/login_thunks';

const initialState = () => {
  const state = { selected: null };
  const tenant = localStore.get('defaultTenant');
  if (tenant != null) {
    const config = Config.forTenant(tenant);
    if (config != null) {
      //setup initial state for non-redux components
      Config.updateConfigForTenant(config);
    }
  }
  return state;
};

const TenantReducer = createReducer(initialState(), {
  [selectTenantComplete]: (state, action) => {
    state.selected = action.payload;
  },
  [setTenants]: (state, action) => {
    state.entities = action.payload;
  },
  [loadAvailableTenantsSucceeded]: (state, action) => {
    state.available = action.payload;
  },
  [logoutStarted]: (state) => {
    state.selected = null;
  },
  [logoutSucceeded]: (state) => {
    state.selected = null;
  },
  [logoutFailure]: (state) => {
    state.selected = null;
  },
});

export default TenantReducer;
