// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from 'config/config';
import { selectAccessToken } from 'selectors/login_selectors';

// Define our single API slice object
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = selectAccessToken(getState());
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      const apiKey = Config.apiKey;
      if (apiKey) {
        headers.set('X-ORIGIN-KEY', apiKey);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdjustmentDefintions: builder.query({
      query: (adjustmentCategoryId) => {
        return `${Config.quicktravelUrl}/api/adjustment_definitions.json?adjustment_category_ids[]=${adjustmentCategoryId}&active=true`;
      },
    }),
  }),
});

export const { useGetAdjustmentDefintionsQuery } = apiSlice;
