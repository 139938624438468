import { useState } from 'react';
import PropTypes from 'prop-types';

const BookingListControls = ({
  currentPage,
  totalPages,
  onSearch,
  onNextPage,
  onPreviousPage,
  onClose,
  onShowRecentBookings,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const searchResultsDisplayed = searchTerm.length > 0;
  const pageButtonsDisplayed = totalPages > 1;

  const onChange = (event) => {
    const text = event.target.value;
    setSearchTerm(text);
    if (text.length > 2 || text.length == 0) {
      onSearch(text);
    }
  };

  const nextPage = () => {
    onNextPage();
  };

  const previousPage = () => {
    onPreviousPage();
  };

  const recentBookings = () => {
    setSearchTerm('');
    onShowRecentBookings();
  };

  const close = () => {
    onClose();
  };

  const prevButton = () => {
    if (currentPage > 1) {
      return (
        <button
          className="button dark small page-left"
          onClick={previousPage}
          aria-label="Previous"
        >
          <i className="icon-left-dir" />
        </button>
      );
    } else {
      return (
        <span className="button disabled small page-left">
          <i className="icon-left-dir" />
        </span>
      );
    }
  };

  const nextButton = () => {
    if (currentPage < totalPages) {
      return (
        <button
          aria-label="Next"
          className="button dark small page-right"
          onClick={nextPage}
        >
          <i className="icon-right-dir" />
        </button>
      );
    } else {
      return (
        <span className="button disabled small page-right">
          <i className="icon-right-dir" />
        </span>
      );
    }
  };

  const pageButtons = () => {
    if (!pageButtonsDisplayed) {
      return null;
    }

    return (
      <div className="grid-12 col section">
        <div className="text-right">
          <div className="inline-block booking-list-pagination">
            Showing {currentPage} of {totalPages}
          </div>
          {prevButton()}
          {nextButton()}
        </div>
      </div>
    );
  };

  const heading = (isSearchTab) => {
    if (searchResultsDisplayed && isSearchTab) {
      return 'grid-base tabs-li active';
    } else if (!searchResultsDisplayed && !isSearchTab) {
      return 'grid-base tabs-li active';
    } else {
      return 'grid-base tabs-li';
    }
  };

  return (
    <div className="grid-12">
      <ul className="row tabs row-between">
        <li className={heading(false)}>
          <div className="tabs-link" onClick={recentBookings}>
            Recent Bookings
          </div>
        </li>
        <li className={`grid-grow ${heading(true)}`}>
          <div className="tabs-link">Search Results</div>
        </li>
        <li className="grid-base tabs-li">
          <button className="tabs-link dark" aria-label="Close" onClick={close}>
            <i className="icon-cancel" />
          </button>
        </li>
      </ul>
      <div className="grid-12 col section booking-list-controls bgd-white">
        <input
          className="booking-search-input full"
          type="search"
          placeholder="Search (by name/full booking reference)"
          onChange={onChange}
          value={searchTerm}
        />
      </div>
      {pageButtons()}
    </div>
  );
};

BookingListControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  onShowRecentBookings: PropTypes.func,
  onSearch: PropTypes.func,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
};

BookingListControls.defaultProps = {
  onSearch: () => {},
  onNextPage: () => {},
  onPreviousPage: () => {},
};

export default BookingListControls;
