import { createReducer } from '@reduxjs/toolkit';
import {
  setIsScanning,
  unsetIsScanning,
  appendValue,
} from 'actions/redux/barcode_scanner_actions';

const initialState = {
  isScanning: false,
  working_value: '',
  value: null,
};

const barcodeScannerReducer = createReducer(initialState, {
  [setIsScanning]: (state) => {
    state.isScanning = true;
    state.value = null;
    state.working_value = '';
  },
  [unsetIsScanning]: (state) => {
    if (state.isScanning) {
      state.value = state.working_value;
      state.working_value = '';
      state.isScanning = false;
    }
  },
  [appendValue]: (state, action) => {
    if (state.isScanning) {
      state.working_value += action.payload;
    }
  },
});

export default barcodeScannerReducer;
