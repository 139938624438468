import AppDispatcher from 'app_dispatcher';
import { createAction } from '@reduxjs/toolkit';

const setPaymentReference = createAction('payment/reference/set');

const resetAmountEntered = () => () => {
  AppDispatcher.trigger('payment:resetAmountEntered');
  return Promise.resolve();
};

const appendDigitToAmountEntered = (digit) => () => {
  AppDispatcher.trigger('payment:appendDigitToAmountEntered', digit);
  return Promise.resolve();
};

const updatePaymentReference = (reference) => (dispatch) => {
  dispatch(setPaymentReference(reference));
  return Promise.resolve();
};

export {
  updatePaymentReference,
  setPaymentReference,
  resetAmountEntered,
  appendDigitToAmountEntered,
};
