import UnauthenticatedScreen from 'components/layouts/unauthenticated_screen';
import { TenantChooser } from 'components/login';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectSelectedTenant } from 'selectors/tenant_selectors';
import { Navigate } from 'react-router-dom';

const TenantScreen = () => {
  const loggedIn = useSelector((state) => selectAuthenticatedUser(state));
  const selectedTenant = useSelector((state) => selectSelectedTenant(state));

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  } else if (selectedTenant === undefined) {
    return (
      <UnauthenticatedScreen>
        <TenantChooser />
      </UnauthenticatedScreen>
    );
  } else {
    return <Navigate to="/bookings/new" replace />;
  }
};

export default TenantScreen;
