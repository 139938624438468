import { useState } from 'react';
import PropTypes from 'prop-types';

const SearchInput = ({ onSearch = () => {} }) => {
  const [term, setTerm] = useState('');
  const handleSearchInput = (e) => {
    setTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <input
      type="text"
      className="search-input full"
      placeholder="Filter"
      size="5"
      value={term}
      onChange={handleSearchInput}
    />
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchInput;
