import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { selectSelectedTenant } from './tenant_selectors';
import { selectPaymentTypes } from './booking_selectors';
import * as entities from './booking_entity_selectors';

const codeValue = (paymentType) => {
  switch (paymentType.code) {
    case 'cash':
      return 1;
    case 'mastercard_visa':
      return 2;
    case 'eftpos':
      return 3;
    default:
      return 4;
  }
};

const selectAvailablePaymentTypes = createSelector(
  [selectPaymentTypes, selectSelectedTenant],
  (paymentTypes, tenant) => {
    if (isNil(tenant)) {
      return [];
    }

    const allowedPaymentTypes = tenant.payments.allowedPaymentTypes;
    const sortMethod = tenant.payments.sortMethod;

    if (sortMethod === 'QT') {
      return paymentTypes.reduce((acc, paymentType) => {
        const match = allowedPaymentTypes.find((x) => x.id == paymentType.id);
        if (match != null) {
          acc.push({ ...paymentType, code: match.code });
        }
        return acc;
      }, []);
    } else {
      const values = allowedPaymentTypes.reduce((acc, paymentType) => {
        const match = paymentTypes.find((x) => x.id == paymentType.id);
        if (match != null) {
          acc.push({ ...match, code: paymentType.code });
        }
        return acc;
      }, []);

      if (sortMethod === 'CONFIG') {
        return values;
      } else {
        //default sort order
        return values.sort((a, b) => {
          return codeValue(a) - codeValue(b);
        });
      }
    }
  }
);

const selectSelectedPaymentType = createSelector(
  [selectAvailablePaymentTypes, entities.selectSelectedPaymentTypeId],
  (paymentTypes, selectedId) => {
    return (
      paymentTypes.find((paymentType) => paymentType.id === selectedId) ?? null
    );
  }
);

const selectDefaultPaymentType = createSelector(
  [entities.selectPaymentTypeEntities, selectSelectedTenant],
  (paymentTypes, tenant) => {
    return paymentTypes[tenant.payments.defaultPaymentTypeId];
  }
);

const selectOnAccountPaymentType = createSelector(
  [selectAvailablePaymentTypes],
  (paymentTypes) => {
    return paymentTypes.find((paymentType) => {
      const payment_method = paymentType.payment_method;
      return (
        payment_method === 'on_account_without_reference' ||
        payment_method === 'on_account_with_reference'
      );
    });
  }
);

export {
  selectAvailablePaymentTypes,
  selectSelectedPaymentType,
  selectDefaultPaymentType,
  selectOnAccountPaymentType,
};
