import { forwardRef } from 'react';
import Select from 'react-select';

const TouchSelect = forwardRef(({ ...props }, ref) => {
  const style = {
    control: (base) => ({
      ...base,
      minHeight: 50,
      border: 0,
      boxShadow: 'none', // This line disable the blue border
    }),
  };

  return <Select {...props} ref={ref} styles={style} />;
});
TouchSelect.displayName = 'TouchSelect';

export default TouchSelect;
