import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentReference } from 'actions/redux/payment_actions';
import PaymentReference from 'components/payment/payment_reference';
import { selectBooking } from 'selectors/booking_selectors';
import { selectPaymentReference } from 'selectors/payment_selectors';
import { selectOpenSection } from 'selectors/ui_selectors';
import { selectIsReferenceRequired } from 'selectors/payment_selectors';

const ConnectedPaymentReference = () => {
  const dispatch = useDispatch();
  const booking = useSelector(selectBooking);
  const reference = useSelector(selectPaymentReference);
  const isReferenceRequired = useSelector(selectIsReferenceRequired);
  const section = useSelector(selectOpenSection);
  const isOpen = section === 'PaymentScreen';

  if (!booking) {
    return null;
  }

  return (
    <PaymentReference
      reference={reference}
      visible={isReferenceRequired && isOpen}
      externalIdentifier={booking.external_identifier}
      onPaymentReferenceChange={(newReference) => {
        dispatch(updatePaymentReference(newReference));
      }}
    />
  );
};

export default ConnectedPaymentReference;
