const sealink_nt = {
  id: 'sealink_nt',
  tenantId: 'sealink_qld',
  name: 'SeaLink NT',
  quicktravelUrl: 'https://qld.quicktravel.com.au',
  productTypeIDs: [30], // 30=NT Ferry & Bus
  adjustmentCategoryId: 13, // ID of category of adjustments to show
  payments: {
    allowedPaymentTypes: [
      { id: 1, code: 'cash' },
      { id: 6, code: 'eftpos' },
      { id: 7, code: 'cheque' },
      { id: 9, code: 'amex' },
      { id: 13, code: 'on_account' },
      { id: 19, code: 'mastercard_visa' },
    ],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: true,
    promotedCountries: [14, 74, 81, 232, 158],
    requiringPostcode: [14],
  },
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 7,
  },
  receipts: {
    autoPrint: false,
    printType: 'quickets',
  },
  apiKey: '3a1cd2eb5e8eb0db1bd114324ca9b79276a7913f5215b86d354fa3cadc086db4',
};

const sealink_qld = {
  id: 'sealink_qld',
  name: 'SeaLink NQ',
  quicktravelUrl: 'https://qld.quicktravel.com.au',
  productTypeIDs: [26, 27, 16, 29], // 26=magnetic, 27=palm, 16=merchandise, 29=car parking
  adjustmentCategoryId: 1, // ID of category of adjustments to show
  payments: {
    allowedPaymentTypes: [
      { id: 1, code: 'cash' },
      { id: 5, code: 'tcc_voucher' },
      { id: 6, code: 'eftpos' },
      { id: 7, code: 'cheque' },
      { id: 9, code: 'amex' },
      { id: 13, code: 'on_account' },
      { id: 19, code: 'mastercard_visa' },
    ],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: true,
    promotedCountries: [14, 74, 81, 232, 158],
    requiringPostcode: [14],
  },
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 7,
  },
  receipts: {
    autoPrint: false,
    printType: 'quickets',
  },
  apiKey: '3a1cd2eb5e8eb0db1bd114324ca9b79276a7913f5215b86d354fa3cadc086db4',
};

const captain_cook = {
  id: 'captain_cook',
  name: 'Captain Cook NSW',
  quicktravelUrl: 'https://captaincook.quicktravel.com.au',
  productTypeIDs: [10, 17, 14],
  payments: {
    allowedPaymentTypes: [
      { id: 1, code: 'cash' },
      { id: 4, code: 'on_account' },
      { id: 8, code: 'amex' },
      { id: 11, code: 'mastercard_visa' },
      { id: 13, code: 'on_account' },
      { id: 17, code: 'diners' },
      { id: 18, code: 'jcb' },
      { id: 20, code: 'eftpos' },
    ],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: true,
    promotedCountries: [14],
    requiringPostcode: [14],
  },
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 3,
  },
  receipts: {
    autoPrint: false,
    printType: 'quickets',
  },
  apiKey: 'cfabbee3a270671745cbd8586938e8f688fcd0eb8af884aac9d0d655f10d674b',
};

const stradbroke = {
  id: 'stradbroke',
  name: 'SeaLink SEQ',
  quicktravelUrl: 'https://stradbroke.quicktravel.com.au',
  productTypeIDs: [5, 8, 15, 16],
  payments: {
    allowedPaymentTypes: [
      { id: 1, code: 'cash' },
      { id: 3, code: 'on_account' },
      { id: 6, code: 'on_account_ref' },
      { id: 8, code: 'mastercard_visa' },
      { id: 26, code: 'eftpos_debit' },
      { id: 12, code: 'eftpos_mastercard' },
      { id: 13, code: 'eftpos_visa' },
      { id: 10, code: 'eftpos_amex' },
    ],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: true,
    promotedCountries: [14],
    requiringPostcode: [14],
  },
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 5,
  },
  receipts: {
    autoPrint: false,
    printType: 'quickets',
  },
  apiKey: '1756be5b1efd7f4263cd5bfb436657727e66f327bf2fdfd5800c6ef041f44d95',
};

const bruny = {
  id: 'bruny',
  name: 'SeaLink Bruny Island',
  quicktravelUrl: 'https://bruny.quicktravel.com.au',
  productTypeIDs: [3],
  payments: {
    allowedPaymentTypes: [
      { id: 1, code: 'cash' },
      { id: 3, code: 'on_account' },
      { id: 4, code: 'on_account_ref' },
      { id: 7, code: 'cheque' },
      { id: 24, code: 'eftpos_mastercard_visa' },
    ],
    defaultPaymentTypeId: 25,
  },
  adjustmentCategoryId: 7,
  categoryColourConfig: {
    2: {
      activeBackground: 'darkblue',
      activeFont: 'white',
      background: 'darkblue',
      font: 'white',
    },
    3: {
      activeBackground: 'lightblue',
      activeFont: 'black',
      background: 'lightblue',
      font: 'black',
    },
    4: {
      activeBackground: '#3D8D3D',
      activeFont: 'black',
      background: '#3D8D3D',
      font: 'black',
    },
    5: {
      activeBackground: 'yellow',
      activeFont: 'black',
      background: 'yellow',
      font: 'black',
    },
  },
  autoPromoCode: {
    mode: 'seasonality',
    seasonalities: {
      departureMode: 'next',
      departure: {
        route_id: 1,
        from_stop_id: 1,
      },
      mapping: {
        1: { id: 4, code: 'LIGHTFARES (SUPERSAVER)' },
        2: { id: 13, code: 'SAVER' },
      },
    },
  },
  countries: {
    required: false,
    promotedCountries: [14],
    requiringPostcode: [14],
  },
  autoValidateTicket: true,
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 8,
  },
  receipts: {
    autoPrint: true,
    printType: 'albert',
  },
  apiKey: '4b1c7112e93b9226c8bf1a8a6848172530fd65ae99ab77c8e65c0cbc4fe83d97',
};

const cc_wa = {
  id: 'captain_cook_wa',
  name: 'SeaLink WA',
  quicktravelUrl: 'https://captaincookwa.quicktravel.com.au',
  productTypeIDs: [23, 24, 28],
  payments: {
    allowedPaymentTypes: [{ id: 1, code: 'cash' }],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: false,
    promotedCountries: [14],
    requiringPostcode: [14],
  },
  categoryColourConfig: {
    2: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'darkblue',
      font: 'white',
    },
    3: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'lightblue',
      font: 'black',
    },
    4: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'green',
      font: 'black',
    },
    5: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'yellow',
      font: 'black',
    },
  },
  adjustmentCategoryId: 16,
  autoPromoCodes: [],
  autoValidateTicket: false,
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 4,
  },
  receipts: {
    autoPrint: true,
    printType: 'quickets',
  },
  apiKey: '5b561efd2253b9ad4132662859e8b84ff605660872b992e69fd97eca8f79d4ee',
};

const sealink_sa = {
  id: 'sealink',
  name: 'SeaLink SA',
  quicktravelUrl: 'https://sealink.quicktravel.com.au',
  productTypeIDs: [554881950, 554881979],
  payments: {
    allowedPaymentTypes: [{ id: 1, code: 'cash' }],
    defaultPaymentTypeId: 1,
  },
  countries: {
    required: false,
    promotedCountries: [14],
    requiringPostcode: [14],
  },
  categoryColourConfig: {
    2: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'darkblue',
      font: 'white',
    },
    3: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'lightblue',
      font: 'black',
    },
    4: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'green',
      font: 'black',
    },
    5: {
      activeBackground: 'white',
      activeFont: 'black',
      background: 'yellow',
      font: 'black',
    },
  },
  adjustmentCategoryId: 750275996,
  autoPromoCodes: [],
  autoValidateTicket: false,
  printing: {
    host: 'https://printers.quicktravel.com.au',
    catalogueId: 2,
  },
  receipts: {
    autoPrint: false,
    printType: 'quickets',
  },
  apiKey: 'ed2841a9ce9ab3730febe36d039335621de0a73c2b359e13e9a7b3d3092a99ff',
};

export default {
  tenants: [
    sealink_nt,
    sealink_qld,
    captain_cook,
    stradbroke,
    bruny,
    cc_wa,
    sealink_sa,
  ],
  printingEnabled: true,
  debugEvents: false,
  rollbar: {
    accessToken: '244053c716364af1a39217cb3cafae96',
    enabled: true,
    environment: 'production',
  },
  datadog: {
    applicationId: 'ac90443c-2836-4ed2-8a7e-9a0dad22c441',
    clientToken: 'pubfaeb9fcfacb936e85c3ef987ef0b5411',
    environment: 'prod',
    service: 'quicktickets-web-prod',
  },
  authServiceURL: 'https://auth.quicktravel.com.au',
  gitSha: null,
  showEnv: false,
  environment: 'production',
  guestlinkUrl: 'https://guestlink.quicktravel.com.au',
  oktaIssuer: 'https://kelsian.okta.com/oauth2/aus2r6kxqkqLBkb5w697',
  oktaClientId: '0oa4d14jxr1flVA2j697',
  oktaAppBase: 'https://quicktickets-web.quicktravel.com.au',
  oktaEnabled: true,
  googleTagManagerId: 'G-HHRP10DTK2',
};
