import Backbone from 'backbone';
import Booking from 'models/booking';
import Config from 'config/config';

const Bookings = Backbone.Collection.extend({
  model: Booking,
  url: Config.routes.bookings,
  headers: { 'X-ORIGIN-KEY': Config.apiKey },
});

export default new Bookings();
