import Config from 'config/config';
import { checkStatus } from 'utils/fetch_utils';

const availableTenants = (access_token) => {
  const requestStartTime = new Date().getTime();
  const meRoute =
    Config.forTenant('captain_cook').quicktravelUrl + '/api/me/tenants';

  return fetch(meRoute, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'X-ORIGIN-KEY': Config.apiKey,
    },
  })
    .then((response) => checkStatus(response, requestStartTime))
    .then((response) => response.json());
};

export default { availableTenants };
