import LoginStatus from 'utils/login_status';
import * as ProductSelectionActions from 'actions/redux/product_selection_actions';
import * as AdjustmentSelectionActions from 'actions/redux/thunks/adjustment_thunks';
import * as PaymentTypeActions from 'actions/redux/thunks/payment_type_thunks';
import store from 'store';
import Config from 'config/config';
import { setTenants } from 'actions/redux/thunks/tenant_thunks';

const dataInitializer = {
  load() {
    store.dispatch(setTenants(Config.tenants));

    if (!LoginStatus.loggedIn()) {
      return;
    }

    store.dispatch(PaymentTypeActions.load());
    store.dispatch(ProductSelectionActions.load());
    store.dispatch(AdjustmentSelectionActions.load());
  },

  unload() {
    store.dispatch(PaymentTypeActions.unload());
    store.dispatch(ProductSelectionActions.unload());
    store.dispatch(AdjustmentSelectionActions.unload());
  },
};

export default dataInitializer;
