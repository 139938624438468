import { createSelector } from '@reduxjs/toolkit';
import { selectBooking } from 'selectors/booking_selectors';

const selectBookingComments = createSelector([selectBooking], (booking) => {
  if (booking == null) {
    return [];
  }

  return booking?.comments ?? [];
});

const selectCompanyComments = createSelector(
  [selectBookingComments],
  (comments) => {
    return comments.find((comment) => {
      return comment.comment_type == 'company';
    });
  }
);

const selectInternalComments = createSelector(
  [selectBookingComments],
  (comments) => {
    return comments.find((comment) => {
      return comment.comment_type == 'internal';
    });
  }
);

const selectExternalIdentifier = createSelector([selectBooking], (booking) => {
  return booking == null ? '' : booking.external_identifier;
});

export {
  selectCompanyComments,
  selectInternalComments,
  selectExternalIdentifier,
};
