import axios from 'axios';
import Config from 'config/config';
import store from 'store';
import * as NoticeActions from 'actions/redux/notice_actions';
import * as ServerResponses from 'actions/redux/server_responses';

const create = function (data) {
  return axios
    .post(Config.routes.checkouts(), data)
    .then((response) => {
      if (response.data.requires_poll) {
        store.dispatch(
          NoticeActions.showError(
            'Polling payment types are not supported!',
            'reload'
          )
        );
      } else {
        store.dispatch(ServerResponses.checkoutSuccess(response.data, data));
      }
    })
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        store.dispatch(
          ServerResponses.checkoutFailure(error.response.data.error)
        );
      } else if (error.request) {
        // client never received a response, or request never left
        store.dispatch(
          ServerResponses.checkoutFailure('Failed to receieve response')
        );
      } else {
        store.dispatch(ServerResponses.checkoutFailure(error.message));
      }

      return Promise.reject(error);
    });
};

export default create;
