import { createReducer } from '@reduxjs/toolkit';
import { resetEntities } from 'actions/redux/entity_actions';
import { hideTicket } from 'actions/redux/ticket_actions';
import {
  setRegistration,
  clearRegistration,
} from 'actions/redux/vehicle_actions';

const INITIAL_STATE = { numberPlate: '' };

const VehicleReducer = createReducer(INITIAL_STATE, {
  [setRegistration]: (state, action) => {
    state.numberPlate = action.payload.numberPlate;
  },
  [clearRegistration]: () => {
    return INITIAL_STATE;
  },
  [resetEntities]: () => {
    return INITIAL_STATE;
  },
  [hideTicket]: () => {
    return INITIAL_STATE;
  },
  'booking:new': () => {
    return INITIAL_STATE;
  },
  'booking:unassignClient': () => {
    return INITIAL_STATE;
  },
});

export default VehicleReducer;
