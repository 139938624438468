import { createAction } from '@reduxjs/toolkit';
import AppDispatcher from 'app_dispatcher';
import { isEmpty } from 'lodash';
import { formatISO } from 'date-fns';
import { selectReservationToAddQuantity } from 'selectors/reservation_to_add_selectors';

const setDate = createAction('reservation_to_add/date/set');
const setPassengerTypeQuantity = createAction('reservation_to_add/pax_qty/set');
const setCurrentQuantity = createAction('reservation_to_add/quantity/set');
const setCurrentResource = createAction('reservation_to_add/resource/set');
const resetSelectedResource = createAction('reservation_to_add/resource/reset');
const setInitialState = createAction('reservation_to_add/reset_all');
const setSelectedExtraPickIds = createAction(
  'reservation_to_add/extra_pick_ids/set'
);

const changeDate = (newDate) => (dispatch) => {
  const dateStr = formatISO(newDate, { representation: 'date' });

  dispatch(setDate(dateStr));
  return Promise.resolve();
};

const changePassengerTypeNumbers =
  (passengerTypeId) => (dispatch, getState) => {
    const quantity = selectReservationToAddQuantity(getState());
    dispatch(setPassengerTypeQuantity({ passengerTypeId, quantity }));
    dispatch(setCurrentQuantity(1));
    return Promise.resolve();
  };

const changeQuantity = (quantity) => (dispatch) => {
  dispatch(setCurrentQuantity(quantity));
};

const resetAll = () => (dispatch) => {
  dispatch(setInitialState());
};

const addWithExtraPicks = (extraPickIds) => (dispatch) => {
  dispatch(setSelectedExtraPickIds(extraPickIds));
  AppDispatcher.trigger('reservation:addSelected');
};

const selectResource = (resource) => (dispatch) => {
  //Dispatch for Selected Product Store
  AppDispatcher.trigger('reservation:selectResource', resource);

  // Resource is the Backbone JS model at this point.
  const currentResource = {
    id: resource.id,
    name: resource.get('name'),
    inlinePriceInCents: resource.get('todays_price_in_cents'),
    childResources: resource.get('child_resources'),
  };

  dispatch(setCurrentResource(currentResource));

  if (isEmpty(currentResource.childResources)) {
    AppDispatcher.trigger('reservation:addSelected');
  }
};

export {
  setDate,
  setCurrentResource,
  setCurrentQuantity,
  setPassengerTypeQuantity,
  setSelectedExtraPickIds,
  changeDate,
  changePassengerTypeNumbers,
  changeQuantity,
  setInitialState,
  resetAll,
  addWithExtraPicks,
  selectResource,
  resetSelectedResource,
};
