import useVersionCheck from 'utils/use_version_check';

const AppVersion = () => {
  const [version, updateAvailable] = useVersionCheck();

  const renderAppUpdate = () => {
    if (updateAvailable) {
      return (
        <span>
          <i className="icon-alert version-update" />
          <br /> Version {version} available
          <br /> Refresh to update
        </span>
      );
    } else {
      return `Version: ${version}`;
    }
  };

  return <div className="booking-info version-text">{renderAppUpdate()}</div>;
};

export default AppVersion;
