import UnauthenticatedScreen from 'components/layouts/unauthenticated_screen';
import { LoginForm } from 'components/login';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'selectors/login_selectors';

const LoginScreen = () => {
  const loggedIn = useSelector((state) => selectAuthenticatedUser(state));

  const renderTenantChooser = () => {
    return <Navigate to="/tenants" replace={true} />;
  };

  if (loggedIn) {
    return renderTenantChooser();
  } else {
    return (
      <UnauthenticatedScreen>
        <LoginForm />
      </UnauthenticatedScreen>
    );
  }
};

export default LoginScreen;
