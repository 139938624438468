import BookingCommentBtn from 'views/booking/booking_comment_btn';
import CompleteBookingButton from 'views/booking/complete_booking_button';
import PaymentScreen from 'views/payment/payment_screen';
import { BookingTotal } from 'components/booking';
import ProductListScreen from 'views/products/product_list_screen';
import Summary from 'views/reservations/summary';
import ClientScreen from 'views/client/client_screen';
import PropTypes from 'prop-types';
import CommentModal from 'components/modals/comment_modal';
import TicketModal from 'components/modals/ticket_modal';
import BarcodeScanner from 'components/barcode_scanner';

const SingleBookingScreen = ({ booking }) => {
  const bookingTotalProps = () => {
    if (booking === null) {
      return {
        loading: true,
      };
    }

    return {
      totalInCents: booking.total().cents,
      surchargeInCents: booking.surcharge().cents,
      commissionInCents: booking.commission().cents,
      paidInCents: booking.paid().cents,
    };
  };

  return (
    <div className="grid-12 grid-medium-11 row row-center" tabIndex="0">
      <BarcodeScanner />
      <div className="grid-12 grid-medium-8 content-main">
        <ProductListScreen />
        <PaymentScreen booking={booking} />
        <ClientScreen />
        <TicketModal />
        <CommentModal />
      </div>
      <div className="grid-12 grid-medium-4 row row-column content-side">
        <Summary booking={booking} />
        <div>
          <BookingTotal {...bookingTotalProps()} />
          <BookingCommentBtn />
          <CompleteBookingButton />
        </div>
      </div>
    </div>
  );
};

SingleBookingScreen.displayName = 'SingleBookingScreen';
SingleBookingScreen.propTypes = {
  booking: PropTypes.object,
};

export default SingleBookingScreen;
