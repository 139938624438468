import { createAction } from '@reduxjs/toolkit';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { passengerTypes } from 'api/consumer_type_api';
import { normalizePassengerTypes } from 'actions/redux/normalizer_actions';
import AppDispatcher from 'app_dispatcher';

const fetchPassengerTypesStarted = createAction(
  'passenger_types/fetch/started'
);
const fetchPassengerTypesSucceeded = createAction(
  'passenger_types/fetch/succeeded'
);
const fetchPassengerTypesFailed = createAction('passenger_types/fetch/failure');

const setConsumerType = createAction('consumer_type/entity/set');

const fetchPassengerTypes = () => (dispatch, getState) => {
  AppDispatcher.trigger('passengerType:load');
  dispatch(fetchPassengerTypesStarted());

  const user = selectAuthenticatedUser(getState());
  return passengerTypes(user?.access_token)
    .then((passengerTypes) => {
      dispatch(normalizePassengerTypes(passengerTypes));
      dispatch(fetchPassengerTypesSucceeded(passengerTypes));
    })
    .catch((error) => dispatch(fetchPassengerTypesFailed(error.message)));
};

export {
  fetchPassengerTypes,
  fetchPassengerTypesStarted,
  fetchPassengerTypesSucceeded,
  fetchPassengerTypesFailed,
  setConsumerType,
};
