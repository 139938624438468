import { createSelector } from '@reduxjs/toolkit';
import { parseISO, format } from 'date-fns';
import { get, isEmpty } from 'lodash';
import { selectPassengerTypes } from './consumer_type_selectors';

const selectReservationToAddDate = (state) => {
  const date = get(state, 'reservationToAdd.date');
  if (!date) {
    return new Date();
  }

  return parseISO(date);
};

const selectReservationToAddQuantity = (state) => {
  return get(state, 'reservationToAdd.quantity') || 1;
};

const selectQuantities = (state) => {
  return get(state, 'reservationToAdd.passengerTypeNumbers') || {};
};

const selectSelectedResource = (state) => {
  return get(state, 'reservationToAdd.selectedResource') || null;
};

const selectSelectedExtraPickIds = (state) => {
  return get(state, 'reservationToAdd.selectedExtraPickIds') || [];
};

const selectPassengerQuantities = createSelector(
  [selectPassengerTypes, selectQuantities],
  (passengerTypes, quantities) => {
    if (isEmpty(passengerTypes)) {
      return [];
    }

    return Object.values(passengerTypes).map((passengerType) => {
      return {
        id: passengerType.id,
        fullName: passengerType.name,
        shortName: passengerType.name.replace(/ /gi, '').substr(0, 5),
        quantity: quantities[passengerType.id] || 0,
      };
    });
  }
);

const hasNoPassengerTypesSelected = createSelector(
  [selectPassengerQuantities],
  (quantities) => {
    return quantities.every((o) => o.quantity === 0);
  }
);

const selectReservationToAdd = createSelector(
  [
    selectReservationToAddDate,
    selectReservationToAddQuantity,
    selectSelectedResource,
    selectPassengerQuantities,
    selectSelectedExtraPickIds,
  ],
  (date, quantity, resource, passengerQuantities, extraPickIds) => {
    const paxTypeNumbers = Object.values(passengerQuantities).reduce(
      (accumulater, currentValue) => {
        accumulater[currentValue.id] = currentValue.quantity;
        return accumulater;
      },
      {}
    );

    return {
      resource_id: resource.id,
      name: resource.name,
      inline_price_in_cents: resource.inlinePriceInCents,
      extra_pick_ids: extraPickIds,
      quantity: quantity,
      first_travel_date: format(date, 'd/M/y'),
      passenger_type_numbers: paxTypeNumbers,
    };
  }
);

export {
  selectSelectedResource,
  selectReservationToAddDate,
  selectPassengerQuantities,
  selectReservationToAddQuantity,
  hasNoPassengerTypesSelected,
  selectReservationToAdd,
};
