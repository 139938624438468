const ENV = process.env.APP_ENV
  ? require(`config/${process.env.APP_ENV}/env`).default
  : require('config/env').default;

import _ from 'underscore';
import ConfigKeys from 'config/config_keys';
let Config = new Object();

const configure = (values, schema) =>
  _.mapObject(schema, (value, key) =>
    values[key] != null ? values[key] : (values[key] = value.default)
  );
Config = configure(ENV, ConfigKeys);

for (let tenant in Config.tenants) {
  const values = Config.tenants[tenant];
  Config.tenants[tenant] = configure(values, ConfigKeys.tenants.children);
}

Config.forTenant = function (tenantKey) {
  return Config.tenants.find((tenant) => tenant.id === tenantKey);
};

Config.tenantHasMultipleConfigs = function (tenantKey) {
  return (
    Config.tenants.filter(
      (tenant) => tenant.id === tenantKey || tenant.tenantId === tenantKey
    ).length > 1
  );
};

Config.updateConfigForTenant = function (tenantConfig) {
  // TODO dry up code by looping over tenantConfig attributes instead of repeating each one here
  this.tenantId = tenantConfig.tenantId || tenantConfig.id;
  this.quicktravelUrl = tenantConfig.quicktravelUrl;
  this.productTypeIDs = tenantConfig.productTypeIDs;
  this.payments = tenantConfig.payments;
  this.adjustmentCategoryId = tenantConfig.adjustmentCategoryId;
  this.apiKey = tenantConfig.apiKey;
  // TODO Fetch these country settings from QT
  this.countries = tenantConfig.countries || {};
  this.printing = tenantConfig.printing;
  this.categoryColourConfig = tenantConfig.categoryColourConfig || {};
  this.autoPromoCode = tenantConfig.autoPromoCode || {};
  this.receipts = tenantConfig.receipts;
  this.tickets = tenantConfig.tickets;
  // TODO Fetch the timeZone setting from QT
  this.timeZone = tenantConfig.timeZone;
  this.eftposUrl = tenantConfig.eftposUrl;
  this.autoValidateTicket = tenantConfig.autoValidateTicket;
  this.currentDeparture = tenantConfig.currentDeparture;
  this.scanningOptions = tenantConfig.scanningOptions;
  return tenantConfig;
};

Config.routes = {
  clientSearch() {
    return Config.quicktravelUrl + '/api/parties/clients.json';
  },

  clientBarcodeSearch() {
    return (
      Config.quicktravelUrl +
      '/api/clients/vehicle_external_identifiers/exact_match.json'
    );
  },

  clientTemplatesSearch(client_id) {
    return Config.quicktravelUrl + `/api/clients/${client_id}/templates.json`;
  },

  resourceCategories() {
    const params = $.param({ product_type_ids: Config.productTypeIDs });
    return Config.quicktravelUrl + '/api/resource_categories.json?' + params;
  },

  resources() {
    const params = $.param({ product_type_ids: Config.productTypeIDs });
    return (
      Config.quicktravelUrl + '/api/resources/index_with_price.json?' + params
    );
  },

  passengerTypes() {
    return Config.quicktravelUrl + '/api/passenger_types.json';
  },

  productTypes() {
    const params = $.param({ ids: Config.productTypeIDs });
    return Config.quicktravelUrl + '/api/product_types.json?' + params;
  },

  adjustmentDefinitions() {
    return Config.quicktravelUrl + '/api/adjustment_definitions.json';
  },

  paymentTypes() {
    const params = $.param({ ids: _.keys(Config.paymentTypes) });
    return Config.quicktravelUrl + '/api/payment_types.json?' + params;
  },

  login() {
    return Config.quicktravelUrl + '/api/login.json';
  },

  logout() {
    return Config.quicktravelUrl + '/api/logout';
  },

  bookings() {
    return Config.quicktravelUrl + '/api/bookings';
  },

  checkouts() {
    return Config.quicktravelUrl + '/api/checkouts';
  },

  tills() {
    return Config.quicktravelUrl + '/api/tills.json';
  },

  search() {
    return Config.quicktravelUrl + '/api/booking_search/search.json';
  },

  promoCodes() {
    return Config.quicktravelUrl + '/api/promo_codes.json';
  },

  countries() {
    return Config.quicktravelUrl + '/api/countries.json';
  },

  services() {
    return Config.quicktravelUrl + '/api/services';
  },
};

export default Config;
