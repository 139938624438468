import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectReservationToAddQuantity } from 'selectors/reservation_to_add_selectors';
import AdjustmentList from 'features/products/adjustment_list';
import QuantitySection from 'components/product/quantity_section';
import PassengerSelection from 'views/products/passenger_selection';
import SearchInput from 'components/product/search_input';
import ProductList from 'views/products/product_list';
import ProductStore from 'stores/product_store';
import * as ProductSelectionActions from 'actions/redux/product_selection_actions';
import Loading from 'views/page/loading';
import CategoryColour from 'utils/category_colour';
import Config from 'config/config';
import { useGetAdjustmentDefintionsQuery } from 'features/api/api_slice';
import {
  changeQuantity,
  resetAll,
} from 'actions/redux/reservation_to_add_actions';
import { setQuantityModalVisibility } from 'actions/redux/ui_actions';

import classNames from 'classnames';

const LAYOUT_PAX_INCLUDED = {
  QUANTITY: 1,
  PASSENGER: 2,
  PRODUCT: 7,
  ADJUSTMENT: 2,
};

const LAYOUT_PAX_NOT_INCLUDED = {
  QUANTITY: 1,
  PASSENGER: 0,
  PRODUCT: 8,
  ADJUSTMENT: 3,
};

const ProductSelectionTabs = ({
  selectedProductTypeCategory,
  noPassengersSelected,
  productType,
}) => {
  const { data: adjustmentDefinitions } = useGetAdjustmentDefintionsQuery(
    Config.adjustmentCategoryId,
    { skip: Config.adjustmentCategoryId === undefined }
  );

  const dispatch = useDispatch();
  const quantity = useSelector((state) =>
    selectReservationToAddQuantity(state)
  );

  const onTabChange = (category, e) => {
    e.preventDefault();
    dispatch(ProductSelectionActions.selectProductTypeCategory(category));
  };

  const isActiveTab = (category) => {
    if (ProductStore.isFiltering()) {
      return false;
    }
    if (selectedProductTypeCategory.id === category.id) {
      return 'active';
    } else {
      return '';
    }
  };

  const getLayout = () => {
    if (ProductStore.passengersRequired()) {
      return LAYOUT_PAX_INCLUDED;
    } else {
      return LAYOUT_PAX_NOT_INCLUDED;
    }
  };

  const productTypesAndCategoriesLoaded = () => {
    return ProductStore.hasCategories() && ProductStore.hasProductTypes();
  };

  const catName = (category) => {
    return category.get('name').replace(/ /gi, '').substr(0, 4);
  };

  const searchButton = () => {
    return (
      <li className="tabs-li grid-grow">
        <SearchInput
          key={`${selectedProductTypeCategory.id}-product-search`}
          onSearch={(searchTerm) => {
            dispatch(ProductSelectionActions.searchProducts(searchTerm));
          }}
        />
      </li>
    );
  };

  const categoryTabs = (category, index) => {
    return (
      <li
        className={`tabs-li grid-base ${isActiveTab(category)}`}
        key={`categoryNav-${index}`}
      >
        <a
          href="#"
          ref={(el) =>
            CategoryColour.set(el, isActiveTab(category), category.id)
          }
          onClick={(e) => {
            onTabChange(category, e);
          }}
          className="tabs-link"
          title={category.get('name')}
        >
          {catName(category)}
        </a>
      </li>
    );
  };

  const renderProductList = () => {
    if (!ProductStore.hasResources()) {
      return <Loading text="Resources" />;
    }
    return <ProductList disabled={noPassengersSelected} />;
  };

  const renderPassengers = (layout) => {
    if (!ProductStore.passengersRequired()) {
      return <span />;
    }
    const containerClass = classNames(
      { [`grid-${layout.PASSENGER}`]: true },
      'row',
      'row-column'
    );

    return (
      <div className={containerClass}>
        <PassengerSelection />
      </div>
    );
  };

  const renderAdjustments = (layout) => {
    if (isEmpty(adjustmentDefinitions)) {
      return <span />;
    }

    const containerClass = classNames(
      { [`grid-${layout.ADJUSTMENT}`]: true },
      'row',
      'row-column',
      'adjustment-list'
    );

    return (
      <div className={containerClass}>
        <AdjustmentList />
      </div>
    );
  };

  if (!productTypesAndCategoriesLoaded()) {
    return <Loading text="Categories" />;
  }

  const categoryTabsView = productType
    .categories()
    .map((category, index) => categoryTabs(category, index));

  const layout = getLayout();

  const quantityContainerClass = classNames(
    {
      [`grid-${layout.QUANTITY}`]: true,
    },
    'row',
    'row-column'
  );

  const productContainerClass = classNames(
    { [`grid-${layout.PRODUCT}`]: true },
    'grid-grow'
  );

  return (
    <div className="catTabs row">
      <ul className="row tabs js-nav-tabs">
        {categoryTabsView}
        {searchButton()}
      </ul>
      <div className={quantityContainerClass}>
        <QuantitySection
          quantity={quantity}
          onUpdateQuantity={(quantity) => {
            dispatch(changeQuantity(quantity));
          }}
          onResetQuantity={() => {
            dispatch(resetAll());
          }}
          onOpenNumberPad={() => {
            dispatch(setQuantityModalVisibility(true));
          }}
        />
      </div>
      {renderPassengers(layout)}
      <div className={productContainerClass}>
        <div className="grid-12 tab-content products-content active">
          {renderProductList()}
        </div>
      </div>
      {renderAdjustments(layout)}
    </div>
  );
};

ProductSelectionTabs.propTypes = {
  selectedProductTypeCategory: PropTypes.object,
  noPassengersSelected: PropTypes.bool,
  productType: PropTypes.object,
};

export default ProductSelectionTabs;
