import { createSelector } from '@reduxjs/toolkit';

export const selectClientHistory = (state) => state?.clients?.history || [];

const selectClientSearchResults = (state) =>
  state?.clients?.searchResults || {};

export const selectClientList = createSelector(
  [selectClientSearchResults],
  (result) => {
    const searchClients = result?.clients || [];
    return searchClients.map((client) => {
      return {
        id: client.id,
        name: client.label,
        type: client.type,
        email: client.email,
        phone: client.phone,
        paymentMethod: client.payment_method_name,
        notes: client.notes,
      };
    });
  }
);

export const selectSearchResultTotalCount = (state) => {
  return selectClientSearchResults(state)?.results || 0;
};
