import { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useDetectFocus } from 'utils/use_detect_focus';
import PropTypes from 'prop-types';

const CustomerDetails = ({
  details,
  onChangeField = () => {},
  onSubmit = () => {},
}) => {
  const [customerDetails, setCustomerDetails] = useState({});

  const ref = useRef(null);
  const isFocused = useDetectFocus(ref);

  useEffect(() => {
    if (!isFocused && !isEmpty(customerDetails)) {
      onSubmit(customerDetails);
      setCustomerDetails({});
    }
  }, [isFocused, customerDetails]);

  const handleFieldChange = (fieldName, value) => {
    setCustomerDetails({
      ...customerDetails,
      [fieldName]: value,
    });
    onChangeField(fieldName, value);
  };

  const customerContactEmail = details?.customerContactEmail;
  const customerContactName = details?.customerContactName;
  const customerContactMobile = details?.customerContactMobile;
  const customerContactPhone = details?.customerContactPhone;

  return (
    <div ref={ref} className="row bgd-white customer-details">
      <div className="grid-6 col">
        <input
          className="full customer-input"
          type="text"
          placeholder="Customer Contact Name"
          value={customerContactName}
          onChange={(e) => {
            handleFieldChange('customer_contact_name', e.target.value);
          }}
        />
      </div>
      <div className="grid-6 col">
        <input
          className="full customer-input"
          type="text"
          placeholder="Customer Contact Phone"
          onChange={(e) => {
            handleFieldChange('customer_contact_phone', e.target.value);
          }}
          value={customerContactPhone}
        />
      </div>
      <div className="grid-6 col">
        <input
          className="full customer-input"
          type="text"
          placeholder="Customer Contact Mobile"
          onChange={(e) => {
            handleFieldChange('customer_contact_mobile', e.target.value);
          }}
          value={customerContactMobile}
        />
      </div>
      <div className="grid-6 col">
        <input
          className="full customer-input"
          type="text"
          placeholder="Customer Contact Email"
          onChange={(e) => {
            handleFieldChange('customer_contact_email', e.target.value);
          }}
          value={customerContactEmail}
        />
      </div>
    </div>
  );
};
CustomerDetails.propTypes = {
  details: PropTypes.shape({
    customerContactEmail: PropTypes.string,
    customerContactMobile: PropTypes.string,
    customerContactPhone: PropTypes.string,
    customerContactName: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onChangeField: PropTypes.func,
};

export default CustomerDetails;
