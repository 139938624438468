/* eslint-disable
    no-self-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Backbone from 'backbone';
import _ from 'underscore';
import Money from '@sealink/money_beans';
import moment from 'moment';
import Config from 'config/config';
import Resources from 'collections/resources';
import * as BookingServerActions from 'actions/redux/booking_server_actions';
import * as ReservationServerActions from 'actions/redux/reservation_server_actions';
import Reservation from 'models/reservation';
import Reservations from 'collections/reservations';
import CheckoutService from 'services/checkout_service';
import ProductStore from 'stores/product_store';
import PaymentStore from 'stores/payment_store';
import { normalizeBookingAttributes } from 'actions/redux/normalizer_actions';
import { setBookingId, selectReservations } from 'actions/redux/ui_actions';
import { createAdjustment } from 'actions/redux/thunks/adjustment_thunks';
import { selectPrinters } from 'selectors/printer_selectors';

import store from 'store';

var Booking = Backbone.Model.extend({
  // TODO: Get rid of backbone, we don't need it
  urlRoot: Config.routes.bookings,

  url() {
    return `${this.urlRoot()}/${this.id}.json`;
  },

  update(attrs) {
    return store.dispatch(BookingServerActions.update(this, attrs));
  },

  parse(data) {
    store.dispatch(normalizeBookingAttributes(data));
    store.dispatch(setBookingId(data.id));
    store.dispatch(selectReservations([]));

    data.reservations = new Reservations(
      data.reservations_attributes != null ? data.reservations_attributes : [],
      { parse: true }
    );
    return data;
  },

  initialize() {
    if (!this.reservations()) {
      return this.set('reservations', new Reservations([]));
    }
  },

  reservations() {
    return this.get('reservations');
  },

  moneyDefaults() {
    const result = {};
    _.each(
      Booking.prototype.moneyAttrs,
      (attr) => (result[attr + '_in_cents'] = 0)
    );
    return result;
  },

  defaults() {
    // TODO: A lot of these 'defaults' aren't actually attributes of the QuickTravel booking model.
    // This is odd. Get rid of them?
    return _.extend(this.moneyDefaults(), {
      booking_total: new Money(0),
      issued_tickets_attributes: [],
    });
  },

  addReservation(data) {
    data.last_travel_date = this.calculateLastTravelDate(data);
    data.gross_in_cents = data.inline_price_in_cents * data.quantity;
    data.extra_pick_ids = data.extra_pick_ids;
    const addedReservations = this.reservations().add([data]);

    store.dispatch(ReservationServerActions.create(addedReservations[0], this));
    return addedReservations[0];
  },

  calculateLastTravelDate(data) {
    const resource = ProductStore.getResource(data.resource_id);
    if (resource.get('expiry_level') && resource.get('expiry_units')) {
      return moment(data.first_travel_date, 'DD/MM/YYYY')
        .add(resource.get('expiry_level'), resource.get('expiry_units'))
        .subtract(1, 'day')
        .format('DD/MM/YYYY');
    }
  },

  addAdjustment(adjustmentDefinition) {
    let reservations = this.selectedReservations();
    if (this.noSelectedReservations()) {
      reservations = this.reservations();
    }

    const data = {
      adjustment_definition_id: adjustmentDefinition.id,
      reservation_ids: _.pluck(reservations, 'id'),
    };

    store.dispatch(createAdjustment(data));
  },

  selectedReservations() {
    return this.reservations().where({ selected: true });
  },

  noSelectedReservations() {
    return _.isEmpty(this.selectedReservations());
  },

  selectedReservationsHaveIds() {
    if (this.noSelectedReservations()) {
      return false;
    }
    const ids = _.pluck(this.selectedReservations(), 'id');
    return _.every(ids, _.identity);
  },

  updateReservationFromResponse(reservation, response) {
    return reservation.set(response);
  },

  updatePrices(bookingPriceBreakdown) {
    this.updatePrice(bookingPriceBreakdown);
    return (() => {
      const result = [];
      for (let attrs of Array.from(bookingPriceBreakdown.reservations)) {
        const reservation = this.reservations().get(attrs.id);
        // reservation may not exist as it may have been deleted clientSide
        // but server responded as it still existed on server
        result.push(
          reservation != null ? reservation.updatePrice(attrs) : undefined
        );
      }
      return result;
    })();
  },

  bookingPriceAttrs: [
    'balance_in_cents',
    'commission_in_cents',
    'gross_in_cents',
    'pre_adjusted_gross_in_cents',
    'pre_adjusted_commission_in_cents',
  ],

  updatePrice(attrs) {
    const priceAttrs = _.pick(attrs, ...Array.from(this.bookingPriceAttrs));
    return (() => {
      const result = [];
      for (let attr in priceAttrs) {
        const value = priceAttrs[attr];
        result.push(this.set(attr, value));
      }
      return result;
    })();
  },

  toggleReservation(reservation) {
    return reservation.set('selected', !reservation.get('selected'));
  },

  destroyReservation(reservation) {
    this.reservations().remove(reservation);

    store.dispatch(ReservationServerActions.destroy(reservation, this));
    return null;
  },

  issuedTickets() {
    return this.get('issued_tickets_attributes');
  },

  reservationIssuedTickets() {
    return _.filter(
      this.issuedTickets(),
      (issuedTicket) => issuedTicket.ticket_issueable_type === 'Reservation'
    );
  },

  consumerSplitIssuedTickets() {
    return _.filter(
      this.issuedTickets(),
      (issuedTicket) => issuedTicket.ticket_issueable_type === 'ConsumerSplit'
    );
  },

  unissuedReservationIds() {
    return _.difference(
      this.reservations().pluck('id'),
      this.issuedReservationIds()
    );
  },

  issuedReservationIds() {
    return _.map(this.issuedReservations(), 'id');
  },

  reservationTicketIssueableIds() {
    return _.map(this.reservationIssuedTickets(), 'ticket_issueable_id');
  },

  consumerSplitTicketIssueableIds() {
    return _.map(this.consumerSplitIssuedTickets(), 'ticket_issueable_id');
  },

  issuedReservations() {
    return this.reservations().filter((reservation) => {
      return (
        this.reservationHasTicket(reservation) ||
        this.reservationConsumerSplitsHaveTicket(reservation)
      );
    });
  },

  reservationHasTicket(reservation) {
    return _.contains(this.reservationTicketIssueableIds(), reservation.id);
  },

  reservationConsumerSplitsHaveTicket(reservation) {
    const consumerSplitIds = _.map(
      this.reservationConsumerSplits(reservation),
      'id'
    );
    if (_.isEmpty(consumerSplitIds)) {
      return false;
    }
    const consumerSplitTicketIssueableIds =
      this.consumerSplitTicketIssueableIds();
    return _.all(consumerSplitIds, (id) =>
      _.contains(consumerSplitTicketIssueableIds, id)
    );
  },

  reservationConsumerSplits(reservation) {
    const passenger_splits = reservation.get('passenger_splits') || [];
    const vehicle_splits = reservation.get('vehicle_splits') || [];
    return passenger_splits.concat(vehicle_splits);
  },

  consumerSplits() {
    return _.flatten(this.reservations().map(this.reservationConsumerSplits));
  },

  checkoutService() {
    return this.checkoutServiceInstance != null
      ? this.checkoutServiceInstance
      : (this.checkoutServiceInstance = new CheckoutService(this));
  },

  complete() {
    return this.checkoutService().checkout();
  },

  canComplete() {
    return (
      this.payable() ||
      this.activatable() ||
      this.refundable() ||
      this.printable()
    );
  },

  payable() {
    return this.balance().isPositive() && this.amountPaying().isPositive();
  },

  refundable() {
    return this.balance().isNegative() && this.amountPaying().isNegative();
  },

  alreadyCompleted() {
    return this.fullyIssued() || this.refundable();
  },

  activatable() {
    if (this.isEmpty() || !this.paid().isZero()) {
      return false;
    }
    return !this.active();
  },

  printable() {
    if (selectPrinters(store.getState()).length == 0) {
      return false;
    }
    if (this.isEmpty() || this.refundable()) {
      return false;
    }
    return this.payingBalance() && this.shouldPrintTickets();
  },

  fullyIssued() {
    if (_.isEmpty(this.issuedTickets())) {
      return false;
    }
    return _.isEmpty(this.unissuedReservationIds());
  },

  active() {
    return this.get('state') === 'active';
  },

  cancelled() {
    return this.get('state') === 'cancelled';
  },

  completed() {
    return this.active() && !this.balance().isPositive();
  },

  isEmpty() {
    return this.reservations().isEmpty();
  },

  shouldPrintTickets() {
    return _.any(this.resources(), (resource) => resource.get('print_tickets'));
  },

  resources() {
    const resourceIds = _.uniq(this.reservations().pluck('resource_id'));
    return _.compact(_.map(resourceIds, (id) => Resources.findWhere({ id })));
  },

  total() {
    return this.reservationTotal();
  },

  client() {
    return this.get('client');
  },

  allowedPaymentTypeIds() {
    return _.map(this.get('payment_types_attributes'), 'id');
  },

  canPayVia(paymentTypeId) {
    return _.contains(this.allowedPaymentTypeIds(), paymentTypeId);
  },

  calculateSurcharge(amount) {
    if (amount == null) {
      amount = this.balance();
    }
    return this.checkoutService().calculateSurcharge(amount);
  },

  reservationTotal() {
    const reservationsGrosses = this.reservations().map((reservation) =>
      reservation.grossIncludingChildren()
    );
    const sum = (totalPrice, singlePrice) => totalPrice.add(singlePrice);
    return _.reduce(reservationsGrosses, sum, new Money(0));
  },

  updateReservation(reservationId, attrs) {
    const reservation = this.reservations().get(reservationId);
    reservation.set(attrs);
    return store.dispatch(
      ReservationServerActions.update(reservation, this, attrs)
    );
  },

  amountPaying() {
    return PaymentStore.currentPayment().amountPaying();
  },

  payingBalance() {
    return this.amountPaying().equals(this.balance());
  },

  getReservationPassengers(passenger_ids) {
    return _.map(passenger_ids, (passenger_id) => {
      return _.findWhere(this.get('passengers_attributes'), {
        id: passenger_id,
      });
    });
  },

  getReservationPassengerTypeCounts(passenger_ids) {
    return _.countBy(
      this.getReservationPassengers(passenger_ids),
      (passenger) => passenger.passenger_type_id
    );
  },

  updateFromSuccessfulCheckout(requestData) {
    const amountPaid = new Money(requestData.payment.amount_in_cents);
    const amountPaidWithSurcharge = amountPaid.add(
      this.calculateSurcharge(amountPaid)
    );
    this.set('paid_in_cents', this.paid().add(amountPaidWithSurcharge).cents);
    this.set(
      'surcharge_in_cents',
      this.surcharge().add(this.calculateSurcharge(amountPaid)).cents
    );
    return this.set(
      'balance_in_cents',
      this.balance().subtract(amountPaid).cents
    );
  },
});

//Dynamically create money attribute readers
Booking.prototype.moneyAttrs = [
  'gross',
  'nett',
  'commission',
  'balance',
  'paid',
  'surcharge',
  'deposit',
];

for (let attr of Array.from(Booking.prototype.moneyAttrs)) {
  ((attr) =>
    (Booking.prototype[attr] = function () {
      return new Money(this.get(attr + '_in_cents'));
    }))(attr);
}

export default Booking;
