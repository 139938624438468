import Backbone from 'backbone';
import Reservation from 'models/reservation';

const Reservations = Backbone.Collection.extend({
  model: Reservation,

  initialize() {
    return this.on('add', this.select);
  },

  select(reservation) {
    for (let selected of Array.from(this.where({ selected: true }))) {
      selected.set('selected', false);
    }
    reservation.set('selected', true);
  },
});

export default Reservations;
