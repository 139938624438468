import ReactDOM from 'react-dom';

import Provider from './provider';
import store from './store';

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'assets/scss/app.scss';
import 'utils/config_checker';
import 'utils/rollbar_setup';

// Ajax setup must happen first!
import ajaxSetup from 'utils/ajax_setup';
ajaxSetup.setupPrefilter();
ajaxSetup.handleUnauthorized();

// Data prefetch must happen after ajax setup, but before route initialization!
require('initializers/datadog');
require('initializers/stores');
import dataInitializer from 'initializers/data';
dataInitializer.load();

// Render top components
import AppStart from 'views/app_start';

// Google Analytics
import ReactGA from 'react-ga4';
import Config from 'config/config';
ReactGA.initialize(Config.googleTagManagerId);

ReactDOM.render(
  <>
    <Provider store={store}>
      <AppStart />
    </Provider>
  </>,
  document.getElementById('AppStart')
);
