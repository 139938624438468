import BookingStore from 'stores/booking_store';
import { FolioSearchModal } from 'components/folio/folio_search_modal';
import moment from 'moment';
import {
  guestlinkSearch,
  getVirtualRoom,
} from 'actions/redux/guest_link_actions';
import {
  selectSelectedFolioSeachResult,
  selectFolioSearchBy,
  selectFolioSearchDates,
} from 'selectors/folio_search_selectors';
import {
  setModalActive,
  setSelectedFolio,
  setFolioSearchBy,
  clearFolioStore,
} from 'actions/redux/folio_search_actions';
import { updatePaymentReference } from 'actions/redux/payment_actions';
import { selectSelectedPaymentType } from 'selectors/payment_type_selectors';
import { completeBooking } from 'actions/redux/thunks/checkout_thunks';

import { useSelector, useDispatch } from 'react-redux';

const FolioSearch = () => {
  const dispatch = useDispatch();

  const selectedPaymentType = useSelector(selectSelectedPaymentType);

  const folioSearchModalActive = useSelector(
    (state) => state.folioSearch.modalActive
  );
  const selectedFolio = useSelector((state) =>
    selectSelectedFolioSeachResult(state)
  );

  const [folioSearchStartDate, folioSearchEndDate] = useSelector((state) => {
    return selectFolioSearchDates(state).map((date) => {
      date ? new moment(date, 'DD/MM/YYYY') : new moment();
    });
  });

  const folioSearchBy = useSelector((state) => selectFolioSearchBy(state));

  const hotelUid = selectedPaymentType?.hotel_uid;

  const activeClasses = 'btn col-md-4 btn-primary active';
  const defaultClasses = 'btn col-md-4 btn-outline-dark';
  const folioSearchByRoomNumberClass =
    folioSearchBy == 'room_number' ? activeClasses : defaultClasses;

  const folioSearchBySurnameClass =
    folioSearchBy == 'last_name' ? activeClasses : defaultClasses;

  const folioSearchByReservationNumberClass =
    folioSearchBy == 'reservation_number' ? activeClasses : defaultClasses;

  const folioSearchByVirtualRoomClass =
    folioSearchBy == 'virtual_room' ? activeClasses : defaultClasses;

  const folioSearchCancelClicked = () => {
    dispatch(setModalActive(false));
  };

  const folioSearchResultClicked = (folio) => {
    dispatch(setSelectedFolio(folio));
  };

  const handleFolioSearchClear = () => {
    dispatch(setSelectedFolio(null));
  };

  const handleFolioSubmit = (comment) => {
    dispatch(updatePaymentReference(comment));
    dispatch(completeBooking(BookingStore.currentBooking()));
    dispatch(setModalActive(false));
    dispatch(clearFolioStore());
  };

  const setSearchBySurname = () => dispatch(setFolioSearchBy('last_name'));
  const setSearchByRoomNumber = () => dispatch(setFolioSearchBy('room_number'));
  const setSearchByReservationNumber = () =>
    dispatch(setFolioSearchBy('reservation_number'));
  const setSearchByVirtualRoom = () => {
    dispatch(setFolioSearchBy('virtual_room'));
  };
  const loadOptions = (value) => dispatch(guestlinkSearch({ query: value }));
  const setFolioSearchDates = ({ startDate, endDate }) =>
    dispatch(
      setFolioSearchDates([
        (startDate || moment()).format('DD/MM/YYYY'),
        (endDate || moment().add(1, 'days')).format('DD/MM/YYYY'),
      ])
    );
  const virtualRoomSearch = (roomNumberQuery) =>
    dispatch(getVirtualRoom({ roomNumberQuery }));

  const updatePaymentExternalId = (externalId) => {
    dispatch(updatePaymentReference(externalId));
  };

  if (!folioSearchModalActive) {
    return null;
  }

  <FolioSearchModal
    loadOptions={loadOptions}
    selectedFolio={selectedFolio}
    folioSearchResultClicked={(result) => {
      updatePaymentExternalId(result);
      folioSearchResultClicked(result);
    }}
    handleFolioSubmit={handleFolioSubmit}
    folioSearchVisible={folioSearchModalActive}
    folioSearchCancelClicked={folioSearchCancelClicked}
    folioIdSelected={selectedFolio != {}}
    handleFolioSearchClear={handleFolioSearchClear}
    setSearchBySurname={setSearchBySurname}
    setSearchByRoomNumber={setSearchByRoomNumber}
    setSearchByReservationNumber={setSearchByReservationNumber}
    setSearchByVirtualRoom={setSearchByVirtualRoom}
    folioSearchStartDate={folioSearchStartDate}
    folioSearchEndDate={folioSearchEndDate}
    setFolioSearchDates={setFolioSearchDates}
    folioSearchByRoomNumberClass={folioSearchByRoomNumberClass}
    folioSearchBySurnameClass={folioSearchBySurnameClass}
    folioSearchByReservationNumberClass={folioSearchByReservationNumberClass}
    folioSearchByVirtualRoomClass={folioSearchByVirtualRoomClass}
    folioSearchBy={folioSearchBy}
    hotelUid={hotelUid}
    virtualRoomSearch={virtualRoomSearch}
  />;
};

export default FolioSearch;
