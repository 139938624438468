import AppScreen from 'views/app_screen';
import ErrorBoundary from 'libs/error_boundary';
import { useDispatch } from 'react-redux';
import AuthenticationGateway from 'views/authentication_gateway';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import LoginScreen from './login_screen';
import LoginCallback from 'components/login/login_callback';
import TenantScreen from './tenant_screen';
import { Security } from '@okta/okta-react';
import { oktaAuth, restoreOriginalUri } from 'api/okta_api';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';

const AppStart = () => {
  const dispatch = useDispatch();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/callback" element={<LoginCallback />} />
        <Route index element={<Navigate to="/login" replace={true} />} />
        <Route path="/tenants" element={<TenantScreen />} />
        <Route element={<AuthenticationGateway />}>
          <Route path="/bookings/new" element={<AppScreen />} />
          <Route
            path="/bookings/:id"
            element={<AppScreen />}
            loader={({ params }) => {
              dispatch(BookingActions.loadBooking(params.id));
              return null;
            }}
          />
          <Route
            path="/bookings/:id/pay"
            element={<AppScreen />}
            action={({ params }) => {
              dispatch(BookingActions.loadBooking(params.id));
              dispatch(BookingActions.payCurrentBooking());
            }}
          />
          <Route
            path="/bookings/:id/client"
            element={<AppScreen />}
            action={({ params }) => {
              dispatch(BookingActions.addClientToBooking(params.id));
              dispatch(BookingActions.loadBooking(params.id));
            }}
          />
          <Route
            path="/list/bookings"
            element={<AppScreen />}
            loader={() => {
              dispatch(BookingActions.listBookings());
              return null;
            }}
          />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
      </Route>
    )
  );

  return (
    <ErrorBoundary>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <RouterProvider router={router} />
      </Security>
    </ErrorBoundary>
  );
};

AppStart.displayName = 'AppStart';

export default AppStart;
