const SLOW_REQUEST_THRESHOLD = 3000;
import Config from 'config/config';

function checkStatus(response, requestStartTime) {
  if (response.status >= 200 && response.status < 300) {
    const responseTimeMs = new Date().getTime() - requestStartTime;
    if (responseTimeMs > SLOW_REQUEST_THRESHOLD) {
      window.Rollbar.log('Slow response', {
        responseTimeMs,
        url: response.url,
      });
    }
    return response;
  } else if (response.status === 422) {
    return response.json().then((json) => {
      const error = new Error(json.error);
      error.response = response;
      throw error;
    });
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json();
}

const fetchBasic = (url, options) => {
  const headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'X-ORIGIN-KEY': Config.apiKey,
  };
  const requestStartTime = new Date().getTime();
  return fetch(url, { ...options, headers }).then(
    checkStatus,
    requestStartTime
  );
};

const fetchJson = (url, options) => {
  return fetchBasic(url, options).then(parseJSON);
};

export { checkStatus, fetchBasic, fetchJson };
