import PropTypes from 'prop-types';
import { sample } from 'lodash';

import backgrounds from 'assets/backgrounds';
import NoticeView from 'views/page/notice_view';

const UnauthenticatedScreen = ({ children }) => {
  const background = sample(backgrounds);
  return (
    <div className="contain unauthenticated-screen">
      <div
        style={{
          backgroundImage: `url(${background.url})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
      <div className="row row-center">
        <NoticeView />
        <div className="grid-12 row row-center" style={{ height: '30vh' }}>
          <div className="login-logo logo grid-4" title="SeaLink Ticket App" />
        </div>
        <div className="grid-12 row row-center" style={{ height: '70vh' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

UnauthenticatedScreen.propTypes = {
  children: PropTypes.object,
};

export default UnauthenticatedScreen;
