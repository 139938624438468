import AppDispatcher from 'app_dispatcher';
import localStore from 'utils/local_store';
import { createAction } from '@reduxjs/toolkit';

import * as API from 'api/clients_api';
import { handleJsonResponse } from 'actions/redux/error_handler_actions';
import { setPaymentTypeId } from './payment_type_thunks';
import { selectClientHistory } from 'selectors/clients_selectors';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { selectSelectedTenant } from 'selectors/tenant_selectors';

const setClientHistory = createAction('clients/history/set');
const setClientSearchResults = createAction('clients/search/set');

const recordClientHistory = (client) => (dispatch, getState) => {
  const clientHistory = selectClientHistory(getState());
  const selectedTenant = selectSelectedTenant(getState());

  if (clientHistory.some((x) => x.id === client.id)) {
    return Promise.resolve();
  }
  const newClientHitory = [client, ...clientHistory.slice(0, 3)];

  localStore.set(`${selectedTenant.id}.clientHistory`, newClientHitory);
  localStore.unset('clientHistory');
  dispatch(setClientHistory(newClientHitory));
  return Promise.resolve();
};

const reloadClientHistoryFromLocalStore = (tenant) => (dispatch) => {
  const data =
    localStore.get('clientHistory') ||
    localStore.get(`${tenant}.clientHistory`) ||
    [];
  dispatch(setClientHistory(data));
  return Promise.resolve();
};

const unassignClient = () => {
  return () => {
    AppDispatcher.trigger('booking:unassignClient');
    return Promise.resolve();
  };
};

const assignClient = (client) => {
  return (dispatch) => {
    AppDispatcher.trigger('client:assigned', client);

    dispatch(recordClientHistory(client));
    //TODO: This should dispatch the correct client payment type
    //however due to the current backbone implementation it is not currently
    //possible.
    //Once work is done to move updating of booking attributes to a thunk
    //this could then be done.
    //Reset here is done so the payment model can switch to on-account payment
    //type (rather than last selection)
    dispatch(setPaymentTypeId(null));

    return Promise.resolve();
  };
};

const search = (text) => (dispatch, getState) => {
  const user = selectAuthenticatedUser(getState());

  return API.search(text, user.access_token)
    .then((response) => {
      dispatch(setClientSearchResults(response));
    })
    .catch((error) => {
      dispatch(handleJsonResponse(error.response, 'dismiss'));
    });
};

const clearSearch = () => (dispatch) => {
  dispatch(setClientSearchResults({}));
  return Promise.resolve();
};

export {
  unassignClient,
  assignClient,
  search,
  clearSearch,
  setClientHistory,
  setClientSearchResults,
  reloadClientHistoryFromLocalStore,
};
