import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

const ClientDetails = ({ booking }) => {
  if (isEmpty(booking) || !booking.client) {
    return <div />;
  }

  const { name, client_type } = booking.client;

  return (
    <div className="ticket-section client-details">
      <dl>
        <dt>Client Name: </dt>
        <dd>{name}</dd>
        <dt>Client Type: </dt>
        <dd>{client_type}</dd>
      </dl>
    </div>
  );
};

ClientDetails.propTypes = {
  booking: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string.isRequired,
      client_type: PropTypes.string.isRequired,
    }),
  }),
};

export default ClientDetails;
