import Backbone from 'backbone';
import Resources from 'collections/resources';

const Category = Backbone.Model.extend({
  resources() {
    return Resources.where({ resource_category_id: this.get('id') });
  },
});

export default Category;
