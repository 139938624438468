/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';
import Money from '@sealink/money_beans';
import Loading from 'views/page/loading';
import createReactClass from 'create-react-class';

const ExtraPicks = createReactClass({
  displayName: 'ExtraPicks',

  extraPicks() {
    return this.props.reservation.get('sub_reservations_attributes');
  },

  shouldShowLoadingExtraPicks() {
    return (
      _.isEmpty(this.extraPicks()) &&
      !_.isEmpty(this.props.reservation.get('extra_pick_ids'))
    );
  },

  render() {
    return (
      <div className="row item-extra-picks">{this.renderExtraPicks()}</div>
    );
  },

  renderExtraPicks() {
    if (this.shouldShowLoadingExtraPicks()) {
      return this.loadingExtraPicks();
    }
    return _.map(this.extraPicks(), (extraPick, i) => (
      <div className="extra-pick-item grid-12" key={`ep-${i}`}>
        <div className="grid-10 extra-pick-title">
          {extraPick.selection_name}
        </div>
        <div className="grid-2 extra-pick-price text-right">
          ${new Money(extraPick.gross_in_cents).format()}
        </div>
      </div>
    ));
  },

  loadingExtraPicks() {
    const style = {
      padding: '0 5px 0 32px',
      textAlign: 'left',
    };
    return (
      <div className="extra-pick-item grid-12">
        <Loading text="Extra Picks" style={style} />
      </div>
    );
  },
});

export default ExtraPicks;
