import TouchSelect from 'components/touch_select';
import { selectTill } from 'actions/redux/thunks/till_thunks';
import {
  selectTillOptions,
  selectSelectedTillOption,
} from 'selectors/till_selectors';
import { useSelector, useDispatch } from 'react-redux';

const TillSelect = () => {
  const dispatch = useDispatch();
  const tills = useSelector((state) => selectTillOptions(state));
  const selected = useSelector((state) => selectSelectedTillOption(state));

  const updateSelectedTill = (option) => {
    dispatch(selectTill(option));
  };

  return (
    <div className="till-section">
      <TouchSelect
        placeholder="Tills"
        isClearable
        options={tills}
        value={selected}
        onChange={updateSelectedTill}
      />
    </div>
  );
};

export default TillSelect;
