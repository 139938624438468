import { createSelector } from '@reduxjs/toolkit';
import { selectTenantConfig } from './tenant_selectors';
import { partition, flatten } from 'lodash';

const selectCountries = (state) => state?.countries?.list || [];

const selectCountryOptions = createSelector(
  [selectCountries, selectTenantConfig],
  (countries, config) => {
    const countriesRequiringPostcode =
      config?.countries?.requiringPostcode || [];

    const promotedCountries = config?.countries?.promotedCountries || [];

    const partitionedCountries = partition(countries, (country) =>
      promotedCountries?.includes(country.id)
    );

    return flatten(partitionedCountries).map((country) => {
      return {
        label: country.name,
        value: country.id,
        requiresPostCode: countriesRequiringPostcode.includes(country.id),
      };
    });
  }
);

export { selectCountries, selectCountryOptions };
