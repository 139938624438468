import AppDispatcher from 'app_dispatcher';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import * as NoticeActions from 'actions/redux/notice_actions';
import * as UIActions from 'actions/redux/ui_actions';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';

export const checkoutFailure = (response) => (dispatch) => {
  dispatch(ErrorHandlerActions.show(response));
  AppDispatcher.trigger('checkout:fail', response);
  return Promise.resolve();
};

export const reservationCreated = (reservation, response) => (dispatch) => {
  AppDispatcher.trigger('reservation:createdOnServer', reservation, response);
  // select just the newly added reservation (This is Reservation.select() equivalent)

  // The user might untick this before it comes back from the server account for this
  const selection = [];
  if (reservation.get('selected')) {
    selection.push(reservation.id);
  }

  dispatch(UIActions.selectReservations(selection));
  return Promise.resolve();
};

export const pricesUpdated = (booking_price_breakdown) => () => {
  AppDispatcher.trigger('price:update', booking_price_breakdown);
  return Promise.resolve();
};

export const checkoutSuccess = (response, requestData) => () => {
  AppDispatcher.trigger('checkout:success', response, requestData);
  return Promise.resolve();
};

export const tillsLoaded = (response) => () => {
  AppDispatcher.trigger('till:loaded', response);
  return Promise.resolve();
};

export const promoCodesLoaded = (response) => () => {
  AppDispatcher.trigger('promoCode:loaded', response);
  return Promise.resolve();
};

export const printerSettingsLoaded = (response) => () => {
  AppDispatcher.trigger('printerSettings:loaded', response);
  return Promise.resolve();
};

export const printersLoaded = (response) => () => {
  AppDispatcher.trigger('printer:loaded', response);
  return Promise.resolve();
};

export const printSuccess = (response) => () => {
  AppDispatcher.trigger('print:success', response);
  return Promise.resolve();
};

export const searchResult = (response) => () => {
  if (response.total_entries === 1) {
    const booking_id = response.results[0].id;
    BookingActions.loadBooking(booking_id);
  } else {
    AppDispatcher.trigger('search:displayResults', response);
  }
  return Promise.resolve();
};

export const clientSearchResult = (response) => () => {
  AppDispatcher.trigger('clientSearch:displayResults', response);
  return Promise.resolve();
};

export const clientSearchAndAssignByBarcodeResult = (clientResponse) => () => {
  AppDispatcher.trigger('clientSearchByBarcode:assignResults', clientResponse);
  return Promise.resolve();
};

export const bookingUpdate = (response) => (dispatch) => {
  dispatch(NoticeActions.clearNotice());
  dispatch(BookingActions.bookingUpdated(response));
  return Promise.resolve();
};

export const currentBookingFetched = (response) => () => {
  AppDispatcher.trigger('currentBooking:fetched', response);
  return Promise.resolve();
};

export const bookingActivated = (response) => () => {
  AppDispatcher.trigger('booking:activated', response);
  return Promise.resolve();
};

export const bookingReloaded = (response) => () => {
  AppDispatcher.trigger('booking:reloaded', response);
  return Promise.resolve();
};

export const bookingReloadFailed = (response) => (dispatch) => {
  dispatch(ErrorHandlerActions.handleJsonResponse(response));
  return Promise.resolve();
};

export const reservationUpdated = () => () => {
  return Promise.resolve();
};

export const reservationError = () => () => {
  return Promise.resolve();
};
