import Backbone from 'backbone';
import Money from '@sealink/money_beans';

const Resource = Backbone.Model.extend({
  price() {
    return new Money(this.get('todays_price_in_cents'));
  },
});

export default Resource;
