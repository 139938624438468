import Backbone from 'backbone';
import Config from 'config/config';
import ProductType from 'models/product_type';

const ProductTypes = Backbone.Collection.extend({
  model: ProductType,
  url: Config.routes.productTypes,
  headers: { 'X-ORIGIN-KEY': Config.apiKey },
});

export default new ProductTypes();
