import { createAction } from '@reduxjs/toolkit';

import { apiSlice } from 'features/api/api_slice';
import authServiceApi from 'api/auth_service_api';
import dataInitializer from 'initializers/data';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import { loadingNotice, clearNotice } from 'actions/redux/notice_actions';
import * as ErrorHandlerActions from 'actions/redux/error_handler_actions';

const loginStarted = createAction('login/started');
const loginSucceeded = createAction('login/succeeded');
const loginFailure = createAction('login/failure');

const logoutStarted = createAction('logout/started');
const logoutSucceeded = createAction('logout/succeeded');
const logoutFailure = createAction('logout/failure');

const clearRollbarUser = () => {
  //TODO
};

const setRollbarUser = () => {
  //TODO
};

const login = (username, password) => {
  return (dispatch) => {
    dispatch(loginStarted());
    dispatch(loadingNotice('Logging in...'));

    return authServiceApi
      .login(username, password)
      .then((response) => {
        if (response.error) {
          const { error } = response;
          dispatch(clearNotice());
          dispatch(loginFailure(error));
          dispatch(ErrorHandlerActions.show(error, 'dismiss'));
        } else {
          setRollbarUser(response.access_token);
          response['username'] = username;
          dispatch(loginSucceeded(response));
          dispatch(clearNotice());
        }
      })
      .catch((error) => {
        dispatch(loginFailure(error));
        dispatch(ErrorHandlerActions.show(error, 'dismiss'));
      });
  };
};

const logout = () => {
  return (dispatch) => {
    dispatch(logoutStarted());
    dispatch(apiSlice.util.resetApiState());
    clearRollbarUser();
    dispatch(BookingActions.unloadBooking());
    dataInitializer.unload();
    dispatch(logoutSucceeded());
  };
};

export {
  login,
  logout,
  loginStarted,
  loginSucceeded,
  loginFailure,
  logoutStarted,
  logoutSucceeded,
  logoutFailure,
};
