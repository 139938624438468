import { createReducer } from '@reduxjs/toolkit';
import {
  setDate,
  setCurrentQuantity,
  setPassengerTypeQuantity,
  setCurrentResource,
  setInitialState,
  setSelectedExtraPickIds,
  resetSelectedResource,
} from 'actions/redux/reservation_to_add_actions';

const INITIAL_STATE = {
  selectedResource: null,
  date: null,
  quantity: null,
  passengerTypeNumbers: {},
  selectedExtraPickIds: [],
};

const ReservationToAddReducer = createReducer(INITIAL_STATE, {
  [setDate]: (state, action) => {
    state.date = action.payload;
  },
  [setCurrentQuantity]: (state, action) => {
    state.quantity = action.payload;
  },
  [setPassengerTypeQuantity]: (state, action) => {
    const { payload } = action;

    const existingQty =
      state.passengerTypeNumbers[payload.passengerTypeId] || 0;
    state.passengerTypeNumbers[payload.passengerTypeId] =
      existingQty + payload.quantity;
  },
  [setCurrentResource]: (state, action) => {
    state.selectedResource = action.payload;
  },
  [setSelectedExtraPickIds]: (state, action) => {
    state.selectedExtraPickIds = action.payload;
  },
  [setInitialState]: () => {
    return INITIAL_STATE;
  },
  [resetSelectedResource]: (state) => {
    state.selectedResource = INITIAL_STATE.selectedResource;
  },
});

export default ReservationToAddReducer;
