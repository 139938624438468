import { useEffect, useState } from 'react';
import { search as searchApi, recentBookings } from 'api/booking_api';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'selectors/login_selectors';

export const useBookingSearch = () => {
  const currentUser = useSelector((state) => selectAuthenticatedUser(state));

  const [input, setInput] = useState(null);
  const [bookings, setBookings] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const nextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const previousPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  };

  const search = (input) => {
    setInput(input);
  };

  const reset = () => {
    setBookings([]);
    setInput(null);
    setCurrentPage(1);
    setTotalPages(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [input]);

  const fetchApi = async () => {
    if (input) {
      return await searchApi(
        { input: input, page: currentPage },
        currentUser.access_token
      );
    }
    return await recentBookings(currentUser.access_token);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetchApi();
      setBookings(response.results);
      setTotalPages(response.total_pages);

      setIsLoading(false);
    };

    fetchData();
  }, [input, currentPage]);

  return {
    bookings,
    totalPages,
    currentPage,
    search,
    reset,
    nextPage,
    previousPage,
    isLoading,
  };
};
