import { memo } from 'react';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

export const NumberPlate = ({ numberPlate = '' }) => {
  if (isEmpty(numberPlate)) {
    return null;
  }
  return <div className="numberPlate">{numberPlate}</div>;
};

NumberPlate.propTypes = {
  numberPlate: PropTypes.string,
};

export default memo(NumberPlate);
