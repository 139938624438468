import { useSelector } from 'react-redux';
import AppControls from 'views/booking/app_controls';
import ClientDetails from 'views/client/client_details';
import BookingDate from 'views/booking/booking_date';
import PromoCodeContainer from 'views/booking/promo_code_container';
import ProductTypeSwitch from 'views/products/product_type_switch';
import Config from 'config/config';
import PropTypes from 'prop-types';
import { selectBooking } from 'selectors/booking_selectors';
import { isEmpty } from 'lodash';

const AppSideBar = ({ isBookingDisplayed }) => {
  const booking = useSelector((state) => selectBooking(state));

  const bookingReference = () => {
    if (isEmpty(booking)) {
      return 'new';
    }
    return booking.reference;
  };

  const openInQtUrl = () => {
    if (booking == null) {
      return '';
    }

    return `${Config.quicktravelUrl}/front_office/bookings/${booking.id}`;
  };

  const bookingNav = () => {
    return (
      <div>
        <a
          className="booking-info booking-reference"
          href={openInQtUrl()}
          target="_blank"
          rel="noreferrer"
        >
          {bookingReference()}
        </a>
        <BookingDate />
        <ClientDetails />
        <PromoCodeContainer />
        <ProductTypeSwitch />
      </div>
    );
  };

  return (
    <div>
      {isBookingDisplayed ? bookingNav() : undefined}
      <AppControls />
    </div>
  );
};

AppSideBar.propTypes = {
  isBookingDisplayed: PropTypes.bool.isRequired,
};

export default AppSideBar;
