import { validateTicket } from 'actions/redux/thunks/ticket_thunks';
import Config from 'config/config';
import CheckoutService from 'services/checkout_service';
import * as BookingActions from 'actions/redux/thunks/booking_thunks';
import PaymentStore from 'stores/payment_store';
import { selectSelectedFolioSeachResult } from 'selectors/folio_search_selectors';

const completeBooking = (booking) => {
  return (dispatch, getState) => {
    const payment = PaymentStore.currentPayment();

    if (payment.isRoomCharge()) {
      payment.setFolioDetails(selectSelectedFolioSeachResult(getState()));
    }

    const checkoutService = new CheckoutService(booking);
    const alreadyCompleted = booking.alreadyCompleted();

    checkoutService.checkout().then(() => {
      if (!alreadyCompleted && Config.autoValidateTicket) {
        booking.fetch().then(() => {
          booking.issuedTickets().forEach((ticket) => {
            dispatch(validateTicket(ticket));
          });
          BookingActions.bookingCompleted();
        });
      } else {
        BookingActions.bookingCompleted();
      }
    });
  };
};

export { completeBooking };
