import { createReducer } from '@reduxjs/toolkit';
import { setEntity, resetEntities } from 'actions/redux/entity_actions';
import { optimisticallyUpdateBooking } from 'actions/redux/thunks/booking_thunks';

const INITIAL_STATE = {};

const entitiesReducer = createReducer(INITIAL_STATE, {
  [setEntity]: (state, action) => {
    return action.payload;
  },
  [resetEntities]: () => {
    return INITIAL_STATE;
  },
  [optimisticallyUpdateBooking]: (state, action) => {
    const { payload } = action;

    const booking = state.booking[payload.id];

    state.booking[payload.id] = { ...booking, ...payload };
  },
});

export default entitiesReducer;
