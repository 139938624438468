import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthenticatedUser } from 'selectors/login_selectors';
import { logout } from 'actions/redux/thunks/login_thunks';
import {
  selectTenant,
  loadAvailableTenants,
} from 'actions/redux/thunks/tenant_thunks';
import { selectAvailableTenantEntities } from 'selectors/tenant_selectors';

const TenantSelectorView = () => {
  const dispatch = useDispatch();
  const tenants = useSelector(selectAvailableTenantEntities);
  const loggedIn = useSelector(selectAuthenticatedUser);

  useEffect(() => {
    if (tenants.length == 0) {
      dispatch(loadAvailableTenants());
    }
  }, []);

  const handleOnClick = (tenant) => {
    dispatch(selectTenant(tenant.id));
  };

  const renderTenants = () => {
    if (tenants === undefined) {
      return null;
    }

    return tenants.map((tenant, key) => {
      return (
        <li key={`tenant-${key}`}>
          <button
            className="button default tenant-button"
            onClick={() => handleOnClick(tenant)}
          >
            {tenant.name}
            <i className="icon-right-dir" />
          </button>
        </li>
      );
    });
  };

  return (
    <div className="grid-8">
      <ul className="tenant-button-list">
        <li>
          <button className="button dark disabled">
            Please choose your configuration
          </button>
        </li>
        {renderTenants()}
        <li>
          Logged in as {loggedIn?.username}
          <a href="/" onClick={() => dispatch(logout())}>
            <i className="icon-logout" />
            Logout
          </a>
        </li>
      </ul>
      <br />
    </div>
  );
};

TenantSelectorView.displayName = 'TenantSelectorView';

export default TenantSelectorView;
